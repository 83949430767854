import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    name: "sortable-column-header",
})
export default class SortableColumnHeader extends Vue {
    @Prop()
    public readonly title: string;

    @Prop()
    public readonly columnName: string;

    @Prop()
    public readonly sortBy: string;

    @Prop()
    public readonly sortDirection: string;
}
