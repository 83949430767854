import Vue from "vue";
import VModal from "vue-js-modal";
import VueMoment from "vue-moment";
import Vuelidate from "vuelidate";
import VTooltip from "v-tooltip";
import Toasted from "vue-toasted";
import { router } from "@/router";
import { store } from "@/store";
import { sync } from "vuex-router-sync";
import { App } from "@/views";

import {
    analysisStatusFilter,
    roleFilter,
} from "@/filters";

Vue.use(VModal);
Vue.use(VueMoment);
Vue.use(Vuelidate);
Vue.use(Toasted);
Vue.use(VTooltip);

Vue.filter("role", roleFilter);
Vue.filter("analysisStatus", analysisStatusFilter);

// Enable Vuejs performance profiling in non-production env
Vue.config.performance = process.env.NODE_ENV !== "production";

sync(store, router);

// Create the Vuejs app instance
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

if (module.hot) {
    module.hot.accept();
}
