import Vue from "vue";
import { Component, Prop, Model, Watch } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import { Loader } from "@/components";
import VueEasyLightbox from "vue-easy-lightbox";
import * as icons from "@/components/shared/icons";
import {
    IBrand,
    IBrandQuality,
    ICurrency,
    ISubject,
    IUserSighting,
    IUserSightingBottle,
} from "@/models";
import { AzureBlobService } from "@/services";

@Component({
    name: "user-sighting-bottle",
    components: {
        "datepicker": Datepicker,
        Multiselect,
        Loader,
        VueEasyLightbox,
        "delete-icon": icons.GarbageIcon,
        "upload-icon": icons.UploadIcon,
    },
})

export default class UserSightingBottle extends Vue {
    public readonly deleteEvent: string = "delete";

    @Model("change", { required: true, type: Object })
    public userSightingBottle: IUserSightingBottle;

    @Prop()
    public validator: any;

    @Prop()
    public indexBottle: number;

    @Prop()
    public isDisabledFields: boolean;

    @Prop()
    public reporterRequest: any;

    @Prop()
    public userSighting: IUserSighting;

    public azureBlobService: AzureBlobService;
    public dragging: boolean;
    public loading: boolean;
    public visible: boolean;
    public decoded: boolean;
    public index: number;

    public selectedPhoto: string;

    public brandQualities: IBrandQuality[];
    public otherSize: boolean;
    public otherVolume: boolean;

    public highlightImage: boolean;
    public isImageDisabled: boolean;

    public highlightLotNumber: boolean;
    public isLotNumberDisabled: boolean;

    public highlightLotDate: boolean;
    public isLotDateDisabled: boolean;

    public highlightLotTime: boolean;
    public isLotTimeDisabled: boolean;

    public searchBrandTimeout: any;
    public filteredBrands: IBrand[];

    public searchBrandQualityTimeout: any;
    public filteredBrandQualities: IBrandQuality[];

    public brandQuality: IBrandQuality;
    public importer: ISubject;

    public constructor() {
        super();

        this.dragging = false;
        this.loading = false;
        this.visible = false;
        this.decoded = false;
        this.index = 0;

        this.selectedPhoto = "";

        this.brandQualities = [];
        this.otherSize = false;
        this.otherVolume = false;

        this.highlightImage = false;
        this.isImageDisabled = false;

        this.highlightLotNumber = false;
        this.isLotNumberDisabled = false;

        this.highlightLotDate = false;
        this.isLotDateDisabled = false;

        this.highlightLotTime = false;
        this.isLotTimeDisabled = false;

        this.searchBrandTimeout = null;
        this.filteredBrands = [];

        this.searchBrandQualityTimeout = null;
        this.filteredBrandQualities = [];

        this.brandQuality = null;
        this.importer = null;
    }

    public async created(): Promise<void> {
        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName, this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);

        if (this.userSightingBottle) {
            if (this.userSightingBottle.brandId) {
                this.brandQualities = this.$store.getters["sighting/getBrand"](this.userSightingBottle.brandId).qualities;
                this.brandQuality = this.brandQualities?.find(bq => bq.brandQualityId === this.userSightingBottle.brandQualityId);
            }

            if (this.userSightingBottle.alcoholVolume > 0 && (this.userSightingBottle.alcoholVolume !== 40 && this.userSightingBottle.alcoholVolume !== 43 &&
                this.userSightingBottle.alcoholVolume !== 47)) {
                this.otherVolume = true;
            }

            if (this.userSightingBottle.size > 0 && (this.userSightingBottle.size !== 70 && this.userSightingBottle.size !== 75 &&
                this.userSightingBottle.size !== 100)) {
                this.otherSize = true;
            }

            if (this.userSightingBottle.importerSubjectId > 0) {
                this.importer = this.$store.state.sighting.customers.find(c => c.subjectId === this.userSightingBottle.importerSubjectId);
            }
        }

        if (this.userSighting && this.userSighting.recordStatus === 3) {
            if (this.reporterRequest.backLabelPhotograph || this.reporterRequest.productQuantityPhotograph) {
                this.highlightImage = true;
            }
            if (this.reporterRequest.lotNumber ) {
                this.highlightLotNumber = true;
            }
            if (this.reporterRequest.bottlingDate) {
                this.highlightLotDate = true;
            }
            if (this.reporterRequest.bottlingTime) {
                this.highlightLotTime = true;
            }
         }

        if (!this.highlightImage && this.isDisabledFields) {
            this.isImageDisabled = true;
        }

        if (!this.highlightLotNumber && this.isDisabledFields) {
            this.isLotNumberDisabled = true;
        }

        if (!this.highlightLotDate && this.isDisabledFields) {
            this.isLotDateDisabled = true;
        }

        if (!this.highlightLotTime && this.isDisabledFields) {
            this.isLotTimeDisabled = true;
        }

        this.filteredBrands = this.brands;
        this.filteredBrandQualities = this.brandQualities;
    }

    public get photosWithFullUrl(): string[] {
        if (this.azureBlobService) {
            return this.userSightingBottle.photos.map(photo => this.azureBlobService.getDownloadUrl(photo));
        }
    }

    public get lotDateIsRequired(): boolean {
        return !this.decoded && this.userSightingBottle.brand?.dateRequired || false;
    }

    public get lotDateIsAvailable(): boolean {
        return this.userSightingBottle.brand?.dateAvailable || false;
    }

    public get lotTimeIsRequired(): boolean {
        return !this.decoded && this.userSightingBottle.brand?.timeRequired || false;
    }

    public get lotTimeIsAvailable(): boolean {
        return this.userSightingBottle.brand?.timeAvailable || false;
    }

    public get currencies(): ICurrency[] {
        return this.$store.state.sighting.currencies ?? [];
    }

    public get customers(): ISubject[] {
        return this.$store.state.sighting.customers ?? [];
    }

    public get companies(): ISubject[] {
        return this.$store.state.sighting.companies ?? [];
    }

    public get importers(): ISubject[] {
        return this.companies.concat(this.customers).sort((a, b) => a.name.localeCompare(b.name));
    }

    // Manage photos
    public onDragEnter(event: DragEvent): void {
        this.dragging = true;
    }

    public onDragLeave(event: DragEvent): void {
        this.dragging = false;
    }

    public async onDropFiles(event: DragEvent): Promise<void> {
        this.dragging = false;
        this.loading = true;
        const filePromises = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
            filePromises.push(this.uploadFile(event.dataTransfer.files[i]));
        }
        await Promise.all(filePromises);
        this.loading = false;
    }

    public async handleFiles(event): Promise<void> {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.target.files.length; i++) {
            await this.uploadFile(event.target.files[i]);
        }
    }

    public async uploadFile(file: File): Promise<void> {
        const metadata = {
            name: file.name,
        };
        if (file.type.startsWith("image")) {
            this.userSightingBottle.photos.push(await this.azureBlobService.uploadFile(file, "usersighting", metadata));
        }
    }

    public deletePhoto(index: number) {
        this.userSightingBottle.photos = this.userSightingBottle.photos.filter((photo, i) => i !== index);
    }

    // Lightbox sur les images
    public showImg(index: number) {
        this.index = index;
        this.visible = true;
    }

    public handleHide() {
        this.visible = false;
    }

    // Brand Managing
    public get brands(): IBrand[] {
        return this.$store.getters["sighting/getBrands"]();
    }

    public get brandQualityDocument(): string {
        if (this.userSightingBottle.brandQualityId) {
            const brandQuality = this.$store.getters["sighting/getBrandQuality"](this.userSightingBottle.brandId, this.userSightingBottle.brandQualityId);
            if (brandQuality?.document && this.azureBlobService) {
                return this.azureBlobService.getDownloadUrl(brandQuality.document);
            }
        }
        return "";
    }

    public cleanSelectBrandQuality() {
        this.userSightingBottle.brandQualityId = null;
        this.userSightingBottle.quality = null;
    }

    public async onBrandChanged(brand: IBrand): Promise<void> {
        if (brand.brandId) {
            await this.$store.dispatch("sighting/fetchBrandQualities", brand.brandId);
            const currentBrand = this.$store.getters["sighting/getBrand"](brand.brandId);
            this.brandQualities = currentBrand.qualities;

            this.userSightingBottle.brandId = brand.brandId;
            this.userSightingBottle.brand = { ...currentBrand, qualities: [] };
            this.filteredBrandQualities = currentBrand.qualities;
        }

        this.cleanSelectBrandQuality();
    }

    public async onImporterChanged(customer: ISubject): Promise<void> {
        this.userSightingBottle.importerSubjectId = customer?.subjectId;
    }

    public async onImporterRemoved(): Promise<void> {
        this.userSightingBottle.importerSubjectId = null;
    }

    public onSearchBrandChanged(query: string) {
        if (this.searchBrandTimeout) {
            clearTimeout(this.searchBrandTimeout);
        }
        this.searchBrandTimeout = setTimeout(async () => {
            this.searchBrand(query);
        }, 400);
    }

    public searchBrand(query: string) {
        this.filteredBrands = this.brands.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));
    }

    public async onBrandQualityChanged(brandQuality: IBrandQuality): Promise<void> {
        if (brandQuality.brandQualityId) {
            const q = this.$store.getters["sighting/getBrandQuality"](this.userSightingBottle.brandId, brandQuality.brandQualityId);
            this.userSightingBottle.quality = { ...q, sizes: [] };

            this.userSightingBottle.brandQualityId = brandQuality.brandQualityId;
        }

        if (!this.userSightingBottle.brand.dateRequired && this.userSightingBottle.lotDate) {
            this.userSightingBottle.lotDate = null;
        }

        if (!this.userSightingBottle.brand.timeRequired && this.userSightingBottle.lotTime) {
            this.userSightingBottle.lotTime = null;
        }
    }

    public onSearchBrandQualityChanged(query: string) {
        if (this.searchBrandQualityTimeout) {
            clearTimeout(this.searchBrandQualityTimeout);
        }
        this.searchBrandQualityTimeout = setTimeout(async () => {
            await this.searchBrandQuality(query);
        }, 400);
    }

    public async searchBrandQuality(query: string): Promise<void> {
        this.filteredBrandQualities = this.brandQualities.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));
    }

    public onClickDecoded(event): void {
        this.userSightingBottle.decoded = JSON.parse(event.target.value);
        if (this.userSightingBottle.decoded) {
            this.userSightingBottle.lotNumber = "Decoded";
        }
        else {
            this.userSightingBottle.lotNumber = "";
        }
    }

    public onOtherVolumeChange(event): void {
        this.userSightingBottle.alcoholVolume = null;
    }

    public onOtherSizeChange(event): void {
        this.userSightingBottle.size = null;
    }

    public deleteBottle(): void {
        this.$emit(this.deleteEvent, this.indexBottle);
    }

    public onLotTimeChanged(event: InputEvent): void {
        if (event.inputType === "insertText" && this.userSightingBottle.lotTime.length === 2) {
            this.userSightingBottle.lotTime = `${this.userSightingBottle.lotTime}:`;
        }
    }

    public onLotDateChanged(event: InputEvent): void {
        if (event.inputType === "insertText" && (this.userSightingBottle.lotDate.length === 4 || this.userSightingBottle.lotDate.length === 7)) {
            this.userSightingBottle.lotDate = `${this.userSightingBottle.lotDate}/`;
        }
    }

    public onLotNumberChanged(): void {
        this.userSightingBottle.lotNumber = this.userSightingBottle.lotNumber.toUpperCase();
    }

    public onBackLabelPartNumberChanged(): void {
        this.userSightingBottle.backLabelPartNumber = this.userSightingBottle.backLabelPartNumber.toUpperCase();
    }
}
