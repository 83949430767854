import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    name: "previous-value-tooltip",
})
export default class SortableColumnHeader extends Vue {
    @Prop()
    public readonly value: string | number;

    @Prop()
    public readonly previous: string;

    public get previousValue(): string | number {
        if (typeof this.value === "number" && this.previous) {
            return Number(this.previous);
        } else {
            return this.previous;
        }
    }
}
