import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import { Loader } from "@/components";
import VueEasyLightbox from "vue-easy-lightbox";
import * as icons from "@/components/shared/icons";

import {
    IUserSighting,
    ISighting,
    IBrandCompanyRequest,
    IBrand,
    IBrandCompany,
    IBrandCompanyRequestFile,
} from "@/models";
import { AzureBlobService } from "@/services";

@Component({
    name: "brand-company-request-form",
    components: {
        "datepicker": Datepicker,
        Loader,
        VueEasyLightbox,
        "add-icon": icons.AddIcon,
        "upload-icon": icons.UploadIcon,
    },
})
export default class BrandCompanyRequestForm extends Vue {

    public uploadedFile: File;
    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public loading: boolean;
    public downloadLink: string;
    public dragging: boolean;
    public azureBlobService: AzureBlobService;

    public ordersInfo: any[];
    public files: IBrandCompanyRequestFile[];

    public comment: string;

    public visible: boolean;
    public index: number;

    constructor() {
        super();

        this.loadPromises = [];
        this.isLoaded = false;
        this.azureBlobService = null;
        this.loading = false;
        this.uploadedFile = null;
        this.downloadLink = null;
        this.dragging = false;

        this.ordersInfo = [];
        this.files = [];

        this.comment = null;

        this.visible = false;
        this.index = 0;

        if (!this.$store.state.sighting.brands) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrands"));
        }
        if (!this.$store.state.app.brandCompanies) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrandCompanies"));
        }
        if (!this.$store.state.sighting.brandCompanyRequests) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrandCompanyRequests"));
        }
        else {
            this.$store.dispatch("sighting/fetchBrandCompanyRequests");
        }
        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName, this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
        if (this.brandCompanyRequest) {
            this.ordersInfo = JSON.parse(this.brandCompanyRequest.ordersInfo) || [];
            this.files = JSON.parse(this.brandCompanyRequest.filesInfo) || [];
        }
        this.isLoaded = true;
    }

    public get brandCompanyRequestId(): number {
        return this.$route.params.brandCompanyRequestId ? parseInt(this.$route.params.brandCompanyRequestId, 10) : null;
    }

    public get brandCompanyRequest(): IBrandCompanyRequest {
        if (this.brandCompanyRequestId) {
            return this.$store.getters["sighting/getBrandCompanyRequest"](this.brandCompanyRequestId);
        }
        return null;
    }

    public get photosWithFullUrl(): string[] {
        if (this.azureBlobService) {
            return this.brandCompanyRequest.photos.map(photo => this.azureBlobService.getDownloadUrl(photo));
        }
    }

    public get brand(): IBrand {
        return this.$store.getters["sighting/getBrand"](this.brandCompanyRequest?.brandId);
    }

    public get brandOwner(): IBrandCompany {
        return this.$store.getters["sighting/getBrandCompany"](this.brand?.brandCompanyId);
    }

    public get brandQuality(): IBrand {
        return this.$store.getters["sighting/getBrandQuality"](this.brandCompanyRequest?.brandId, this.brandCompanyRequest?.brandQualityId);
    }

    public addOrderInfo(): void {
        this.ordersInfo.push({
            selected: false,
            orderNumber: null,
            soldToNo: null,
            soldToName: null,
            shipToNo: null,
            shipToName: null,
            shipmentDate: null,
            poNumber: null,
            totalCases: null,
            sku: null,
            description: null,
            bottlesPerCase: null,
            casesPerPallet: null,
            sscc: null,
        });
    }

    public deleteOrderInfo(index: number): void {
        this.ordersInfo.splice(index, 1);
    }

    public onOrderInfoPaste(event, index: number): void {
        const text: string = event.clipboardData.getData("Text");
        const lines = text.split("\n").filter(x => x);
        let lineIndex = 0;
        for (let line of lines) {
            if (line.includes("\t")) {
                if (this.ordersInfo.length <= index + lineIndex) {
                    this.addOrderInfo();
                }
                // Remove Carriage Returns if on windows or an old mac
                if (line.includes("\r")) {
                    line = line.replace("\r", "");
                }

                const values = line.split("\t");
                const currentValue = this.ordersInfo[index + lineIndex];

                [currentValue.lotNumber,
                currentValue.palletDate,
                currentValue.palletTime,
                currentValue.sscc,
                currentValue.orderNumber,
                currentValue.soldToNo,
                currentValue.soldToName,
                currentValue.shipToNo,
                currentValue.shipToName,
                currentValue.poNumber,
                currentValue.sku,
                currentValue.description,
                currentValue.transQty] = values;
            }
            else {
                this.ordersInfo[index + lineIndex].orderNumber = line;
            }
            lineIndex++;
        }
    }

    // Lightbox sur les images
    public showImg(index: number): void {
        this.index = index;
        this.visible = true;
    }

    public handleHide(): void {
        this.visible = false;
    }

    public answerBrandCompanyRequest(isShipmentInfoAvailable: boolean): void {
        this.$store.dispatch("sighting/answerBrandCompanyRequests", {
            brandCompanyRequestId: this.brandCompanyRequestId,
            ordersInfo: JSON.stringify(this.ordersInfo),
            filesInfo: JSON.stringify(this.files),
            isShipmentInfoAvailable,
        });

        this.$router.push("/");
    }

    public onDragEnter(event: DragEvent): void {
        this.dragging = true;
    }

    public onDragLeave(event: DragEvent): void {
        this.dragging = false;
    }

    public async onDropFiles(event: DragEvent): Promise<void> {
        this.dragging = false;
        this.loading = true;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
            await this.uploadFile(event.dataTransfer.files[i]);
        }
        this.loading = false;
    }

    public async uploadFile(file: File): Promise<void> {
        const metadata = {
            name: file.name,
        };
        const link = await this.azureBlobService.uploadFile(file, "brandCompanyRequest", metadata);

        this.files.push({ name: file.name, link });
    }

    public deleteFile() {
        this.uploadedFile = null;
        this.files = [];
    }
}
