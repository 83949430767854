import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ISighting, AnalysisStatus, IReporterRequest, IMarket } from "@/models";
import { AzureBlobService } from "@/services";
import { Loader } from "@/components";
import Paginate from "vuejs-paginate";

@Component({
    name: "deletesighting",
    components: {
        Loader,
        Paginate,
    },
})
export default class DeleteSighting extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public azureBlobService: AzureBlobService;
    public selectedStatus: AnalysisStatus;
    public key: string;
    public selectedSighting: number;

    public page: number;
    private pageSize = 20;

    public constructor() {
        super();
        this.loadPromises = [];
        this.isLoaded = false;
        this.selectedStatus = AnalysisStatus.Research;
        this.azureBlobService = null;
        this.key = "";
        this.selectedSighting = 0;
        this.page = 1;

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }

        const params = {
            status: 1,
            keySearch: this.key,
            pageSize: this.pageSize,
            pageNumber: this.page,
            includeCompleted: true,
            sortDirection: "DESC",
            sortBy: "UserSightingId",
        };

        if (!this.$store.state.sighting.sightings) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchSightingsWithoutFrontFilter", params));
        }
        else {
            this.$store.dispatch("sighting/fetchSightingsWithoutFrontFilter", params);
        }

        if (!this.$store.state.sighting.sightings) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchReporterRequests"));
        }
        else {
            this.$store.dispatch("sighting/fetchReporterRequests");
        }
        if (!this.$store.state.sighting.markets) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchMarkets"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.isLoaded = true;
        this.azureBlobService = new AzureBlobService(
            this.$store.state.app.blobSettings.containerName,
            this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
    }

    public get totalPages(): number {
        const total = this.$store.getters["sighting/getSightingsTotal"]();
        return Math.ceil(total / this.pageSize);
    }

    public get sightings(): ISighting[] {
        return this.$store.getters["sighting/getSightingByStatus"](this.selectedStatus, this.key) ?? [];
    }

    public navigateToPage(page: number): void {
        this.page = page;

        const params = {
            status: this.selectedStatus,
            keySearch: this.key,
            pageSize: this.pageSize,
            pageNumber: this.page,
            includeCompleted: true,
            sortDirection: "DESC",
            sortBy: "UserSightingId",
        };

        this.$store.dispatch("sighting/fetchSightingsWithoutFrontFilter", params);
    }

    public setStatus(statusId: number): void {
        if (this.selectedStatus !== statusId) {
            this.selectedStatus = statusId;
            this.page = 1;

            const params = {
                status: statusId,
                keySearch: this.key,
                pageSize: this.pageSize,
                pageNumber: this.page,
                includeCompleted: true,
                sortDirection: "DESC",
                sortBy: "UserSightingId",
            };

            this.$store.dispatch("sighting/fetchSightingsWithoutFrontFilter", params);
        }
    }

    public getAnalysisStatuslabel(sighting: ISighting): string {
        return AnalysisStatus[sighting.analysisStatus];
    }

    public getPhotosWithFullUrl(photo): string {
        if (this.azureBlobService) {
            return this.azureBlobService.getDownloadUrl(photo);
        }
    }

    public getSightingReporterRequest(sighting: ISighting): IReporterRequest {
        return this.$store.getters["sighting/getReporterRequestsBySightingId"](sighting.sightingId);
    }

    public openModal(modalName: string, sightingId: number): void {
        this.$modal.show(modalName);
    }

    public assignSelected(sightingId: number): void {
        this.selectedSighting = sightingId;
    }

    public closeModal(modalName: string): void {
        this.$modal.hide(modalName);
    }

    public deleteSighting(): void {
        const params = {
            sightingId: this.selectedSighting,
            status: this.selectedStatus,
            keySearch: this.key,
            pageSize: this.pageSize,
            pageNumber: this.page,
            includeCompleted: true,
            sortDirection: "DESC",
            sortBy: "UserSightingId",
        };
        this.$store.dispatch("sighting/deleteSighting", params);
        this.closeModal("deleteSighting");
    }
}
