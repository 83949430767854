import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { ICurrency } from "@/models";

class CurrencyService extends ElintApiService {
    public async getAllCurrencies(): Promise<ICurrency[]> {
        try {
            const response = await fetch("/api/Currencies", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const currencies = await response.json();
                return currencies;
            }
            else {
                NotificationService.error("Error getting currencies !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting currencies !");
            return null;
        }
    }
}

const currencyService = new CurrencyService();

export {CurrencyService, currencyService};
