import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IPendingUser } from "@/models";

class PendingUserService extends ElintApiService {
    public async getAllPendingUsers(): Promise<IPendingUser[]> {
        try {
            const response = await fetch("/api/pendingUsers", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                return await response.json();
            }
            else {
                NotificationService.error("Error getting pending users !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting pending users !");
            return [];
        }
    }

    public async deletePendingUser(pendingUserId: number): Promise<void> {
        try {
            const response = await fetch(`/api/pendingUsers/${pendingUserId}/delete`, {
                method: "DELETE",
                headers: await this.getHeaders(),
            });

            if (response.ok) {
                NotificationService.success("Pending User deleted!");
            } else {
                NotificationService.error("Error deleting pending user.");
            }
        } catch (error) {
            NotificationService.error("Exception deleting pending user!");
        }
    }

    public async invitePendingUser(pendingUserId: number): Promise<void> {
        try {
            const response = await fetch(`/api/pendingUsers/${pendingUserId}/invite`, {
                method: "POST",
                headers: await this.getHeaders(),
            });

            if (response.ok) {
                NotificationService.success("Pending User invited!");
            } else {
                NotificationService.error("Error inviting pending user.");
            }
        } catch (error) {
            NotificationService.error("Exception inviting pending user!");
        }
    }
}

const pendingUserService = new PendingUserService();

export { PendingUserService, pendingUserService };
