import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    name: "pinned",
})
export default class Pinned extends Vue {
    private data: any[];

    constructor() {
        super();

    }
}
