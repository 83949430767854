import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IReporterRequest, ReporterRequestStatus, IBrandCompanyRequest, ISighting, IMarketCompany, IBrandCompany, IBrand } from "@/models";
import { Loader } from "@/components";
import { AzureBlobService } from "@/services";

@Component({
    name: "brand-company-request-dashboard",
    components: {
        Loader,
    },
})
export default class BrandCompanyRequestDashboard extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public azureBlobService: AzureBlobService;
    public key: string;

    public constructor() {
        super();
        this.loadPromises = [];
        this.isLoaded = false;
        this.azureBlobService = null;
        this.key = null;

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }
        if (!this.$store.state.sighting.brandCompanyRequests) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrandCompanyRequests"));
        }
        else {
            this.$store.dispatch("sighting/fetchBrandCompanyRequests");
        }
        if (!this.$store.state.sighting.markets) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchMarkets"));
        }
        if (!this.$store.state.sighting.brandCompanies) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrandCompanies"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.isLoaded = true;
        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName, this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
    }

    public get brandCompanyRequests(): IBrandCompanyRequest[] {
        return this.$store.getters["sighting/searchBrandCompanyRequests"](this.key) ?? [];
    }

    public getBrand(brandId: number): IBrand {
        return this.$store.getters["sighting/getBrand"](brandId);
    }

    public getBrandQuality(brandId: number, brandQualityId: number): IBrand {
        return this.$store.getters["sighting/getBrandQuality"](brandId, brandQualityId);
    }

    public getBrandCompany(brandCompanyId: number): IBrandCompany {
        return this.$store.getters["sighting/getBrandCompany"](brandCompanyId);
    }
}
