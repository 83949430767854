import { NavigationGuard } from "vue-router";
import { store } from "@/store";

export const authGuard: NavigationGuard = async (to, from, next) => {
    const skipGuard = to.matched.some(route => route.meta.skipGuard);

    if (!skipGuard) {
        if (!store.state.app.loaded) {
            await store.dispatch("app/load");
            if (store.getters["app/isAuthenticated"]) {
                await store.dispatch("sighting/fetchData");
            }
        }
        const allowAnonymous = to.matched.some(route => route.meta.allowAnonymous);

        if (!allowAnonymous && !store.getters["app/isAuthenticated"]) {
            next("/login");
        }
        else {
            next();
        }
    }
    else {
        next();
    }
};
