import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IMarketCompanyRequest } from "@/models";

class MarketCompanyRequestService extends ElintApiService {

    public async getAllMarketCompanyRequests(): Promise<IMarketCompanyRequest[]> {
        try {
            const response = await fetch("/api/marketcompanyrequests", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const marketCompanyRequests = await response.json();
                return marketCompanyRequests;
            }
            else {
                NotificationService.error("Error getting market company requests !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting market company requests !");
        }
    }

    public async getActiveMarketCompanyRequests(): Promise<IMarketCompanyRequest[]> {
        try {
            const response = await fetch("/api/marketcompanyrequests/active", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const marketCompanyRequests = await response.json();
                return marketCompanyRequests;
            }
            else {
                NotificationService.error("Error getting market company requests !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting market company requests !");
        }
    }

    public async addMarketCompanyRequest(data): Promise<void> {
        try {
            const response = await fetch("/api/marketcompanyrequests", {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Market Company request added !");
            }
            else {
                NotificationService.error("Error adding market company request !");
            }
        }
        catch (error) {
            NotificationService.error("Exception adding market company request !");
        }
    }

    public async sendMarketCompanyRequests(marketCompanyRequestIds: number[]) {
        try {
            const response = await fetch("/api/marketcompanyrequests/send", {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(marketCompanyRequestIds),
            });
            if (response.ok) {
                NotificationService.success("Market company requests sent !");
            }
            else {
                NotificationService.error("Error sending market company requests !");
            }
        }
        catch (error) {
            NotificationService.error("Exception sending market company requests !");
        }
    }

    public async answerMarketCompanyRequests(data: any) {
        try {
            const response = await fetch(`/api/marketcompanyrequests/${data.marketCompanyRequestId}/answer`, {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Market company request answered !");
            }
            else {
                NotificationService.error("Error answering market company request !");
            }
        }
        catch (error) {
            NotificationService.error("Exception answering market company request !");
        }
    }
}

const marketCompanyRequestService = new MarketCompanyRequestService();

export {MarketCompanyRequestService, marketCompanyRequestService};
