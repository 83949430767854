import {
    required,
    minValue,
    maxValue,
    decimal,
} from "vuelidate/lib/validators";
import { RuleDecl } from "vue/types/options";
import SightingForm from "./SightingForm";

export const sightingFormValidations: RuleDecl = {
    brandId: {
        required,
        minValue: minValue(1),

    },
    brandQualityId: {
        required,
        minValue: minValue(1),
    },
    sightingInternal: {
        analysisStatus: {
            required,
            minValue: minValue(1),
        },
        size: {
            required,
            decimal,
        },
        alcoholVolume: {
            required,
            decimal,
        },
        // brand company tab
        lotNumber: {
            required,
        },
        lotDate: {
            required,
        },
        lotTime: {
            required,
        },
        backLabelPartNumber: {
            required,
        },
        caseCode: {
            required,
        },
        palletCodeSscc: {
            required,
        },

        // market company tab
        originatingMarketCompanyId: {
            required,
        },

        // customer tab
        customerSubjectId: {
            required,
        },

        // Importer tab
        importerSubjectId: {
            required,
        },

        // Outlet tab
        outletType: {
            required,
        },
        outletName: {
            required,
        },
        street: {
            required,
        },
        zipCode: {
            required,
        },
        townCity: {
            required,
        },
        countryState: {
            required,
        },
        volumeImpact: {
            minValue: minValue(0),
            maxValue: maxValue(999999),
        },
    },
    reporterRequestLotNumber: {
        required: atLeastOneOptionSelected,
    },
    reporterRequestBottlingDate: {
        required: atLeastOneOptionSelected,
    },
    reporterRequestBottlingTime: {
        required: atLeastOneOptionSelected,
    },
    reporterRequestBackLabelPhotograph: {
        required: atLeastOneOptionSelected,
    },
    reporterRequestProductQuantityPhotograph: {
        required: atLeastOneOptionSelected,
    },
};

function atLeastOneOptionSelected(this: SightingForm ): boolean {
    if (this) {
        return this.reporterRequestLotNumber ||
            this.reporterRequestBottlingDate ||
            this.reporterRequestBottlingTime ||
            this.reporterRequestBackLabelPhotograph ||
            this.reporterRequestProductQuantityPhotograph;
    } else {
        return false;
    }
}
