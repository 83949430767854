import Vue from "vue";
import { Component } from "vue-property-decorator";

import { IBrand, IBrandQuality, ISubject } from "@/models";

import Multiselect from "vue-multiselect";
import { AzureBlobService } from "@/services";
import { brandValidations } from "./BrandValidations";

@Component({
    name: "brands",
    components: {
        Multiselect,
    },
    validations: brandValidations,
})
export default class Brands extends Vue {

    public loadPromises: Array<Promise<void>>;

    public selectedBrand: IBrand;
    public selectedBrandQuality: IBrandQuality;

    public newBrand: INewBrand;

    public brandName: string;
    public brandCompanyId: number;

    public isEditingBrandSettings: boolean;
    public isAddingNewBrand: boolean;

    public azureBlobService: AzureBlobService;

    constructor() {
        super();

        this.loadPromises = [];

        this.selectedBrand = null;
        this.selectedBrandQuality = null;

        this.newBrand = {
            brandName: "",
            brandCompanyId: 0,
        };

        this.brandName = "";
        this.brandCompanyId = null;

        this.isEditingBrandSettings = false;
        this.isAddingNewBrand = false;

        if (this.brands.length === 0) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrands"));
        }
        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }
        if (!this.$store.state.app.brandCompanies) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrandCompanies"));
        }
    }

    // hooked
    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);

        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName, this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
    }

    public selectBrand(brand: IBrand): void {
        this.isAddingNewBrand = false;
        this.isEditingBrandSettings = false;
        this.selectedBrand = brand;
        this.selectedBrandQuality = null;
        this.$store.dispatch("sighting/fetchBrandQualities", this.selectedBrand.brandId);
    }

    public get brandCompanies(): ISubject[] {
        return this.$store.state.sighting.brandCompanies || [];
    }

    public async selectBrandQuality(brandQuality: IBrandQuality) {
        this.isEditingBrandSettings = false;
        this.selectedBrandQuality = brandQuality;
    }

    public displayBrandSettings() {
        this.isEditingBrandSettings = true;
        this.selectedBrandQuality = null;
    }

    public displayNewBrand() {
        this.newBrand = {
            brandName: "",
            brandCompanyId: null,
        };
        this.selectedBrand = null;
        this.selectedBrandQuality = null;
        this.isEditingBrandSettings = false;
        this.isAddingNewBrand = true;
    }

    public addBrandQuality() {
        this.isAddingNewBrand = false;
        this.isEditingBrandSettings = false;

        this.selectedBrandQuality = {
            brandQualityId: 0,
            brandId: this.selectedBrand.brandId,
            name: "",
            brand: null,
        };
    }

    public async saveBrandQuality(): Promise<void> {
        if (!this.$v.selectedBrandQuality.name.$invalid) {
            await this.$store.dispatch("sighting/upsertBrandQuality", this.selectedBrandQuality);
        }
    }

    public async addNewBrand(): Promise<void> {
        if (!this.$v.brandName.$invalid && !this.$v.brandCompanyId.$invalid) {
            this.newBrand = {
                brandName: this.brandName,
                brandCompanyId: this.brandCompanyId,
            };
            await this.$store.dispatch("sighting/addBrand", this.newBrand).then(() => {
                this.$store.dispatch("sighting/fetchBrands");

                this.isAddingNewBrand = false;
                this.brandName = "";
                this.brandCompanyId = null;
            });
        }
    }

    public async editBrand(): Promise<void> {
        if (!this.$v.selectedBrand.name.$invalid) {
            await this.$store.dispatch("sighting/editBrand", this.selectedBrand);
            this.isEditingBrandSettings = false;
        }
    }

    // Manage files
    public async handleFiles(event): Promise<void> {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.target.files.length; i++) {
            await this.uploadFile(event.target.files[i]);
        }
    }

    public async uploadFile(file: File): Promise<void> {
        const metadata = {
            name: file.name,
        };
        this.selectedBrandQuality.document = await this.azureBlobService.uploadFile(file, "brandQuality", metadata);
    }

    public deleteFile(): void {
        this.selectedBrandQuality.document = null;
    }

    public downloadDocument(): void {
        window.open(this.brandQualityDocument, "_blank");
    }

    // Computed
    public get brands(): IBrand[] {
        return this.$store.getters["sighting/getBrands"]();
    }

    public get brandQualities(): IBrandQuality[] {
        return this.selectedBrand.qualities;
    }

    public get brandQualityDocument(): string {
        if (this.selectedBrandQuality && this.selectedBrandQuality.document && this.azureBlobService) {
            return this.azureBlobService.getDownloadUrl(this.selectedBrandQuality.document);
        }
        return null;
    }
}

interface INewBrand {
    brandName: string;
    brandCompanyId: number;
}
