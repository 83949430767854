import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
    name: "lists",
})
export default class Lists extends Vue {

    constructor() {
        super();

    }
}
