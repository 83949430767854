import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";

class DataService extends ElintApiService {
    public async getData(): Promise<any> {
        try {
            const response = await fetch("/api/datas", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            }
            else {
                NotificationService.error("Error getting data !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting data !");
            return null;
        }
    }
}

const dataService = new DataService();

export {DataService, dataService};
