import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "./AuthGuard";
import { roleGuard } from "./RoleGuard";
import { profileGuard } from "./ProfileGuard";
import {
    Login,
    NotFound,
    Unauthorized,
    Home,
    UserSightingDashboard,
    UserSightingForm,
    UserSightingView,
    SightingDashboard,
    SightingForm,
    StoreLocatorMap,
    Pinned,
    Users,
    User,
    Brands,
    Lists,
    SightingRequestDashboard,
    BrandCompanyRequestForm,
    Bottles,
    EditBottles,
    BrandCompanyRequestDashboard,
    Subjects,
    SubjectForm,
    MarketCompanyRequestDashboard,
    MarketCompanyRequestForm,
    DeleteSighting,
    PendingUsers,
} from "@/views";
import { Role } from "@/models";
import { cookieService } from "@/services";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
        },
        {
            path: "/user-sightings",
            name: "UserSightingDashboard",
            component: UserSightingDashboard,
            meta: {
                roleLevelRequirement: Role.BrandCompanyContact,
            },
        },
        {
            path: "/user-sightings/new",
            name: "NewUserSighting",
            component: UserSightingForm,
        },
        {
            path: "/user-sightings/:userSightingId",
            name: "ViewUserSighting",
            component: UserSightingView,
        },
        {
            path: "/user-sightings/:userSightingId/edit",
            name: "EditUserSighting",
            component: UserSightingForm,
        },
        {
            path: "/sightings",
            name: "SightingDashboard",
            component: SightingDashboard,
            meta: {
                roleLevelRequirement: Role.TraceTeam,
            },
        },
        {
            path: "/sightings/:sightingId/edit",
            name: "Sighting",
            component: SightingForm,
        },
        {
            path: "/sighting-requests",
            name: "SightingRequestDashboard",
            component: SightingRequestDashboard,
            meta: {
                roleLevelRequirement: Role.TraceTeam,
            },
        },
        {
            path: "/brand-company-requests",
            name: "BrandCompanyRequestDashboard",
            component: BrandCompanyRequestDashboard,
            meta: {
                roleLevelRequirement: Role.BrandCompanyContact,
            },
        },
        {
            path: "/brand-company-requests/:brandCompanyRequestId/edit",
            name: "BrandCompanyRequestForm",
            component: BrandCompanyRequestForm,
            meta: {
                roleLevelRequirement: Role.BrandCompanyContact,
            },
        },
        {
            path: "/market-company-requests",
            name: "MarketCompanyRequestDashboard",
            component: MarketCompanyRequestDashboard,
            meta: {
                roleLevelRequirement: Role.BrandCompanyContact,
            },
        },
        {
            path: "/market-company-requests/:marketCompanyRequestId/edit",
            name: "MarketCompanyRequestForm",
            component: MarketCompanyRequestForm,
            meta: {
                roleLevelRequirement: Role.BrandCompanyContact,
            },
        },
        {
            path: "/subjects",
            name: "Subjects",
            component: Subjects,
        },
        {
            path: "/subjects/:subjectId",
            name: "SubjectDetails",
            component: SubjectForm,
        },
        {
            path: "/store-locator/map",
            name: "StoreLocatorMap",
            component: StoreLocatorMap,
        },
        {
            path: "/store-locator/pinned",
            name: "Pinned",
            component: Pinned,
        },
        {
            path: "/bs-team/bottles",
            name: "Bottles",
            component: Bottles,
        },
        {
            path: "/bs-team/bottles/edit",
            name: "EditBottles",
            component: EditBottles,
        },
        {
            path: "/manage/users",
            name: "Users",
            component: Users,
            meta: {
                roleLevelRequirement: Role.Administrator,
            },
        },
        {
            path: "/manage/sightings",
            name: "deletesighting",
            component: DeleteSighting,
            meta: {
                roleLevelRequirement: Role.Administrator,
            },
        },
        {
            path: "/manage/lists",
            name: "Lists",
            component: Lists,
            meta: {
                roleLevelRequirement: Role.Administrator,
            },
        },
        {
            path: "/manage/brands",
            name: "Brands",
            component: Brands,
            meta: {
                roleLevelRequirement: Role.Administrator,
            },
        },
        {
            path: "/manage/pendingusers",
            name: "PendingUsers",
            component: PendingUsers,
            meta: {
                roleLevelRequirement: Role.Administrator,
            },
        },
        {
            path: "/my-profile",
            name: "user",
            component: User,
        },
        {
            path: "/login",
            name: "login",
            component: Login,
            meta: {
                allowAnonymous: true,
            },
        },
        {
            path: "/unauthorized",
            name: "unauthorized",
            component: Unauthorized,
            meta: {
                allowAnonymous: true,
                skipGuard: true, // FCU : workaround to avoid infiniet load of the application
            },
        },
        {
            path: "/auth",
            redirect: to => {
                const redirectPath = cookieService.getCookie("exp.source") || "/";
                cookieService.deleteCookie("exp.source");
                return {
                    path: redirectPath,
                    hash: "",
                };
            },
        },
        {
            path: "*",
            name: "not-found",
            component: NotFound,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

router.beforeEach(authGuard);
router.beforeEach(roleGuard);
router.beforeEach(profileGuard);

export { router };
