const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const SET_CONFIG = "SET_CONFIG";
const SET_BLOB_SETTINGS = "SET_BLOB_SETTINGS";
const SET_GOOGLE_MAPS_CONFIG = "SET_GOOGLE_MAPS_CONFIG";
const SET_LOADED = "SET_LOADED";
const SET_USER_ROLE = "SET_USER_ROLE";
const SET_USER_PROFILE = "SET_USER_PROFILE";

// [USERS]
const ADD_USERS = "ADD_USERS";

// [PENDINGUSERS]
const ADD_PENDING_USERS = "ADD_PENDING_USERS";

export {
    LOGIN,
    LOGOUT,
    SET_CONFIG,
    SET_BLOB_SETTINGS,
    SET_GOOGLE_MAPS_CONFIG,
    SET_LOADED,
    SET_USER_ROLE,
    SET_USER_PROFILE,

    ADD_USERS,
    ADD_PENDING_USERS,
};
