import {
    BlobServiceClient,
    BlockBlobParallelUploadOptions,
    AnonymousCredential,
} from "@azure/storage-blob";
import { IMetadataObject } from "@/models";

export class AzureBlobService {

    private readonly blobServiceClient: BlobServiceClient;

    constructor(private containerName: string, private blobUri: string, private sasToken: string, private blobPrefix: string = "") {
        this.containerName = containerName;
        this.blobPrefix = blobPrefix;
        this.blobUri = blobUri;
        this.sasToken = sasToken;

        this.blobServiceClient  = new BlobServiceClient(this.blobUriWithSas, new AnonymousCredential());
    }

    private get blobUriWithSas(): string {
        return `${this.blobUri}?${this.sasToken}`;
    }

    public async uploadFile(file: File, blobPrefix: string = "", metadata?: IMetadataObject): Promise<string> {
        const uniqueName = this.generateGuid();
        const uniquePath = blobPrefix !== "" ? `${this.buildBlobPrefix(blobPrefix)}/${uniqueName}` : uniqueName;
        const containerClient = this.blobServiceClient.getContainerClient(this.containerName);
        const blockBlobClient = containerClient.getBlockBlobClient(uniquePath);

        const uploadOptions: BlockBlobParallelUploadOptions = {
            metadata: this.encodeObject(metadata),
            blobHTTPHeaders: {
                blobContentDisposition: this.buildContentDispositionHeader(file.name),
            },
        };
        await blockBlobClient.uploadBrowserData(file, uploadOptions);
        return decodeURIComponent(blockBlobClient.url.split("?")[0]);
    }

    public getDownloadUrl(fileLink: string): string {
        return `${fileLink}${this.sasToken}`;
    }

    // Source: http://guid.us/GUID/JavaScript
    private generateGuid(): string {
        const guidPart = () => {
            // tslint:disable-next-line:no-bitwise
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };

        // tslint:disable-next-line:max-line-length
        return `${guidPart() + guidPart()}-${guidPart()}-4${guidPart().substr(0, 3)}-${guidPart()}-${guidPart() + guidPart() + guidPart()}`.toLowerCase();
    }

    private buildBlobPrefix(...fragments: string[]): string {
        let parts = [this.blobPrefix, ...fragments];

        parts = parts.filter(prefix => !!prefix).map(prefix => prefix.replace(/^\/+|\/+$/, ""));
        return parts.join("/");
    }

    private encodeObject(obj: IMetadataObject): IMetadataObject {
        if (obj != null) {
            for (const key of Object.keys(obj)) {
                obj[key] = encodeURIComponent(obj[key]);
            }
        }

        return obj;
    }

    private buildContentDispositionHeader(fileName: string): string {
        return `attachment;filename="${encodeURIComponent(fileName)}"`;
    }
}
