import Vue from "vue";
import { Component } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import { Loader, UserSightingInfo, PreviousValueTooltip, PeoplePicker } from "@/components";
import VueEasyLightbox from "vue-easy-lightbox";
import * as icons from "@/components/shared/icons";
import {
    ISighting,
    IBrand,
    IBrandQuality,
    IUserSighting,
    ICoding,
    Role,
    AnalysisStatus,
    IMarket,
    IMarketCompany,
    IMarketCompanyCode,
    ISubject,
    ICurrency,
    ISubjectType,
    SubjectType,
    ISubjectPersonnel,
    IUserGraph,
    IBrandCompanyRequestFile,
    IMarketCompanyRequestFile,
    IUserSightingSource,
} from "@/models";
import { productionSiteService, AzureBlobService, subjectService } from "@/services";
import { sightingFormValidations } from "./SightingFormValidations";
import Multiselect from "vue-multiselect";

@Component({
    name: "sighting-form",
    components: {
        "datepicker": Datepicker,
        Loader,
        UserSightingInfo,
        PreviousValueTooltip,
        VueEasyLightbox,
        Multiselect,
        "add-icon": icons.AddIcon,
        "email-icon": icons.EmailIcon,
        "save-icon": icons.SaveIcon,
        "complete-icon": icons.StarIcon,
        "error-icon": icons.ErrorIcon,
        "upload-icon": icons.UploadIcon,
        "people-picker": PeoplePicker,
    },
    validations: sightingFormValidations,
})
export default class SightingForm extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public azureBlobService: AzureBlobService;
    public visible: boolean;
    public index: number;
    public brandQualities: IBrandQuality[];
    public productionSites: ISubject[];
    public originatingMarketCompany: ISubject;
    public selected: string;
    public loading: boolean;
    public dragging: boolean;
    public highlightImage: boolean;
    public isImageDisabled: boolean;

    public sightingInternal: ISighting;

    public brandId: number;
    public brandQualityId: number;

    public volumeImpact: number;

    public reporterRequestStep: number;
    public reporterRequestLotNumber: boolean;
    public reporterRequestBottlingDate: boolean;
    public reporterRequestBottlingTime: boolean;
    public reporterRequestBackLabelPhotograph: boolean;
    public reporterRequestProductQuantityPhotograph: boolean;
    public reporterRequestSendByEmail: boolean;
    public reporterRequestComments: boolean;

    public brandCompanyRequestStep: number;
    public brandCompanyRequestProductionSites: ISubject[];
    public brandCompanyRequestSendByEmail: boolean;

    public marketCompanyRequestSendByEmail: boolean;

    public subjects: ISubject[];

    public files: IBrandCompanyRequestFile[];
    public mcRequestFiles: IMarketCompanyRequestFile[];

    public initOpenBrandTab: boolean;
    public initOpenMarketTab: boolean;
    public initOpenCustomerTab: boolean;
    public initOpenImporterTab: boolean;
    public initOpenOutletTab: boolean;

    public showOpenBrandTabOverflow: boolean;

    public searchBrandTimeout: any;
    public filteredBrands: IBrand[];

    public searchBrandQualityTimeout: any;
    public filteredBrandQualities: IBrandQuality[];

    public searchMarketCompanyTimeout: any;
    public filteredMarkets: IMarket[];
    public filteredMarketCompanies: ISubject[];

    public searchCustomerTimeout: any;
    public filteredCustomers: ISubjectType[];
    public filteredImporters: ISubjectType[];
    public customer: ISubject;
    public importer: ISubject;

    public reporters: IUserGraph[];

    constructor() {
        super();

        this.customer = null;
        this.importer = null;
        this.searchBrandTimeout = null;
        this.searchBrandQualityTimeout = null;
        this.searchMarketCompanyTimeout = null;
        this.searchCustomerTimeout = null;
        this.filteredBrands = [];
        this.filteredBrandQualities = [];
        this.filteredMarketCompanies = [];
        this.filteredMarkets = [];
        this.filteredCustomers = [];
        this.filteredImporters = [];

        this.isImageDisabled = false;
        this.loading = false;
        this.dragging = false;
        this.highlightImage = false;
        this.loadPromises = [];
        this.isLoaded = false;
        this.azureBlobService = null;
        this.visible = false;
        this.index = 0;
        this.brandQualities = [];
        this.productionSites = [];
        this.originatingMarketCompany = null;
        this.selected = null;

        this.sightingInternal = null;

        this.brandId = null;
        this.brandQualityId = null;

        this.volumeImpact = null;

        this.reporterRequestStep = 1;
        this.reporterRequestLotNumber = false;
        this.reporterRequestBottlingDate = false;
        this.reporterRequestBottlingTime = false;
        this.reporterRequestBackLabelPhotograph = false;
        this.reporterRequestProductQuantityPhotograph = false;
        this.reporterRequestSendByEmail = true;
        this.reporterRequestComments = null;

        this.brandCompanyRequestStep = 1;
        this.brandCompanyRequestProductionSites = [];
        this.brandCompanyRequestSendByEmail = true;

        this.marketCompanyRequestSendByEmail = true;

        this.subjects = [];

        this.files = [];
        this.mcRequestFiles = [];

        this.reporters = [];

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }

        if (!this.$store.state.sighting.brands) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrands"));
        }
        if (!this.$store.state.sighting.codings) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchCodings"));
        }
        if (!this.$store.state.app.brandCompanies) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrandCompanies"));
        }
        if (!this.$store.state.sighting.markets) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchMarkets"));
        }
        if (this.$store.getters["sighting/getSubjectsByType"](SubjectType.MarketCompany).length < 1) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchSubjectsByType", SubjectType.MarketCompany));
        }

        if (!this.$store.state.sighting.userSightingSources || this.$store.state.sighting.userSightingSources.length < 1) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchUserSightingSources"));
        }

        this.initOpenBrandTab = false;
        this.initOpenMarketTab = false;
        this.initOpenCustomerTab = false;
        this.initOpenImporterTab = false;
        this.initOpenOutletTab = false;

        this.showOpenBrandTabOverflow = false;
    }

    public get analysisStatus(): IAnalysisStatus[] {
        const enumKeys = Object.keys(AnalysisStatus).map(k => AnalysisStatus[k]);

        const status: IAnalysisStatus[] = [];
        for (const elem of enumKeys) {
            if (typeof (elem) === "number") {
                status.push({
                    analysisStatusId: elem,
                    label: AnalysisStatus[elem],
                });
            }
        }

        return status;
    }

    public async created(): Promise<void> {
        this.loadPromises.push(this.$store.dispatch("sighting/fetchSightingById", this.sightingId));
        this.loadPromises.push(this.$store.dispatch("sighting/fetchSightingPreviousValuesById", this.sightingId));
        await Promise.all(this.loadPromises);
        this.subjects = await subjectService.getAllSubjects();
        this.isLoaded = true;
        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName, this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
        if (this.sighting) {
            this.sightingInternal = {
                ...this.sighting,
                ordersInfo: JSON.parse(this.sighting.ordersInfo) || [],
                customersInfo: JSON.parse(this.sighting.customersInfo) || [],
            };

            const allFiles = JSON.parse(this.sighting.filesInfo) || [];

            for (const file of allFiles) {
                if (file.link.includes("marketCompanyRequest")) {
                    this.mcRequestFiles.push(file);
                } else {
                    this.files.push(file);
                }
            }

            this.brandId = this.sighting.brand.brandId;
            this.brandQualityId = this.sighting.quality.brandQualityId;
            this.brandQualities = this.$store.getters["sighting/getBrand"](this.brandId).qualities;
            this.$store.dispatch("sighting/fetchMarketCompanyCodes", this.brandOwner.subjectId);
            this.customer = this.customerSubjects.filter(cs => cs.subjectId === this.sightingInternal.customerSubjectId)[0];
            this.importer = this.importerSubjects.filter(cs => cs.subjectId === this.sightingInternal.importerSubjectId)[0];
            await this.loadProductionSite();
            await this.loadMarketCompany();

            if (this.loadProductionSite) {
                this.productionSites = this.productionSites.filter(ps => ps.details?.personnels.some(personnel => personnel.isBcRequest === true));
            }

            this.filteredMarkets = this.markets;
            this.reporters = [{
                displayName: this.sightingInternal.user.name,
                email: this.sightingInternal.user.email,
                uniqueId: this.sightingInternal.user.uniqueId,
            }];
        } else {
            this.reporters = [];
        }

        this.initOpenBrandTab = this.statusTabBrandCompany === "error";
        this.showOpenBrandTabOverflow = this.initOpenBrandTab;
        this.initOpenMarketTab = this.statusTabMarketCompany === "error";
        this.initOpenCustomerTab = this.statusTabCustomer === "error";
        this.initOpenImporterTab = this.statusTabImporter === "error";
        this.initOpenOutletTab = this.statusTabOutlet === "error";
        this.filteredBrands = this.brands;
        this.filteredBrandQualities = this.brandQualities;
        this.filteredMarketCompanies = this.marketCompanies;
        this.filteredCustomers = this.customerSubjects;
        this.filteredImporters = this.importerSubjects;
    }

    public async loadProductionSite(): Promise<void> {
        this.productionSites = await productionSiteService.getProductionSitesByBrand(this.brandOwner?.subjectId);
    }

    public async loadMarketCompany(): Promise<void> {
        this.originatingMarketCompany = await subjectService.getSubject(this.sightingInternal.originatingMarketCompanyId);
    }

    public get sightingId(): number {
        return this.$route.params.sightingId ? parseInt(this.$route.params.sightingId, 10) : null;
    }

    public get sighting(): ISighting {
        if (this.sightingId) {
            return this.$store.getters["sighting/getEditingSighting"]();
        }
        return null;
    }

    public get previousValues(): Record<string, string> {
        if (this.sightingId) {
            return this.$store.state.sighting.editingSightingPreviousValues?.previousValues ?? {};
        }

        return {};
    }

    public get userSightingPreviousValues(): Record<string, string> {
        if (this.sightingId) {
            return this.$store.state.sighting.editingSightingPreviousValues?.userSightingPreviousValues ?? {};
        }

        return {};
    }

    public get userSighting(): IUserSighting {
        return this.sighting?.userSighting;
    }

    public get photosWithFullUrl(): string[] {
        if (this.azureBlobService) {
            return this.userSighting.photos.map(photo => this.azureBlobService.getDownloadUrl(photo));
        }
    }

    public get brands(): IBrand[] {
        return this.$store.getters["sighting/getBrands"]();
    }

    public get codings(): ICoding[] {
        return this.$store.getters["sighting/getCodings"]();
    }

    public get coding(): ICoding {
        return this.$store.getters["sighting/getCoding"](this.sighting?.codingId);
    }

    public get customerSubjects(): ISubject[] {
        return this.subjects?.filter(s => s.subjectTypeId === SubjectType.Customer);
    }

    public get importerSubjects(): ISubject[] {
        return this.subjects?.filter(s => s.subjectTypeId === SubjectType.Customer || s.subjectTypeId === SubjectType.Company)?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    }

    public get marketCompanies(): ISubject[] {
        return this.$store.state.sighting.marketCompanies || [];
    }

    public get affectedMarketCompany(): IMarketCompany {
        return this.$store.getters["sighting/getMarketCompany"](this.sighting?.affectedMarketCompanyId);
    }

    public get userRole(): Role {
        return this.$store.state.app.userRole;
    }

    public get selectedOrdersInfo(): any[] {
        return this.sightingInternal.ordersInfo.filter(oi => oi.selected);
    }

    public get brand(): IBrand {
        return this.$store.getters["sighting/getBrand"](this.brandId);
    }

    public get brandOwner(): ISubject {
        return this.$store.getters["sighting/getBrandCompany"](this.brand?.brandCompanyId);
    }

    public get marketCompanyCodes(): IMarketCompanyCode[] {
        return this.$store.state.sighting.marketCompanyCodes || [];
    }

    public get userSightingSourceName(): IUserSightingSource {
        return this.$store.getters["sighting/getUserSightingSource"](this.userSighting.userSightingSourceId)?.name ?? "";
    }

    public get brandCompanyRequestContacts(): IUserGraph[] {
        const contacts = [];
        for (const productionSite of this.brandCompanyRequestProductionSites) {
            for (const contact of productionSite.details.personnels?.filter(p => p.isBcRequest) ?? []) {
                contacts.push(contact);
            }
        }

        return this.extractDistinctUsers(contacts);
    }

    public get marketCompanyRequestContacts(): IUserGraph[] {
        const contacts = [];
        const markerCompanyContacts = this.originatingMarketCompany?.details?.personnels?.filter(p => p.isMcRequest) ?? [];
        for (const contact of markerCompanyContacts) {
            contacts.push(contact);
        }

        return this.extractDistinctUsers(contacts);
    }

    public get currency(): ICurrency {
        return this.$store.getters["sighting/getCurrency"](this.sightingInternal.userSighting.currencyId);
    }

    public get statusTabBrandCompany(): string {
        if (this.$v.brandId.$invalid ||
            this.$v.brandQualityId.$invalid ||
            this.$v.sightingInternal.size.$invalid ||
            this.$v.sightingInternal.alcoholVolume.$invalid ||
            this.$v.sightingInternal.lotNumber.$invalid ||
            this.$v.sightingInternal.lotDate.$invalid ||
            this.$v.sightingInternal.lotTime.$invalid ||
            this.$v.sightingInternal.backLabelPartNumber.$invalid ||
            this.$v.sightingInternal.caseCode.$invalid ||
            this.$v.sightingInternal.palletCodeSscc.$invalid) {
            return "error";
        }
        return "valid";
    }

    public get statusTabMarketCompany(): string {
        if (this.$v.sightingInternal.originatingMarketCompanyId.$invalid) {
            return "error";
        }
        return "valid";
    }

    public get statusTabCustomer(): string {
        if (this.$v.sightingInternal.customerSubjectId.$invalid) {
            return "error";
        }
        return "valid";
    }

    public get statusTabImporter(): string {
        if (this.$v.sightingInternal.importerSubjectId.$invalid) {
            return "error";
        }
        return "valid";
    }

    public get statusTabOutlet(): string {
        if (this.$v.sightingInternal.outletType.$invalid ||
            this.$v.sightingInternal.outletName.$invalid ||
            this.$v.sightingInternal.street.$invalid ||
            this.$v.sightingInternal.zipCode.$invalid ||
            this.$v.sightingInternal.townCity.$invalid ||
            this.$v.sightingInternal.countryState.$invalid) {
            return "error";
        }
        return "valid";
    }

    public get traceabilityScore(): number {
        let result: number = 0;

        if (this.statusTabBrandCompany === "valid") {
            result++;
        }
        if (this.statusTabMarketCompany === "valid") {
            result++;
        }
        if (this.statusTabCustomer === "valid") {
            result++;
        }
        if (this.statusTabImporter === "valid") {
            result++;
        }
        if (this.statusTabOutlet === "valid") {
            result++;
        }

        return result;
    }

    public get trackLevel(): string {
        if (this.sightingInternal.customerSubjectId) {
            return "Customer";
        }
        else if (this.sightingInternal.originatingMarketCompanyId &&
            this.selectedOrdersInfo.length > 0) {
            return "Market Company";
        }
        else {
            return "Unable to trace";
        }
    }

    public get isTraceTeam(): boolean {
        return this.$store.state.app.userRole >= Role.TraceTeam;
    }

    public get isBSTeam(): boolean {
        return this.$store.state.app.userRole >= Role.BSTeam;
    }

    public get marketCompanyCanBeRequested(): boolean {
        return this.isBSTeam &&
            this.sightingInternal.originatingMarketCompanyId &&
            this.sighting.analysisStatus >= AnalysisStatus.Review &&
            this.sighting.analysisStatus < AnalysisStatus.MarketCoRequest;
    }

    public get canBeCompleted(): boolean {
        return this.sightingInternal.analysisStatus < AnalysisStatus.Decoded;
    }

    public get canBeSaved(): boolean {
        return !(this.$v.sightingInternal.alcoholVolume.$invalid ||
            this.$v.sightingInternal.volumeImpact.$invalid ||
            this.$v.brandId.$invalid ||
            this.$v.brandQualityId.$invalid);
    }

    public get markets(): IMarket[] {
        return this.$store.state.sighting.markets ?? [];
    }

    public setUser(user: IUserGraph) {
        if (this.sightingInternal && user) {
            this.sightingInternal.user = {
                userId: 0,
                isActive: true,
                productionSiteIds: [],
                role: null,
                roleId: 0,
                email: user.email,
                name: user.displayName,
                uniqueId: user.uniqueId,
            };

            this.reporters = [{
                displayName: this.sightingInternal.user.name,
                email: this.sightingInternal.user.email,
                uniqueId: this.sightingInternal.user.uniqueId,
            }];
        }
    }

    public showImg(index: number): void {
        this.index = index;
        this.visible = true;
    }

    public handleHide(): void {
        this.visible = false;
    }

    public getFileUrl(url: string): string {
        if (this.azureBlobService) {
            return this.azureBlobService.getDownloadUrl(url);
        }

        return "";
    }

    public async onBrandChanged(brand: IBrand): Promise<void> {
        this.brandQualityId = null;
        if (brand.brandId) {
            this.brandId = brand.brandId;
            this.sighting.brand = brand;
            await this.$store.dispatch("sighting/fetchBrandQualities", brand.brandId);
            this.brandQualities = this.$store.getters["sighting/getBrand"](brand.brandId).qualities;
            this.$store.dispatch("sighting/fetchMarketCompanyCodes", this.brandOwner.subjectId);
            this.loadProductionSite();
            this.filteredBrandQualities = this.brandQualities;
            this.filteredMarketCompanies = this.marketCompanies;
            this.sighting.quality = null;
        }
    }

    public onSearchBrandChanged(query: string) {
        if (this.searchBrandTimeout) {
            clearTimeout(this.searchBrandTimeout);
        }
        this.searchBrandTimeout = setTimeout(async () => {
            this.searchBrand(query);
        }, 400);
    }

    public searchBrand(query: string) {
        this.filteredBrands = this.brands.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));
    }

    public async onBrandQualityChanged(brandQuality: IBrandQuality): Promise<void> {
        if (brandQuality.brandId) {
            this.brandQualityId = brandQuality.brandQualityId;
            this.sighting.quality = brandQuality;
        }
    }

    public onSearchBrandQualityChanged(query: string) {
        if (this.searchBrandQualityTimeout) {
            clearTimeout(this.searchBrandQualityTimeout);
        }
        this.searchBrandQualityTimeout = setTimeout(async () => {
            this.searchBrandQuality(query);
        }, 400);
    }

    public searchBrandQuality(query: string) {
        this.filteredBrandQualities = this.brandQualities.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));
    }

    public async onMarketChanged(company: ISubject): Promise<void> {
        if (company.subjectId) {
            this.sightingInternal.originatingMarketCompanyId = company.subjectId;
            this.loadMarketCompany();
        }
    }

    public async onMarketRemoved(): Promise<void> {
        this.sightingInternal.originatingMarketCompanyId = null;
        this.originatingMarketCompany = null;
    }

    public onSearchMarketChanged(query: string) {
        if (this.searchMarketCompanyTimeout) {
            clearTimeout(this.searchMarketCompanyTimeout);
        }
        this.searchMarketCompanyTimeout = setTimeout(async () => {
            this.searchMarketCompany(query);
        }, 400);
    }

    public searchMarketCompany(query: string) {
        this.filteredMarketCompanies = this.marketCompanies.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));
    }

    public async onCustomerChanged(customer: ISubject): Promise<void> {
        if (customer.subjectId) {
            this.customer = customer;
            this.sightingInternal.customerSubjectId = customer.subjectId;
        }
    }

    public async onImporterChanged(importer: ISubject): Promise<void> {
        if (importer.subjectId) {
            this.importer = importer;
            this.sightingInternal.importerSubjectId = importer.subjectId;
        }
    }

    public onSearchCustomerChanged(query: string) {
        if (this.searchCustomerTimeout) {
            clearTimeout(this.searchCustomerTimeout);
        }
        this.searchCustomerTimeout = setTimeout(async () => {
            this.searchCustomer(query);
        }, 400);
    }

    public onSearchImporterChanged(query: string) {
        if (this.searchCustomerTimeout) {
            clearTimeout(this.searchCustomerTimeout);
        }
        this.searchCustomerTimeout = setTimeout(async () => {
            this.searchImporter(query);
        }, 400);
    }

    public onCustomerRemoved() {
        this.customer = null;
        this.sightingInternal.customerSubjectId = null;
    }

    public onImporterRemoved() {
        this.importer = null;
        this.sightingInternal.importerSubjectId = null;
    }

    public searchCustomer(query: string) {
        this.filteredCustomers = this.customerSubjects.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));
    }

    public searchImporter(query: string) {
        this.filteredImporters = this.importerSubjects.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));
    }

    public addOrderInfo(): void {
        this.sightingInternal.ordersInfo.push({
            selected: false,
            lotNumber: null,
            palletDate: null,
            palletTime: null,
            sscc: null,
            orderNumber: null,
            soldToNo: null,
            soldToName: null,
            shipToNo: null,
            shipToName: null,
            poNumber: null,
            sku: null,
            description: null,
            transQty: null,
            shipmentDate: null,
            bottlesPerCase: null,
            totalCases: null,
            casesPerPallet: null,
    });
    }

    public addCustomerInfo(): void {
        this.sightingInternal.customersInfo.push({
            selected: false,
            customer: null,
            orderNumber: null,
            shipmentDate: null,
            cases: null,
            poNumber: null,
            sscc: null,
        });
    }

    public deleteCustomerInfo(index: number): void {
        this.sightingInternal.customersInfo.splice(index, 1);
    }

    public deleteOrderInfo(index: number): void {
        this.sightingInternal.ordersInfo.splice(index, 1);
    }

    public openModal(modalName: string, disabled = false): void {
        if (!disabled) {
            this.$modal.show(modalName);
        }
    }

    public closeModal(modalName: string): void {
        this.$modal.hide(modalName);
    }

    public async addReporterRequest(): Promise<void> {
        if (!this.$v.reporterRequestLotNumber.$invalid &&
            !this.$v.reporterRequestBottlingDate.$invalid &&
            !this.$v.reporterRequestBottlingTime.$invalid &&
            !this.$v.reporterRequestBackLabelPhotograph.$invalid &&
            !this.$v.reporterRequestProductQuantityPhotograph.$invalid &&
            !this.$v.sightingInternal.volumeImpact.$invalid) {
            await this.saveSighting();
        }

        this.$store.dispatch("sighting/addReporterRequest", {
            sightingId: this.sighting.sightingId,
            lotNumber: this.reporterRequestLotNumber,
            bottlingDate: this.reporterRequestBottlingDate,
            bottlingTime: this.reporterRequestBottlingTime,
            backLabelPhotograph: this.reporterRequestBackLabelPhotograph,
            productQuantityPhotograph: this.reporterRequestProductQuantityPhotograph,
            sendByEmail: this.reporterRequestSendByEmail,
            comments: this.reporterRequestComments,
        });
        this.$router.push("/sightings");
    }

    public async addBrandCompanyRequest(): Promise<void> {
        if (!this.$v.sightingInternal.volumeImpact.$invalid) {
            await this.saveSighting();
        }

        this.$store.dispatch("sighting/addBrandCompanyRequest", {
            sightingId: this.sighting.sightingId,
            productionSiteIds: JSON.stringify(this.brandCompanyRequestProductionSites.map(productionSite => {
                return { productionSiteId: productionSite.subjectId };
            })),
            sendByEmail: this.brandCompanyRequestSendByEmail,
        });
        this.$router.push("/sightings");
    }

    public onOrderInfoPaste(event, index: number): void {
        const text: string = event.clipboardData.getData("Text");
        const lines = text.split("\n").filter(x => x);
        let lineIndex = 0;

        for (let line of lines) {
            if (line.includes("\t")) {
                if (this.sightingInternal.ordersInfo.length <= index + lineIndex) {
                    this.addOrderInfo();
                }

                // Remove Carriage Returns if on windows or an old mac
                if (line.includes("\r")) {
                    line = line.replace("\r", "");
                }

                const values = line.split("\t");
                const currentValue = this.sightingInternal.ordersInfo[index + lineIndex];

                [currentValue.lotNumber,
                currentValue.palletDate,
                currentValue.palletTime,
                currentValue.sscc,
                currentValue.orderNumber,
                currentValue.soldToNo,
                currentValue.soldToName,
                currentValue.shipToNo,
                currentValue.shipToName,
                currentValue.poNumber,
                currentValue.sku,
                currentValue.description,
                currentValue.transQty] = values;
            }
            else {
                this.sightingInternal.ordersInfo[index + lineIndex].orderNumber = line;
            }
            lineIndex++;
        }
    }

    public onCustomerInfoPaste(event, index: number): void {
        const text: string = event.clipboardData.getData("Text");
        const lines = text.split("\n").filter(x => x);
        let lineIndex = 0;

        for (let line of lines) {
            if (line.includes("\t")) {
                if (this.sightingInternal.customersInfo.length <= index + lineIndex) {
                    this.addCustomerInfo();
                }

                // Remove Carriage Returns if on windows or an old mac
                if (line.includes("\r")) {
                    line = line.replace("\r", "");
                }

                const values = line.split("\t");
                const currentValue = this.sightingInternal.customersInfo[index + lineIndex];

                [currentValue.customer,
                currentValue.orderNumber,
                currentValue.shipmentDate,
                currentValue.cases,
                currentValue.poNumber,
                currentValue.sscc] = values;
            }
            else {
                this.sightingInternal.customersInfo[index + lineIndex].orderNumber = line;
            }
            lineIndex++;
        }
    }

    public onBrandCompanyRequestProductionSiteChanged(productionSite: ISubject) {
        this.brandCompanyRequestProductionSites.splice(0);
        this.brandCompanyRequestProductionSites.push(productionSite);
    }

    public async addMarketCompanyRequest(): Promise<void> {
        if (!this.$v.sightingInternal.volumeImpact.$invalid) {
            await this.saveSighting();
        }

        this.$store.dispatch("sighting/addMarketCompanyRequest", {
            sightingId: this.sighting.sightingId,
            sendByEmail: this.marketCompanyRequestSendByEmail,
        });
        this.$router.push("/sightings");
    }

    public async saveSighting(): Promise<void> {
        if (this.canBeSaved) {
            await this.$store.dispatch("sighting/updateSighting", {
                sightingId: this.sightingId,

                analysisStatus: this.sightingInternal.analysisStatus,
                MarketId: this.sightingInternal.market.marketId,
                brandId: this.brandId,
                brandQualityId: this.brandQualityId,
                size: this.sightingInternal.size,
                alcoholVolume: this.sightingInternal.alcoholVolume,
                lotNumber: this.sightingInternal.lotNumber,
                lotDate: this.sightingInternal.lotDate,
                lotTime: this.sightingInternal.lotTime,
                backLabelPartNumber: this.sightingInternal.backLabelPartNumber,
                caseCode: this.sightingInternal.caseCode,
                uniqueBottleId: this.sightingInternal.uniqueBottleId,
                productionSite: this.sightingInternal.productionSite,
                palletCodeSscc: this.sightingInternal.palletCodeSscc,

                originatingMarketCompanyId: this.sightingInternal.originatingMarketCompanyId,

                customerSubjectId: this.sightingInternal.customerSubjectId,
                importerSubjectId: this.sightingInternal.importerSubjectId,

                outletName: this.sightingInternal.outletName,
                streetNumber: this.sightingInternal.streetNumber,
                street: this.sightingInternal.street,
                zipCode: this.sightingInternal.zipCode,
                townCity: this.sightingInternal.townCity,
                province: this.sightingInternal.province,
                countryState: this.sightingInternal.countryState,
                outletType: this.sightingInternal.outletType,
                latitude: this.sightingInternal.latitude,
                longitude: this.sightingInternal.longitude,

                volumeImpact: this.sightingInternal.volumeImpact,
                notes: this.sightingInternal.notes,
                remarks: this.sightingInternal.remarks,
                ordersInfo: JSON.stringify(this.sightingInternal.ordersInfo),
                codingId: this.sightingInternal.codingId,
                filesInfo: this.sightingInternal.filesInfo,
                customersInfo: JSON.stringify(this.sightingInternal.customersInfo),
                user: this.sightingInternal.user,
            });

            const promises: Array<Promise<any>> = [];
            promises.push(this.$store.dispatch("sighting/fetchSightingById", this.sightingId));
            promises.push(this.$store.dispatch("sighting/fetchSightingPreviousValuesById", this.sightingId));
            await Promise.all(promises);
        }
    }

    public validateReporterRequestNextStep(): void {
        this.$v.reporterRequestLotNumber.$touch();
        this.$v.reporterRequestBottlingDate.$touch();
        this.$v.reporterRequestBottlingTime.$touch();
        this.$v.reporterRequestBackLabelPhotograph.$touch();
        this.$v.reporterRequestProductQuantityPhotograph.$touch();

        if (!(this.$v.reporterRequestLotNumber.$invalid ||
            this.$v.reporterRequestBottlingDate.$invalid ||
            this.$v.reporterRequestBottlingTime.$invalid ||
            this.$v.reporterRequestBackLabelPhotograph.$invalid ||
            this.$v.reporterRequestProductQuantityPhotograph.$invalid)) {
            this.reporterRequestStep = 2;
        }
    }

    public resetReporterRequest(): void {
        this.reporterRequestStep = 1;
        this.reporterRequestLotNumber = false;
        this.reporterRequestBottlingDate = false;
        this.reporterRequestBottlingTime = false;
        this.reporterRequestBackLabelPhotograph = false;
        this.reporterRequestProductQuantityPhotograph = false;
    }

    public resetBrandCompanyRequest(): void {
        this.brandCompanyRequestStep = 1;
        this.brandCompanyRequestProductionSites = [];
        this.selected = null;
    }

    public async addPicturesToSighting(): Promise<void> {

        this.$store.dispatch("sighting/updateUserSighting", {
            userSightingId: this.userSighting.userSightingId,
            recordStatus: this.userSighting.recordStatus,
            photos: this.userSighting.photos,
            dateSighted: this.userSighting.dateSighted,
            outletType: this.userSighting.outletType,
            marketId: this.userSighting.marketId,
            outletName: this.userSighting.outletName,
            streetNumber: this.userSighting.streetNumber,
            street: this.userSighting.street,
            zipCode: this.userSighting.zipCode,
            townCity: this.userSighting.townCity,
            province: this.userSighting.province,
            countryState: this.userSighting.countryState,
            latitude: this.userSighting.latitude,
            longitude: this.userSighting.longitude,
            bookmarked: this.userSighting.bookmarked,

            brandId: this.userSighting.brand.brandId,
            brandQualityId: this.userSighting.quality.brandQualityId,
            size: this.userSighting.size,
            alcoholVolume: this.userSighting.alcoholVolume,
            importerSubjectId: this.userSighting.importerSubjectId,
            lotNumber: this.userSighting.lotNumber,
            decoded: this.userSighting.decoded,
            lotDate: this.userSighting.lotDate,
            lotTime: this.userSighting.lotTime,
            backLabelPartNumber: this.userSighting.backLabelPartNumber,
            caseCode: this.userSighting.caseCode,
            quantitySighted: this.userSighting.quantitySighted,
            estimatedQuantity: this.userSighting.estimatedQuantity,
            rspPrice: this.userSighting.rspPrice,
            palletCodeSscc: this.userSighting.palletCodeSscc,
            notes: this.userSighting.notes,
            currencyId: this.userSighting.currencyId,
        });

        this.closeModal("addPicturesModal");
    }

    public onDragEnter(event: DragEvent): void {
        this.dragging = true;
    }

    public onDragLeave(event: DragEvent): void {
        this.dragging = false;
    }

    public async handleFiles(event): Promise<void> {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.target.files.length; i++) {
            await this.uploadFile(event.target.files[i]);
        }
    }

    public async onDropFiles(event: DragEvent): Promise<void> {
        this.dragging = false;
        this.loading = true;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
            await this.uploadFile(event.dataTransfer.files[i]);
        }
        this.loading = false;
    }

    public async uploadFile(file: File): Promise<void> {
        const metadata = {
            name: file.name,
        };
        if (file.type.startsWith("image")) {
            this.userSighting.photos.push(await this.azureBlobService.uploadFile(file, "usersighting", metadata));
        }
    }

    public updateOpenBrandTab() {
        this.showOpenBrandTabOverflow = !this.showOpenBrandTabOverflow;
    }

    private extractDistinctUsers(personnels: ISubjectPersonnel[]) {
        const jsonUsers = new Set(personnels.map<string>(c => JSON.stringify(c.user)));
        return Array
            .from(jsonUsers)
            .map(str => JSON.parse(str))
            .sort((a, b) => a.email.localeCompare(b.email));
    }
}

interface IAnalysisStatus {
    analysisStatusId: number;
    label: string;
}
