import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    name: "number-sighting-advanced-search",
})

export default class TextSightingAdvancedSearch extends Vue {
    @Prop({ required: true, type: String })
    public label: string;

    @Prop({ required: true })
    public value: number;

    @Prop()
    public step: string;

    @Prop({ required: true })
    public operator: string;

    public readonly operators = [
        { value: "eq", label: "=" },
        { value: "lt", label: "<" },
        { value: "le", label: "≤" },
        { value: "gt", label: ">" },
        { value: "ge", label: "≥" },
    ];

    public internalOperator: any;
    public internalValue: number;

    constructor() {
        super();

        this.internalOperator = this.operators.find(o => o.value === this.operator) ?? this.operator[0];
        this.internalValue = this.value;
    }

    public onInput(): void {
        this.$emit("update:value", this.internalValue);
    }

    public onChangeOperator(): void {
        this.$emit("update:operator", this.internalOperator.value);
    }

    public onDelete(): void {
        this.$emit("delete");
    }
}
