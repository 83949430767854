import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ISighting, AnalysisStatus, IReporterRequest, IPaginatedAdvancedSearchForm } from "@/models";
import { AzureBlobService, cookieService } from "@/services";
import { Loader, SortableColumnHeader, SightingAdvancedSearch } from "@/components";
import Paginate from "vuejs-paginate";

@Component({
    name: "sighting-dashboard",
    components: {
        Loader,
        Paginate,
        SortableColumnHeader,
        SightingAdvancedSearch,
    },
})
export default class SightingDashboard extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public azureBlobService: AzureBlobService;
    public selectedStatus: AnalysisStatus;
    public key: string;
    public advancedSearchMode: boolean;
    public advancedSearchParms: IPaginatedAdvancedSearchForm;
    public includeCompleted: boolean;
    public page: number;
    public searchMode: boolean;
    public sortDirection: string;
    public sortBy: string;

    private pageSize = 20;

    public constructor() {
        super();
        this.loadPromises = [];
        this.isLoaded = false;
        this.selectedStatus = AnalysisStatus.Research;
        this.azureBlobService = null;
        this.advancedSearchMode = cookieService.getCookie("advancedSearchMode") === "true";
        try {
            this.advancedSearchParms = JSON.parse(cookieService.getCookie("advancedSearchParms"));
        } catch {
            this.advancedSearchParms = {};
        }
        this.key = cookieService.getCookie("searchKey") ?? "";
        this.includeCompleted = cookieService.getCookie("includeCompleted") === "true" ?? false;
        this.page = 1;
        this.searchMode = this.key ? true : false;
        this.sortDirection = null;
        this.sortBy = null;

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }
    }

    public async created(): Promise<void> {
        if (!this.$store.state.sighting.sightings) {
            this.loadPromises.push(this.getSightings(this.page));
        }
        else {
            this.getSightings(this.page);
        }

        if (!this.$store.state.sighting.sightings) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchReporterRequests"));
        }
        else {
            this.$store.dispatch("sighting/fetchReporterRequests");
        }

        if (!this.$store.state.sighting.markets) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchMarkets"));
        }

        await Promise.all(this.loadPromises);
        this.isLoaded = true;
        this.azureBlobService = new AzureBlobService(
            this.$store.state.app.blobSettings.containerName,
            this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
    }

    public async getSightings(page: number): Promise<void> {
        this.isLoaded = false;
        if (this.advancedSearchMode) {
            const params = {
                includeCompleted: this.searchMode && this.includeCompleted,
                pageSize: this.pageSize,
                pageNumber: page,
                sortDirection: this.sortDirection,
                sortBy: this.sortBy,
                ...this.advancedSearchParms,
            };

            await this.$store.dispatch("sighting/fetchAdvancedSightings", params);
        }
        else {
            const mode = this.key ? true : false;
            const params = {
                status: mode ? null : this.selectedStatus,
                keySearch: this.key,
                includeCompleted: this.includeCompleted,
                pageSize: this.pageSize,
                pageNumber: page,
                sortDirection: this.sortDirection,
                sortBy: this.sortBy,
            };

            await this.$store.dispatch("sighting/fetchSightingsWithoutFrontFilter", params);
            this.searchMode = mode;
        }

        this.page = page;
        this.isLoaded = true;
    }

    public async keyChange(): Promise<void> {
        this.advancedSearchMode = false;
        await this.getSightings(1);
        cookieService.setCookie("searchKey", this.key, 604800);
        cookieService.setCookie("includeCompleted", this.includeCompleted.toString(), 604800);
        cookieService.setCookie("advancedSearchMode", this.advancedSearchMode.toString(), 604800);
    }

    public async onIncludeCompletedChange(): Promise<void> {
        await this.getSightings(this.page);
        cookieService.setCookie("includeCompleted", this.includeCompleted.toString(), 604800);
    }

    public async onClickInputSearch(): Promise<void> {
        await this.keyChange();
    }

    public async onClearInputSearch(): Promise<void> {
        this.key = "";
        await this.keyChange();
    }

    public get sightings(): ISighting[] {
        return this.$store.state.sighting.sightings ?? [];
    }

    public get totalPages(): number {
        const total = this.$store.getters["sighting/getSightingsTotal"]();
        return Math.ceil(total / this.pageSize);
    }

    public getAnalysisStatuslabel(sighting: ISighting): string {
        return AnalysisStatus[sighting.analysisStatus];
    }

    public getPhotosWithFullUrl(photo): string {
        if (this.azureBlobService) {
            return this.azureBlobService.getDownloadUrl(photo);
        }
    }

    public getSightingReporterRequest(sighting: ISighting): IReporterRequest {
        return this.$store.getters["sighting/getReporterRequestsBySightingId"](sighting.sightingId);
    }

    public async navigateToPage(page: number): Promise<void> {
        await this.getSightings(page);
    }

    public async setStatus(statusId: number): Promise<void> {
        if (this.selectedStatus !== statusId) {
            this.selectedStatus = statusId;

            this.advancedSearchMode = false;
            await this.getSightings(1);
            cookieService.setCookie("searchKey", this.key, 604800);
            cookieService.setCookie("includeCompleted", this.includeCompleted.toString(), 604800);
            cookieService.setCookie("advancedSearchMode", this.advancedSearchMode.toString(), 604800);
        }
    }

    public async setSort(sortBy: string): Promise<void> {
        this.isLoaded = false;
        if (sortBy === this.sortBy) {
            this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
        }
        else {
            this.sortDirection = "ASC";
        }
        this.sortBy = sortBy;

        await this.getSightings(this.page);
    }

    public async onToggleAdvancedMode(): Promise<void> {
        this.advancedSearchMode = !this.advancedSearchMode;
        await this.getSightings(1);
        cookieService.setCookie("advancedSearchMode", this.advancedSearchMode.toString(), 604800);

        if (this.advancedSearchMode) {
            cookieService.setCookie("advancedSearchParms", JSON.stringify(this.advancedSearchParms), 604800);
        }
    }

    public async onAdvancedSearchFilter(value: IPaginatedAdvancedSearchForm): Promise<void> {
        this.advancedSearchMode = true;
        this.advancedSearchParms = value;
        await this.getSightings(1);
        cookieService.setCookie("advancedSearchMode", this.advancedSearchMode.toString(), 604800);
        cookieService.setCookie("advancedSearchParms", JSON.stringify(this.advancedSearchParms), 604800);
    }
}
