import { Role } from "@/models";

/**
 * Display name of the role instead of number
 * @param value
 */
export const roleFilter = (value: Role) => {
    switch (value) {
        case Role.BrandCompanyContact:
            return "Brand Company Contact";
        case Role.MarketCompanyContact:
            return "Market Company Contact";
        case Role.TraceTeam:
            return "Trace Team";
        case Role.BSTeam:
            return "BS Team";
        default:
            return Role[value];
    }
};
