import { authService } from "./AuthService";

export default class ElintApiService {
    protected async getHeaders(): Promise<HeadersInit> {
        return {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await authService.getAccessToken()}`,
        };
    }
}
