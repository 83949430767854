import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    name: "text-sighting-advanced-search",
    components: {
    },
})

export default class TextSightingAdvancedSearch extends Vue {
    @Prop({ required: true, type: String })
    public label: string;

    @Prop({ required: true })
    public value: string;

    public onInput(value: string): void {
        this.$emit("update:value", value?.trim() ?? null);
    }

    public onDelete(): void {
        this.$emit("delete");
    }
}
