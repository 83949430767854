import Vue from "vue";
import { Component, Model, Prop, Watch } from "vue-property-decorator";

import { authService } from "@/services";

import Multiselect from "vue-multiselect";

import { IUserGraph } from "@/models";

@Component({
    name: "people-picker",
    components: {
        Multiselect,
    },
})
export default class PeoplePicker extends Vue {

    @Model("input", { required: true })
    public selectedUsers: IUserGraph[];

    @Prop()
    public multiple: boolean;

    @Prop({ default: true })
    public displayEmail: boolean;

    public internalMultiple: boolean;

    public adUsers: IUserGraph[] = [];

    public internalSelectedUsers: IUserGraph[] = [];

    public isLoading: boolean = false;

    @Watch("selectedUsers")
    public onStatusFromQueryChanged(): void {
        this.internalSelectedUsers = this.selectedUsers.map(x => {
            return { ...x };
        });
    }

    public async searchADUsers(search) {
        if (search.length >= 3) {

            this.isLoading = true;

            const apiUrl: string = `/api/graph/users/${ search }`;

            const response = await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${await authService.getAccessToken()}`,
                },
            });

            const users: IUserGraph[] = await response.json();
            this.adUsers = users.filter(userGraph => userGraph.email);

            this.isLoading = false;
        } else {
            this.adUsers = [];
            this.isLoading = false;
        }
    }

    public onSelectUser(event) {
        this.adUsers = [];
        this.$emit("input", event);
    }

    public customLabel({ displayName, email }) {
        if (email && this.displayEmail) {
            return `${displayName} (${email})`;
        }
        else if (email) {
            return displayName;
        } else {
            return "Search a user";
        }

    }

    // Computed

    public get isMultiple(): boolean {
        return typeof this.multiple === "undefined" ? false : this.multiple;
    }
}
