import Vue from "vue";
import { Component } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import { Loader } from "@/components";
import {
    ISubjectType,
    ISearchSubject,
} from "@/models";
import { subjectService } from "@/services";
import { subjectsValidations } from "./SubjectsValidations";

@Component({
    name: "subjects",
    components: {
        Loader,
        Multiselect,
    },
    validations: subjectsValidations,
})
export default class Subjects extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;

    public subjectsLoading: boolean;
    public searchSubjects: ISearchSubject[];
    public searchSubjectsTimeout: any;
    public searchSubject: ISearchSubject;

    public subject: ICreateSubject;

    constructor() {
        super();

        this.loadPromises = [];
        this.isLoaded = false;

        this.subjectsLoading = false;
        this.searchSubjects = [];
        this.searchSubjectsTimeout = null;
        this.searchSubject = null;

        this.subject = {
            subjectName: null,
            subjectTypeId: null,
        };

        if (!this.$store.state.sighting.subjectTypes) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchSubjectTypes"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);

        this.isLoaded = true;
    }

    public get subjectTypes(): ISubjectType[] {
        return this.$store.state.sighting.subjectTypes ?? [];
    }

    public onSearchSubjectChanged(query) {
        if (this.searchSubjectsTimeout) {
            clearTimeout(this.searchSubjectsTimeout);
        }
        this.searchSubjectsTimeout = setTimeout(async () => {
            await this.filterSubjects(query);
        }, 400);
    }

    public async filterSubjects(query: string): Promise<void> {
        this.subjectsLoading = true;

        if (!this.searchSubject) {
            this.searchSubjects = await subjectService.searchSubjects(query);
            this.subjectsLoading = false;
        }

        this.subjectsLoading = false;
    }

    public async onSelectSubject(searchSubject: ISearchSubject): Promise<void> {
        this.$router.push({
            path: "/subjects/" + searchSubject.subjectId,
        });
    }

    public async addSubject(): Promise<void> {
        this.$v.$touch();

        if (this.$v.$error) {
            return;
        }

        const subjectId = await this.$store.dispatch("sighting/addSubject", this.subject);

        if (subjectId) {
            this.$router.push({
                path: "/subjects/" + subjectId,
            });
        }
    }
}

export interface ICreateSubject {
    /*brandCompanyId: number;
    marketCompanyId: number;*/
    subjectTypeId: number;
    subjectName: string;
}
