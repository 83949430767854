import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as icons from "@/components/shared/icons";
import PeoplePicker from "@/components/shared/peoplePicker/PeoplePicker.vue";

import {
    IUser,
    IUserGraph,
    Role,
} from "@/models";

@Component({
    name: "users",
    components: {
        "people-picker": PeoplePicker,
        "add-icon": icons.AddIcon,
    },
})
export default class Users extends Vue {

    public readonly addUserModal: string = "addUserModal";

    public selectedUsers: IUserGraph[];
    public search: string;
    public selectedUser: IUser;
    public editIndex: number;
    public hideDisabledUsers: boolean;

    constructor() {
        super();

        if (this.users.length === 0) {
            this.$store.dispatch("app/getUsers");
        }

        this.selectedUsers = [];
        this.search = "";
        this.selectedUser = null;
        this.editIndex = null;
        this.hideDisabledUsers = false;
    }

    // Computed

    public get users(): IUser[] {
        if (this.hideDisabledUsers) {
            return this.$store.getters["app/getUsers"]()
                .filter(x => x.isActive &&
                    (x.name.includes(this.search) ||
                        x.email.includes(this.search) ||
                        x.uniqueId.includes(this.search)));
        }
        else {
            return this.$store.getters["app/getUsers"]()
                .filter(x => x.name.includes(this.search) ||
                    x.email.includes(this.search) ||
                    x.uniqueId.includes(this.search));
        }
    }

    public get roles(): any[] {
        return [
            {
                key: "None",
                value: Role.None,
            },
            {
                key: "User",
                value: Role.User,
            },
            {
                key: "Brand Company Contact",
                value: Role.BrandCompanyContact,
            },
            {
                key: "Market Company Contact",
                value: Role.MarketCompanyContact,
            },
            {
                key: "Trace Team",
                value: Role.TraceTeam,
            },
            {
                key: "BS Team",
                value: Role.BSTeam,
            },
            {
                key: "Administrator",
                value: Role.Administrator,
            },
        ];
    }

    // methods

    public openModal(): void {
        this.selectedUsers = [];
        this.$modal.show(this.addUserModal);
    }

    public async addUsers(): Promise<void> {
        this.$modal.hide(this.addUserModal);

        if (this.selectedUsers.length > 0) {
            await this.$store.dispatch("app/addUsers", this.selectedUsers);
        }
    }

    public setEditUser(index: number): void {
        if (index !== this.editIndex) {
            this.selectedUser = { ...this.users[index] };
            this.editIndex = index;
        }
    }

    public closeModal(modalName: string): void {
        this.$modal.hide(modalName);
    }

    public async toggleUser(userId: number): Promise<void> {
        await this.$store.dispatch("app/toggleUser", userId);
        this.selectedUser = null;
        this.editIndex = null;
    }

    public async editUser(): Promise<void> {
        await this.$store.dispatch("app/editUser", this.selectedUser);
        this.selectedUser = null;
        this.editIndex = null;
    }
}
