import Vue from "vue";
import { Component } from "vue-property-decorator";
import { usersValidations } from "./UserValidations";
import Multiselect from "vue-multiselect";

import {
    IUser,
    IMarket,
    ICurrency,
} from "@/models";

@Component({
    name: "user",
    components: {
        Multiselect,
    },
    validations: usersValidations,
})
export default class User extends Vue {
    public loadPromises: Array<Promise<any>>;
    public marketId: number;
    public currencyId: number;
    public termsAndConditionsAccepted: boolean;
    public photoBase64: string;

    public searchMarketTimeout: any;
    public selectedMarket: IMarket;
    public filteredMarkets: IMarket[];

    public searchCurrencyTimeout: any;
    public selectedCurrency: ICurrency;
    public filteredCurrencies: ICurrency[];

    public constructor() {
        super();

        this.loadPromises = [];

        this.marketId = null;
        this.currencyId = null;
        this.termsAndConditionsAccepted = null;
        this.photoBase64 = null;

        this.searchCurrencyTimeout = null;
        this.selectedCurrency = null;
        this.filteredCurrencies = [];

        this.searchMarketTimeout = null;
        this.selectedMarket = null;
        this.filteredMarkets = [];

        if (!this.$store.state.sighting.markets) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchMarkets"));
        }

        if (!this.$store.state.sighting.currencies) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchCurrencies"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.marketId = this.$store.state.app?.userProfile.marketId;
        this.currencyId = this.$store.state.app?.userProfile.currencyId;
        this.termsAndConditionsAccepted = this.$store.state.app?.userProfile.termsAndConditionsAccepted;
        this.photoBase64 = this.$store.state.app?.userProfile.photoBase64;
        this.filteredCurrencies = this.currencies;
        this.selectedCurrency = this.currencies.filter(x => x.currencyId === this.currencyId)[0];
        this.filteredMarkets = this.markets;
        this.selectedMarket = this.markets.filter(x => x.marketId === this.marketId)[0];
    }

    public get userAccount(): IUser {
        return this.$store.state.app.userAccount;
    }

    public get markets(): IMarket[] {
        return this.$store.state.sighting.markets ?? [];
    }

    public get currencies(): ICurrency[] {
        return this.$store.state.sighting.currencies ?? [];
    }

    public get userPhotoBase64(): string {
        const imgLink = require("@/assets/default-image.jpg");
        return this.photoBase64 ?
            "data:image/png;base64, " + this.photoBase64 :
            imgLink;
    }

    public async updateUserProfile(): Promise<void> {
        if (this.validateBeforeSubmit()) {
            // Wait profile update to pass guard.
            await this.$store.dispatch("app/updateUserProfile",
                {
                    marketId: this.marketId,
                    currencyId: this.currencyId,
                    termsAndConditionsAccepted: this.termsAndConditionsAccepted,
                });

            this.$router.push("/");
        }
    }

    public async onMarketChanged(market: IMarket): Promise<void> {
        if (market.marketId) {
            this.marketId = market.marketId;
        }
    }

    public onSearchMarketChanged(query: string) {
        if (this.searchMarketTimeout) {
            clearTimeout(this.searchMarketTimeout);
        }
        this.searchMarketTimeout = setTimeout(async () => {
            this.searchMarket(query);
        }, 400);
    }

    public searchMarket(query: string) {
        this.filteredMarkets = this.markets.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));
    }

    public async onCurrencyChanged(currency: ICurrency): Promise<void> {
        if (currency.currencyId) {
            this.currencyId = currency.currencyId;
        }
    }

    public onSearchCurrencyChanged(query: string) {
        if (this.searchCurrencyTimeout) {
            clearTimeout(this.searchCurrencyTimeout);
        }
        this.searchCurrencyTimeout = setTimeout(async () => {
            this.searchCurrency(query);
        }, 400);
    }

    public searchCurrency(query: string) {
        this.filteredCurrencies = this.currencies.filter(b => b.name.toLowerCase().includes(query.toLowerCase()));
    }

    private validateBeforeSubmit(): boolean {
        this.$v.$touch();
        return !this.$v.termsAndConditionsAccepted.$invalid;
    }
}
