import * as Msal from "msal";
import { authService } from "@/services";
import { Role, IUser, IUserProfile, IPendingUser } from "@/models";

interface IAppState {
    config: any;
    blobSettings: any;
    userAccount: Msal.Account;
    userRole: Role;
    userProfile: IUserProfile;
    googleMapsConfig: any;
    loaded: boolean;
    itemsMenu: any;
    users: IUser[];
    pendingUsers: IPendingUser[];
}

const initialState: IAppState = {
    config: null,
    blobSettings: null,
    userAccount: authService.getAccount(),
    userRole: null,
    userProfile: null,
    googleMapsConfig: null,
    loaded: false,
    itemsMenu: [
        {
            title: "Dashboard",
            href: "/",
            icon: "flaticon-home",
            requiredRole: 1,
        },
        {
            title: "Sightings List",
            href: "/user-sightings",
            icon: "flaticon-database-1",
            requiredRole: 2,
        },
        {
            title: "New sighting",
            href: "/user-sightings/new",
            icon: "flaticon-add",
            requiredRole: 1,
        },
        {
            title: "Requests",
            href: "/sighting-requests",
            icon: "flaticon-folder-7",
            requiredRole: 4,
        },
        {
            title: "Subjects",
            href: "/subjects",
            icon: "flaticon-megaphone",
            requiredRole: 4,
        },
        {
            title: "Brand company requests",
            href: "/brand-company-requests",
            icon: "flaticon-worldwide",
            requiredRole: 6,
        },
        {
            title: "Market company requests",
            href: "/market-company-requests",
            icon: "flaticon-flag-2",
            requiredRole: 6,
        },
        {
            title: "Settings",
            href: "",
            icon: "flaticon-settings-8",
            child: [
                {
                    title: "Brands",
                    href: "/manage/brands",
                    icon: "",
                    requiredRole: 6,
                },
                {
                    title: "Users",
                    href: "/manage/users",
                    icon: "",
                    requiredRole: 6,
                },
                {
                    title: "Sightings Deletion",
                    href: "/manage/sightings",
                    icon: "",
                    requiredRole: Role.Administrator,
                },
                {
                    title: "Pending Users",
                    href: "/manage/pendingusers",
                    icon: "",
                    requiredRole: Role.Administrator,
                },
            ],
            requiredRole: 6,
        },
    ],
    users: [],
    pendingUsers: [],
};

export { IAppState, initialState };
