import Vue from "vue";
export class NotificationService {

    public static success(message: string) {
        Vue.toasted.success(message, {
            theme: "bubble",
            duration: 8000,
            position: "top-right",
            fitToScreen: true,
            closeOnSwipe: true,
        });
    }

    public static error(message: string) {
        Vue.toasted.error(message, {
            theme: "bubble",
            duration: 8000,
            position: "top-right",
            fitToScreen: true,
            closeOnSwipe: true,
        });
    }
}
