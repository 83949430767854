import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IUserSighting, IUserSightingOutlet } from "@/models";

class UserSightingService extends ElintApiService {
    public async getMyUserSightings(): Promise<IUserSighting[]> {
        try {
            const response = await fetch("/api/usersightings/my", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const userSightings = await response.json();
                return userSightings;
            }
            else {
                NotificationService.error("Error getting sightings !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting sightings !");
            return [];
        }
    }

    public async getAllBookmarks(): Promise<IUserSightingOutlet[]> {
        try {
            const response = await fetch("/api/usersightings/my/bookmarkedOutlets", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const userBookmarkedOutlets = await response.json();
                return userBookmarkedOutlets;
            }
            else {
                NotificationService.error("Error getting sightings !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting sightings !");
            return [];
        }
    }

    public async addUserSighting(data): Promise<void> {
        try {
            const response = await fetch("/api/usersightings/add", {
                method: "POST",
                headers: await this.getHeaders(),
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Sighting added !");
            }
            else {
                NotificationService.error("Error adding sighting !");
            }
        }
        catch (error) {
            NotificationService.error("Exception adding sighting !");
        }
    }

    public async updateUserSighting(data): Promise<void> {
        try {
            const response = await fetch(`/api/usersightings/${data.userSightingId}/update`, {
                method: "POST",
                headers: await this.getHeaders(),
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Sighting updated !");
            }
            else {
                NotificationService.error("Error updating sighting !");
            }
        }
        catch (error) {
            NotificationService.error("Exception updating sighting !");
        }
    }

    public async deleteUserSighting(userSightingId: number): Promise<void> {
        try {
            const response = await fetch(`/api/usersightings/${userSightingId}/delete`, {
                method: "POST",
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                NotificationService.success("Sighting deleted !");
            }
            else {
                NotificationService.error("Error deleting sighting !");
            }
        }
        catch (error) {
            NotificationService.error("Exception deleting sighting !");
        }
    }

    public async incompleteUserSighting(userSightingId: number): Promise<void> {
        try {
            const response = await fetch(`/api/usersightings/${userSightingId}/incomplete`, {
                method: "POST",
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                NotificationService.success("Sighting updated !");
            }
            else {
                NotificationService.error("Error updating sighting !");
            }
        }
        catch (error) {
            NotificationService.error("Exception updating sighting !");
        }
    }
}

const userSightingService = new UserSightingService();

export {UserSightingService, userSightingService};
