class CookieService {
    public setCookie(name: string, value: string, expire: number): void {
        // Set it expire
        const date = new Date();
        date.setTime(date.getTime() + (expire * 1000));
        document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
    }

    public getCookie(name: string): string {
        const values = `; ${document.cookie}`;
        const cookies = values.split(`; ${name}=`);

        if (cookies.length === 2) {
            return cookies.pop().split(";").shift();
        }
    }

    public deleteCookie(name: string) {
        // Set it expire in -1 days
        const date = new Date();
        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
        document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
    }
}

const cookieService = new CookieService();

export {CookieService, cookieService};
