import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IUserSighting } from "@/models";
import { AzureBlobService } from "@/services";
import VueEasyLightbox from "vue-easy-lightbox";

@Component({
    name: "user-sighting-info",
    components: {
        VueEasyLightbox,
    },
})

export default class UserSightingInfo extends Vue {
    @Prop()
    public userSighting: IUserSighting;

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public azureBlobService: AzureBlobService;
    public visible: boolean;
    public index: number;

    constructor() {
        super();

        this.loadPromises = [];
        this.isLoaded = false;
        this.azureBlobService = null;
        this.visible = false;
        this.index = 0;

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.isLoaded = true;
        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName, this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
    }

    public get photosWithFullUrl(): string[] {
        if (this.azureBlobService) {
            return this.userSighting.photos.map(photo => this.azureBlobService.getDownloadUrl(photo));
        }
    }

    public showImg(index: number): void {
        this.index = index;
        this.visible = true;
        }

    public handleHide(): void {
        this.visible = false;
    }
}
