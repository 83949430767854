import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { GoogleMapsService } from "@/services";

@Component({
    name: "store-locator-map",
})
export default class StoreLocatorMap extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public googleMapsService: GoogleMapsService;
    public map: google.maps.Map;
    public markers: google.maps.Marker[];
    public query: string;

    constructor() {
        super();
        this.loadPromises = [];
        this.isLoaded = false;
        this.googleMapsService = null;
        this.map = null;
        this.markers = [];
        this.query = "";

        if (!this.$store.state.app.googleMapsConfig) {
            this.loadPromises.push(this.$store.dispatch("app/loadGoogleMapsConfig"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.isLoaded = true;
        this.googleMapsService = new GoogleMapsService(this.$store.state.app.googleMapsConfig.apiKey);
        this.map = await this.googleMapsService.buildMap("map");
        this.markers = await this.googleMapsService.searchNearbyPlaces(this.map, (outlet) => {
            this.onSelectOutlet(outlet);
        });
    }

    public onSelectOutlet(outlet: google.maps.places.PlaceResult): void {
        this.$router.push(`/user-sightings/new?placeId=${outlet.place_id}`);
    }

    public async onKeyChanged(): Promise<void> {
        if (this.query) {
            const outlets = await this.googleMapsService.searchPlaces(this.query, this.map);
            this.googleMapsService.deleteMarkers(this.markers);
            this.markers = this.googleMapsService.createMarkers(this.map, outlets, (outlet) => {
                this.onSelectOutlet(outlet);
            });
        }
    }
}
