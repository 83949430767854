import { ActionTree } from "vuex";
import {
    LOGOUT,
    LOGIN,
    SET_CONFIG,
    SET_LOADED,
    SET_BLOB_SETTINGS,
    SET_GOOGLE_MAPS_CONFIG,
    SET_USER_ROLE,
    SET_USER_PROFILE,

    ADD_USERS,
    ADD_PENDING_USERS,
} from "./mutations-types";
import { IAppState } from "./state";
import * as Msal from "msal";
import {
    IUser,
    IUserGraph,
    Role,
} from "@/models";
import { authService, NotificationService, userService, pendingUserService } from "@/services";
import { router } from "@/router";

export const actions: ActionTree<IAppState, any> = {

    async load(context): Promise<void> {
        const response = await fetch("/api/config", {
            headers: {
                "Content-Type": "application/json",
            },
        });
        const appConfig = await response.json();
        context.commit(SET_CONFIG, appConfig);
        const msalConfig: Msal.Configuration = {
            auth: {
                clientId: appConfig.clientId,
                authority: `${appConfig.instance}/${appConfig.tenantId}`,
                redirectUri: `${window.location.origin}${appConfig.callbackPath}`,
                navigateToLoginRequestUrl: false,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
            },
        };
        authService.init(msalConfig);
        context.commit(LOGIN, authService.getAccount());
        if (context.state.userAccount) {
            context.dispatch("loadStorageConfig");
            const roleResponse = await fetch("/api/users/role", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${await authService.getAccessToken()}`,
                },
            });
            if (roleResponse.status === 401) {
                context.commit(SET_USER_ROLE, Role.None);
                router.push("unauthorized");
            }
            else {
                const role = await roleResponse.json();
                context.commit(SET_USER_ROLE, role);
                await context.dispatch("fetchUserProfile");
            }
        }
        context.commit(SET_LOADED, true);
    },

    async login(context): Promise<void> {
        if (!authService.getAccount()) {
            authService.login();
        }
    },

    async logout(context): Promise<void> {
        authService.logout();
        context.commit(LOGOUT);
    },

    async loadStorageConfig(context): Promise<void> {
        const response = await fetch("/api/config/storage", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${await authService.getAccessToken()}`,
            },
        });
        const blobSettings = await response.json();
        context.commit(SET_BLOB_SETTINGS, blobSettings);
    },

    async loadGoogleMapsConfig(context): Promise<void> {
        const response = await fetch("/api/config/googlemaps", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${await authService.getAccessToken()}`,
            },
        });
        const googleMapsConfig = await response.json();
        context.commit(SET_GOOGLE_MAPS_CONFIG, googleMapsConfig);
    },

    async fetchUserProfile(context): Promise<void> {
        const profile = await userService.getUserProfile();
        context.commit(SET_USER_PROFILE, profile);
    },

    async updateUserProfile(context, data): Promise<void> {
        await userService.updateUserProfile(data.marketId, data.currencyId, data.termsAndConditionsAccepted);
        await context.dispatch("fetchUserProfile");
    },

    // [USERS]
    async getUsers(context): Promise<void> {
        const response = await fetch("/api/users", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${await authService.getAccessToken()}`,
            },
        });
        const users = await response.json();
        context.commit(ADD_USERS, users);
    },

    async addUsers(context, users: IUserGraph[]): Promise<void> {
        await fetch("/api/users", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${await authService.getAccessToken()}`,
            },
            body: JSON.stringify({ users }),
        });
        await context.dispatch("getUsers");
    },

    async toggleUser(context, userId: number): Promise<void> {
        await fetch(`/api/users/${userId}/toggle`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${await authService.getAccessToken()}`,
            },
        });
        await context.dispatch("getUsers");
    },

    async editUser(context, user: IUser): Promise<void> {
        try {
            const response = await fetch(`/api/users/${user.userId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${await authService.getAccessToken()}`,
                },
                body: JSON.stringify({
                    name: user.name,
                    role: user.roleId,
                }),
            });
            if (Math.trunc(response.status / 100) === 2) {
                NotificationService.success("User edited !");
            }
            else {
                NotificationService.error("Error editing user !");
            }
        }
        catch (error) {
            NotificationService.error("Error editing user !");
        }
        await context.dispatch("getUsers");
    },

    // [PENDINGUSERS]
    async getPendingUsers(context): Promise<void> {
        const pendingUsers = await pendingUserService.getAllPendingUsers();
        context.commit(ADD_PENDING_USERS, pendingUsers);
    },

    async deletePendingUser(context, pendingUserId: number): Promise<void> {
        await pendingUserService.deletePendingUser(pendingUserId);
        context.dispatch("getPendingUsers");
    },

    async invitePendingUser(context, pendingUserId: number): Promise<void> {
        await pendingUserService.invitePendingUser(pendingUserId);
        context.dispatch("getPendingUsers");
    },
};
