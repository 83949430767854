import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";

class LocationService extends ElintApiService {
    public async searchLocations(query: string, latitude: number, longitude: number): Promise<google.maps.places.PlaceResult[]> {
        try {
            const response = await fetch(`/api/locations?query=${query}&latitude=${latitude}&longitude=${longitude}`, {
                headers: await this.getHeaders(),
            });
            if (response.status === 500) {
                NotificationService.error("Error getting locations !");
                return [];
            }
            const locations = await response.json();
            return locations;
        }
        catch (error) {
            NotificationService.error("Exception getting locations !");
            return [];
        }
    }
}

const locationService = new LocationService();

export {LocationService, locationService};
