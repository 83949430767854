import { } from "google-maps";

declare global {
    // tslint:disable-next-line:interface-name
    interface Window {
        google: typeof google;
    }
}

export class GoogleMapsService {
    public initPromise: Promise<any>;
    private readonly GMAPS_CALLBACK = "GMAPS_CALLBACK";
    private readonly storeTypes = ["liquor_store", "bar", "cafe", "night_club", "restaurant", "supermarket"];

    constructor(apiKey: string) {
        this.initPromise = new Promise<void>((resolve, reject) => {
            if (window.google) {
                resolve();
            }
            window[this.GMAPS_CALLBACK] = () => {
                resolve();
            };
        });

        if (!window.google) {
            const script = document.createElement("script");
            script.async = true;
            script.defer = true;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=${this.GMAPS_CALLBACK}`;
            document.querySelector("head").appendChild(script);
        }
    }

    public async buildMap(mapElementId: string = "map"): Promise<google.maps.Map> {
        await this.initPromise;

        const map = new google.maps.Map(document.getElementById(mapElementId), {
            center: { lat: 48.855653, lng: 2.349115 },
            zoom: 15,
            clickableIcons: false,
        });
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    map.setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    resolve(map);
                }, () => {
                    resolve(map);
                });
            }
            else {
                resolve(map);

            }
        });
    }

    public searchPlaces(query: string, map: google.maps.Map): Promise<google.maps.places.PlaceResult[]> {
        const request = {
            query,
            location: map.getCenter(),
        };

        const service = new google.maps.places.PlacesService(map);
        return new Promise<any[]>((resolve, reject) => {
            service.textSearch(request, (results, status) => {
                resolve(this.filterStores(results));
            });
        });
    }

    public searchNearbyPlaces(map: google.maps.Map, clickHandler?: (place: google.maps.places.PlaceResult) => void): Promise<google.maps.Marker[]> {
        if (navigator.geolocation) {
            const service = new google.maps.places.PlacesService(map);
            return new Promise<any[]>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition((position) => {
                    const request = {
                        location: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        },
                        radius: 100000,
                    };
                    service.nearbySearch(request, (results, status) => {
                        resolve(this.createMarkers(map, this.filterStores(results), clickHandler));
                    });
                });
            });
        }
    }

    public getPlaceDetails(placeId: string, map: google.maps.Map) {
        const service = new google.maps.places.PlacesService(map);
        return new Promise<any>((resolve, reject) => {
            service.getDetails({ placeId }, (results, status) => {
                resolve(results);
            });
        });
    }

    public createMarkers(map: google.maps.Map, places: google.maps.places.PlaceResult[], clickHandler?: (place: google.maps.places.PlaceResult) => void): google.maps.Marker[] {
        const markers = [];
        const bounds = new google.maps.LatLngBounds();
        places.forEach(place => {
            const image = {
                url: this.getIcon(place),
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(35, 35),
            };

            const marker = new google.maps.Marker({
                map,
                icon: image,
                title: place.name,
                position: place.geometry.location,
            });
            markers.push(marker);
            bounds.extend(place.geometry.location);
            if (clickHandler) {
                google.maps.event.addListener(marker, "click", () => clickHandler(place));
            }
        });
        return markers;
    }

    public deleteMarkers(markers: google.maps.Marker[]) {
        markers.forEach(marker => {
            marker.setMap(null);
        });
    }

    public centerMap(map: google.maps.Map, lat: number, lng: number) {
        map.setCenter({ lat, lng });
    }

    private filterStores(results: google.maps.places.PlaceResult[]): google.maps.places.PlaceResult[] {
        return results.filter(result => result.types.find(type => this.storeTypes.find(storeType => storeType === type)));
    }

    private getIcon(place: google.maps.places.PlaceResult): string {
        if (place.types) {
            if (place.types.includes("liquor_store")) { return `${window.location.origin}/dist/liquor.png`; }
            if (place.types.includes("bar")) { return `${window.location.origin}/dist/bar.png`; }
            if (place.types.includes("cafe")) { return `${window.location.origin}/dist/cafe.png`; }
            if (place.types.includes("night_club")) { return `${window.location.origin}/dist/music.png`; }
            if (place.types.includes("restaurant")) { return `${window.location.origin}/dist/restaurant.png`; }
            if (place.types.includes("supermarket")) { return `${window.location.origin}/dist/shopping-cart.png`; }
        }
        return place.icon;
    }
}
