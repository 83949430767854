import { GetterTree } from "vuex";
import { IAppState } from "./state";
import { IUser, IPendingUser } from "@/models";

const getters: GetterTree<IAppState, any> = {

    isAuthenticated(state: IAppState): boolean {
        return !(!state.userAccount);
    },

    menuItems(state: IAppState): any[] {
        return state.itemsMenu.filter(item => item.requiredRole <= state.userRole);
    },

    // [USERS]
    getUsers: (state) => (): IUser[] => {
        return state.users;
    },

    getActiveUsers: (state) => (): IUser[] => {
        return state.users.filter(x => x.isActive);
    },

    getUserById: (state) => (userId: number): IUser => {
        return state.users.find(x => x.userId === userId);
    },

    getUsersForIds: (state) => (userIds: number[]): IUser[] => {
        return state.users.filter(user => userIds.includes(user.userId));
    },

    // [PENDINGUSERS]
    getPendingUsers: (state) => (): IPendingUser[] => {
        return state.pendingUsers;
    },
};

export { getters };
