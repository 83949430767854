import { ActionTree } from "vuex";
import {
    SET_USER_SIGHTINGS,
    SET_USER_BOOKMARKED_OUTLETS,

    SET_SIGHTINGS,
    SET_SIGHTINGS_TOTAL,
    SET_EDITING_SIGHTING,
    SET_EDITING_SIGHTING_PREVIOUS_VALUES,

    SET_BRANDS,
    SET_BRAND_QUALITIES,

    SET_PRODUCTIONSITES,

    SET_CODINGS,
    SET_REPORTER_REQUESTS,
    SET_BRAND_COMPANIES,
    SET_COMPANIES,
    SET_CUSTOMERS,
    SET_CURRENCIES,
    SET_MARKETS,
    SET_MARKET_COMPANIES,
    SET_MARKET_COMPANY_CODES,
    SET_BRAND_COMPANY_REQUESTS,
    SET_MARKET_COMPANY_REQUESTS,

    SET_SUBJECT_TYPES,
    SET_SUBJECT,
    SET_SUBJECTS,
    SET_SUBJECT_JOURNAL_KEYWORDS,
    REMOVE_DELETED_SUBJECT,

    SET_USER_SIGHTING_SOURCES,

} from "./mutations-types";
import { ISightingState } from "./state";
import {
    sightingService,
    userSightingService,
    userSightingSourceService,
    reporterRequestService,
    dataService,
    brandCompanyRequestService,
    brandService,
    brandCompanyService,
    currencyService,
    marketService,
    marketCompanyService,
    productionSiteService,
    codingService,
    marketCompanyRequestService,
    subjectTypeService,
    subjectService,
} from "@/services";
import { IBrand, IBrandQuality, ISubject } from "@/models";

export const actions: ActionTree<ISightingState, any> = {

    // Data
    async fetchData(context): Promise<void> {
        const data = await dataService.getData();
        context.commit(SET_BRANDS, data.brands);
        context.commit(SET_BRAND_COMPANIES, data.brandcompanies);
        context.commit(SET_COMPANIES, data.companies);
        context.commit(SET_CUSTOMERS, data.customers);
        context.commit(SET_MARKETS, data.markets);
        context.commit(SET_MARKET_COMPANIES, data.marketCompanies);
        context.commit(SET_CODINGS, data.codings);
        context.commit(SET_CURRENCIES, data.currencies);
    },

    // User sighting
    async fetchUserSightings(context): Promise<void> {
        const userSightings = await userSightingService.getMyUserSightings();
        context.commit(SET_USER_SIGHTINGS, userSightings);
    },

    async fetchUserBookmarkedOutlets(context): Promise<void> {
        const userBookmarkedOutlets = await userSightingService.getAllBookmarks();
        context.commit(SET_USER_BOOKMARKED_OUTLETS, userBookmarkedOutlets);
    },

    async addUserSighting(context, data): Promise<void> {
        await userSightingService.addUserSighting(data);
        await context.dispatch("fetchUserSightings");
    },

    async updateUserSighting(context, data): Promise<void> {
        await userSightingService.updateUserSighting(data);
        await context.dispatch("fetchUserSightings");
    },

    async deleteUserSighting(context, userSightingId: number): Promise<void> {
        await userSightingService.deleteUserSighting(userSightingId);
        await context.dispatch("fetchUserSightings");
    },

    async incompleteUserSighting(context, userSightingId: number): Promise<void> {
        await userSightingService.incompleteUserSighting(userSightingId);
        await context.dispatch("fetchUserSightings");
    },

    async fetchUserSightingSources(context): Promise<void> {
        const userSightingSources = await userSightingSourceService.getUserSightingSources();
        context.commit(SET_USER_SIGHTING_SOURCES, userSightingSources);
    },

    // Sighting
    async fetchSightingsWithoutFrontFilter(context, data): Promise<void> {
        const sightings = await sightingService.getPagedSightingsByStatusAndKey(data);
        context.commit(SET_SIGHTINGS, sightings.items);
        context.commit(SET_SIGHTINGS_TOTAL, sightings.total);
    },

    async fetchAdvancedSightings(context, data): Promise<void> {
        const sightings = await sightingService.GetAdvancedSightings(data);
        context.commit(SET_SIGHTINGS, sightings.items);
        context.commit(SET_SIGHTINGS_TOTAL, sightings.total);
    },

    async fetchSightingById(context, id: number): Promise<void> {
        const sighting = await sightingService.getSightingById(id);
        context.commit(SET_EDITING_SIGHTING, sighting);
    },

    async fetchSightingPreviousValuesById(context, id: number): Promise<void> {
        const previousValues = await sightingService.getSightingPreviousValuesById(id);
        context.commit(SET_EDITING_SIGHTING_PREVIOUS_VALUES, previousValues);
    },

    async updateSighting(context, data): Promise<void> {
        await sightingService.updateSighting(data);
        await context.dispatch("fetchUserSightings");
    },

    async deleteSighting(context, data): Promise<void> {
        await sightingService.deleteSighting(data.sightingId);
        await context.dispatch("fetchSightingsWithoutFrontFilter", data);
        await context.dispatch("fetchUserSightings");
    },

    // Reporter Request
    async fetchReporterRequests(context): Promise<void> {
        const reporterRequests = await reporterRequestService.getActiveReporterRequests();
        context.commit(SET_REPORTER_REQUESTS, reporterRequests);
    },

    async addReporterRequest(context, data): Promise<void> {
        await reporterRequestService.addReporterRequest(data);
        context.dispatch("fetchReporterRequests");
    },

    async sendReporterRequests(context, reporterRequestIds: number[]) {
        await reporterRequestService.sendReporterRequests(reporterRequestIds);
        context.dispatch("fetchReporterRequests");
    },

    // Brand Company Request
    async fetchBrandCompanyRequests(context): Promise<void> {
        const brandCompanyRequests = await brandCompanyRequestService.getActiveBrandCompanyRequests();
        context.commit(SET_BRAND_COMPANY_REQUESTS, brandCompanyRequests);
    },

    async addBrandCompanyRequest(context, data): Promise<void> {
        await brandCompanyRequestService.addBrandCompanyRequest(data);
    },

    async sendBrandCompanyRequests(context, brandCompanyRequestIds: number[]) {
        await brandCompanyRequestService.sendBrandCompanyRequests(brandCompanyRequestIds);
        context.dispatch("fetchBrandCompanyRequests");
    },

    async answerBrandCompanyRequests(context, data: any) {
        await brandCompanyRequestService.answerBrandCompanyRequests(data);
        context.dispatch("fetchBrandCompanyRequests");
    },

    // Market Company Request
    async fetchMarketCompanyRequests(context): Promise<void> {
        const marketCompanyRequests = await marketCompanyRequestService.getActiveMarketCompanyRequests();
        context.commit(SET_MARKET_COMPANY_REQUESTS, marketCompanyRequests);
    },

    async addMarketCompanyRequest(context, data): Promise<void> {
        await marketCompanyRequestService.addMarketCompanyRequest(data);
    },

    async sendMarketCompanyRequests(context, marketCompanyRequestIds: number[]) {
        await marketCompanyRequestService.sendMarketCompanyRequests(marketCompanyRequestIds);
        context.dispatch("fetchMarketCompanyRequests");
    },

    async answerMarketCompanyRequests(context, data: any) {
        await marketCompanyRequestService.answerMarketCompanyRequests(data);
        context.dispatch("fetchMarketCompanyRequests");
    },

    // Brand
    async fetchBrands(context): Promise<void> {
        const brands = await brandService.getAllBrands();
        context.commit(SET_BRANDS, brands);
    },

    async fetchBrandQualities(context, brandId: number) {
        const brandQualities = await brandService.getBrandQualities(brandId);
        context.commit(SET_BRAND_QUALITIES, { brandId, brandQualities });
    },

    async addBrand(context, data: any): Promise<void> {
        await brandService.addBrand(data);
        await context.dispatch("fetchBrands");
    },

    async editBrand(context, brand: IBrand): Promise<void> {
        await brandService.editBrand(brand);
        await context.dispatch("fetchBrands");
    },

    async upsertBrandQuality(context, brandQuality: IBrandQuality): Promise<void> {
        await brandService.upsertBrandQuality(brandQuality);
        await context.dispatch("fetchBrandQualities", brandQuality.brandId);
    },

    // Brand Company
    async fetchBrandCompanies(context): Promise<void> {
        const brandcompanies = await brandCompanyService.getAllBrandCompanies();
        context.commit(SET_BRAND_COMPANIES, brandcompanies);
    },

    // Currencies
    async fetchCurrencies(context): Promise<void> {
        const currencies = await currencyService.getAllCurrencies();
        context.commit(SET_CURRENCIES, currencies);
    },

    // Markets
    async fetchMarkets(context): Promise<void> {
        const markets = marketService.getAllMarkets();
        context.commit(SET_MARKETS, markets);
    },

    // Market company
    async fetchMarketCompanies(context): Promise<void> {
        const marketCompanies = await marketCompanyService.getAllMarketCompanies();
        context.commit(SET_MARKET_COMPANIES, marketCompanies);
    },

    async fetchMarketCompanyCodes(context, brandCompanyId: number): Promise<void> {
        const marketCompanyCodes = await marketCompanyService.getMarketCompanyCodes(brandCompanyId);
        context.commit(SET_MARKET_COMPANY_CODES, marketCompanyCodes);
    },

    // Production site
    async fetchProductionSites(context): Promise<void> {
        const productionSites = await productionSiteService.getAllProductionSites();
        context.commit(SET_PRODUCTIONSITES, productionSites);
    },

    async addProductionSite(context, name: string): Promise<void> {
        await productionSiteService.addProductionSite(name);
        await context.dispatch("fetchProductionSites");
    },

    async getBrandsForProductionSite(context, id: number): Promise<number[]> {
        const brandIds = await productionSiteService.getBrandsForProductionSite(id);
        return brandIds;
    },

    async addBrandsToProductionSite(context, data): Promise<void> {
        await productionSiteService.addBrandsToProductionSite(data);
        await context.dispatch("fetchProductionSites");
    },

    async upsertUsersToProductionSite(context, data): Promise<void> {
        await productionSiteService.upsertUsersToProductionSite(data);
        await context.dispatch("fetchProductionSites");
        this.dispatch("app/getUsers");
    },

    // Coding
    async fetchCodings(context): Promise<void> {
        const codings = await codingService.getAllCodings();
        context.commit(SET_CODINGS, codings);
    },

    // Subject
    async fetchSubjectTypes(context): Promise<void> {
        const subjectTypes = await subjectTypeService.getSubjectTypes();
        context.commit(SET_SUBJECT_TYPES, subjectTypes);
    },

    async fetchSubject(context, subjectId: number): Promise<void> {
        const subject = await subjectService.getSubject(subjectId);
        context.commit(SET_SUBJECT, subject);
    },

    async fetchSubjects(context): Promise<void> {
        const subjects = await subjectService.getAllSubjects();
        context.commit(SET_SUBJECTS, subjects);
    },

    async fetchSubjectsByType(context, subjectTypeId: number): Promise<void> {
        const subjects = await subjectService.getSubjectsByType(subjectTypeId);
        context.commit(SET_SUBJECTS, subjects);
    },

    async fetchSubjectJournalKeywords(context): Promise<void> {
        const keywords = await subjectService.getSubjectJournalKeywords();
        context.commit(SET_SUBJECT_JOURNAL_KEYWORDS, keywords);
    },

    async addSubject(context, data: ISubject): Promise<number> {
        const subjectId: number = await subjectService.addSubject(data);
        context.dispatch("fetchSubjectsByType", data?.subjectTypeId);
        return subjectId;
    },

    async updateSubject(context, data): Promise<void> {
        await subjectService.updateSubject(data);
        await context.dispatch("fetchSubjectJournalKeywords");
    },

    async deleteSubject(context, data: number): Promise<boolean> {
        const result: boolean = await subjectService.deleteSubject(data);
        context.commit(REMOVE_DELETED_SUBJECT, data);
        return result;
    },
};
