import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IReporterRequest } from "@/models";

class ReporterRequestService extends ElintApiService {
    public async getAllReporterRequests(): Promise<IReporterRequest[]> {
        try {
            const response = await fetch("/api/reporterrequests", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const reporterRequests = await response.json();
                return reporterRequests;
            }
            else {
                NotificationService.error("Error getting reporter requests !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting reporter requests !");
        }
    }

    public async getActiveReporterRequests(): Promise<IReporterRequest[]> {
        try {
            const response = await fetch("/api/reporterrequests/active", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const reporterRequests = await response.json();
                return reporterRequests;
            }
            else {
                NotificationService.error("Error getting reporter requests !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting reporter requests !");
        }
    }

    public async addReporterRequest(data): Promise<void> {
        try {
            const response = await fetch("/api/reporterRequests", {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Reporter request added !");
            }
            else {
                NotificationService.error("Error adding reporter request !");
            }
        }
        catch (error) {
            NotificationService.error("Exception adding reporter request !");
        }
    }

    public async sendReporterRequests(reporterRequestIds: number[]) {
        try {
            const response = await fetch("/api/reporterrequests/send", {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(reporterRequestIds),
            });
            if (response.ok) {
                NotificationService.success("Reporter requests sent !");
            }
            else {
                NotificationService.error("Error sending reporter requests !");
            }
        }
        catch (error) {
            NotificationService.error("Exception sending reporter requests !");
        }
    }
}

const reporterRequestService = new ReporterRequestService();

export {ReporterRequestService, reporterRequestService};
