import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IBrand, IBrandQuality } from "@/models";

class BrandService extends ElintApiService {
    public async getAllBrands(): Promise<IBrand[]> {
        try {
            const response = await fetch("/api/brands", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const brands = await response.json();
                return brands;
            }
            else {
                NotificationService.error("Error getting brands !");
            }
        }
        catch (error) {
            NotificationService.error("Exception getting brands !");
            return null;
        }
    }

    public async getBrandQualities(brandId: number): Promise<IBrandQuality[]> {
        try {
            const response = await fetch(`/api/brands/${brandId}/qualities`, {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const brandQualities = await response.json();
                return brandQualities;
            }
            else {
                NotificationService.error("Error getting brand qualities !");
            }
        }
        catch (error) {
            NotificationService.error("Exception getting brand qualities !");
            return null;
        }
    }

    public async addBrand(data: any): Promise<void> {
        try {
            const body = {
                name: data.brandName,
                subjectId: data.brandCompanyId,
             };

            const response = await fetch(`/api/brands`, {
                method: "POST",
                headers: await this.getHeaders(),
                body: JSON.stringify(body),
            });
            if (response.ok) {
                NotificationService.success("Brand added !");
            }
            else {
                NotificationService.error("Error adding brand !");
            }
        }
        catch (error) {
            NotificationService.error("Exception adding brand !");
        }
    }

    public async editBrand(brand: IBrand): Promise<void> {
        try {
            const body = {
                name: brand.name,
                regexLotNumber: brand.regexLotNumber,
                regexDescription: brand.regexDescription,
                dateRequired: brand.dateRequired,
                dateAvailable: brand.dateAvailable,
                timeRequired: brand.timeRequired,
                timeAvailable: brand.timeAvailable,
            };
            const response = await fetch(`/api/brands/${brand.brandId}`, {
                method: "PUT",
                headers: await this.getHeaders(),
                body: JSON.stringify(body),
            });
            if (response.ok) {
                NotificationService.success("Brand edited !");
            }
            else {
                NotificationService.error("Error editing brand !");
            }
        }
        catch (error) {
            NotificationService.error("Exception editing brand !");
        }
    }

    public async upsertBrandQuality(brandQuality: IBrandQuality): Promise<void> {
        try {
            const response = await fetch(`/api/brands/quality/upsert`, {
                method: "POST",
                headers: await this.getHeaders(),
                body: JSON.stringify(brandQuality),
            });
            if (response.ok) {
                NotificationService.success("Brand quality edited !");
            }
            else {
                NotificationService.error("Error editing brand quality !");
            }
        }
        catch (error) {
            NotificationService.error("Exception editing brand quality !");
        }
    }
}

const brandService = new BrandService();

export {BrandService, brandService};
