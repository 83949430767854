import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IMarketCompany, IMarketCompanyCode, ISubject } from "@/models";

class MarketCompanyService extends ElintApiService {
    public async getAllMarketCompanies(): Promise<ISubject[]> {
        try {
            const response = await fetch("/api/subjects/type/2", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const marketCompanies = await response.json();
                return marketCompanies;
            }
            else {
                NotificationService.error("Error getting market companies !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting market companies !");
            return null;
        }
    }

    public async getMarketCompanyByReferenceNumber(brandCompanyId: number, referenceNumber: string): Promise<IMarketCompany> {
        try {
            const response = await fetch(`/api/marketcompanies/referencenumber/${brandCompanyId}/${referenceNumber}`, {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                if (response.headers.get("Content-Length") === "0") {
                    return null;
                }
                const marketCompany = await response.json();
                return marketCompany;
            }
            else {
                NotificationService.error("Error getting market company !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Error getting market company !");
            return null;
        }
    }

    public async getMarketCompanyCodes(brandCompanyId: number): Promise<IMarketCompanyCode[]> {
        try {
            const response = await fetch(`/api/markets/codes/${brandCompanyId}`, {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const marketCompanyCodes = await response.json();
                return marketCompanyCodes;
            }
            else {
                NotificationService.error("Error getting market company codes !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Error getting market company codes !");
            return null;
        }
    }
}

const marketCompanyService = new MarketCompanyService();

export {MarketCompanyService, marketCompanyService};
