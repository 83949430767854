import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";

class UserService extends ElintApiService {
    public async getUserProfile(): Promise<any> {
        try {
            const response = await fetch("/api/users/profile", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const profile = await response.json();
                return profile;
            }
            else {
                NotificationService.error("Error getting user profile !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting user profile !");
            return null;
        }
    }

    public async updateUserProfile(
        marketId: number,
        currencyId: number,
        termsAndConditionsAccepted: boolean)
        : Promise<void> {
        try {
            const response = await fetch("/api/users/profile", {
                method: "POST",
                headers: await this.getHeaders(),
                body: JSON.stringify({ marketId, currencyId, termsAndConditionsAccepted }),
            });
            if (response.ok) {
                NotificationService.success("User profile updated !");
            }
            else {
                NotificationService.error("Error updating user profile !");
            }
        }
        catch (error) {
            NotificationService.error("Exception updating user profile !");
        }
    }
}

const userService = new UserService();

export {UserService, userService};
