import { MutationTree } from "vuex";
import * as Msal from "msal";
import {
    LOGIN,
    LOGOUT,
    SET_CONFIG,
    SET_BLOB_SETTINGS,
    SET_GOOGLE_MAPS_CONFIG,
    SET_LOADED,
    SET_USER_ROLE,
    SET_USER_PROFILE,

    ADD_USERS,
    ADD_PENDING_USERS,
} from "./mutations-types";
import { IAppState } from "./state";
import { Role, IUser, IUserProfile, IPendingUser } from "@/models";

export const mutations: MutationTree<IAppState> = {

    [LOGIN](state, payload: Msal.Account): void {
        state.userAccount = payload;
    },

    [LOGOUT](state): void {
        state.userAccount = null;
    },

    [SET_CONFIG](state, payload: any): void {
        state.config = payload;
    },

    [SET_BLOB_SETTINGS](state, payload: any): void {
        state.blobSettings = payload;
    },

    [SET_GOOGLE_MAPS_CONFIG](state, payload: any): void {
        state.googleMapsConfig = payload;
    },

    [SET_LOADED](state, payload: boolean): void {
        state.loaded = payload;
    },

    [SET_USER_ROLE](state, payload: Role): void {
        state.userRole = payload;
    },

    [SET_USER_PROFILE](state, payload: IUserProfile): void {
        state.userProfile = payload;
    },

    // [USERS]
    [ADD_USERS](state, payload: IUser[]): void {
        state.users = payload;
    },

    [ADD_PENDING_USERS](state, payload: IPendingUser[]): void {
        state.pendingUsers = payload;
    },
};
