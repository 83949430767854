import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IMarket } from "@/models";

class MarketService extends ElintApiService {
    public async getAllMarkets(): Promise<IMarket[]> {
        try {
            const response = await fetch("/api/markets", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const markets = await response.json();
                return markets;
            }
            else {
                NotificationService.error("Error getting markets !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting markets !");
            return null;
        }
    }
}

const marketService = new MarketService();

export {MarketService, marketService};
