import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as icons from "@/components/shared/icons";
import PeoplePicker from "@/components/shared/peoplePicker/PeoplePicker.vue";
import { Loader } from "@/components";

import {
    IPendingUser,
} from "@/models";

@Component({
    name: "PendingUsers",
    components: {
        "people-picker": PeoplePicker,
        "add-icon": icons.AddIcon,
        Loader,
    },
})
export default class PendingUsers extends Vue {

    public readonly deletePendingUserModal: string = "deletePendingUserModal";
    public readonly invitePendingUserModal: string = "invitePendingUserModal";

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public search: string;
    public selectedUser: IPendingUser;
    public editIndex: number;

    constructor() {
        super();

        this.loadPromises = [];
        this.isLoaded = false;

        this.loadPromises.push(this.$store.dispatch("app/getPendingUsers"));

        this.search = "";
        this.selectedUser = null;
        this.editIndex = null;
    }

    public get pendingUsers(): IPendingUser[] {
        return this.$store.getters["app/getPendingUsers"]().filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()));
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.isLoaded = true;
    }

    public setEditPendingUser(index: number): void {
        if (index !== this.editIndex) {
            this.selectedUser = { ...this.pendingUsers[index] };
            this.editIndex = index;
        }
    }

    public inviteUser(): void {
        this.$modal.hide(this.invitePendingUserModal);
        this.$store.dispatch("app/invitePendingUser", this.selectedUser.pendingUserId);
    }

    public async declineUser(): Promise<void> {
        this.$modal.hide(this.deletePendingUserModal);
        this.$store.dispatch("app/deletePendingUser", this.selectedUser.pendingUserId);
    }

    public openModal(): void {
        this.$modal.show(this.deletePendingUserModal);
    }

    public openInvitationModal(): void {
        this.$modal.show(this.invitePendingUserModal);
    }

    public closeModal(name: string): void {
        this.$modal.hide(name);
    }
}
