import { render, staticRenderFns } from "./ValidationSummary.vue?vue&type=template&id=f2ee81d0&scoped=true&"
import script from "./ValidationSummary.ts?vue&type=script&lang=ts&"
export * from "./ValidationSummary.ts?vue&type=script&lang=ts&"
import style0 from "./ValidationSummary.scss?vue&type=style&index=0&id=f2ee81d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2ee81d0",
  null
  
)

export default component.exports