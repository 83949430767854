import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import NumberSightingAdvancedSearch from "./numberSightingAdvancedSearch/NumberSightingAdvancedSearch.vue";
import TextSightingAdvancedSearch from "./textSightingAdvancedSearch/TextSightingAdvancedSearch.vue";
import { IPaginatedAdvancedSearchForm, IFilterField } from "@/models";

@Component({
    name: "sighting-advanced-search",
    components: {
        NumberSightingAdvancedSearch,
        TextSightingAdvancedSearch,
    },
})

export default class SightingAdvancedSearch extends Vue {
    @Prop({ required: true, type: Object })
    public modelValue: IPaginatedAdvancedSearchForm;

    public viewModel: IFilterField[] = [];

    public newFilter: IFilterField = null;

    private filters: IFilterField[] = [
        { name: "alcoholVolume", label: "Alcohol Volume", type: "number", step: 0.1 },
        { name: "backLabelPartNumber", label: "Back Label Part Number", type: "text" },
        { name: "brandName", label: "Brand", type: "text" },
        { name: "brandQualityName", label: "Brand Quality", type: "text" },
        { name: "caseCode", label: "Case Code", type: "text" },
        { name: "lotDate", label: "Lot Date", type: "text" },
        { name: "lotNumber", label: "Lot Number", type: "text" },
        { name: "lotTime", label: "Lot Time", type: "text" },
        { name: "marketName", label: "Market", type: "text" },
        { name: "outletName", label: "Outlet", type: "text" },
        { name: "palletCodeSscc", label: "Pallet Code Sscc", type: "text" },
        { name: "userSightingId", label: "Record", type: "number" },
        { name: "size", label: "Size", type: "number", step: 0.1 },
        { name: "street", label: "Street", type: "text" },
        { name: "userName", label: "User", type: "text" },
    ];

    public get availableFilters(): IFilterField[] {
        return this.filters.filter(f => this.viewModel.findIndex(v => v.name === f.name) < 0);
    }

    public async created(): Promise<void> {
        this.viewModel = this.setViewModel();
    }

    @Watch("modelValue")
    public onModelValueChanged(): void {
        this.viewModel = this.setViewModel();
    }

    public onFilter() {
        const value = {};
        for (const field of this.viewModel) {
            if (field.value !== null && field.value !== undefined) {
                if (field.type === "number") {
                    value[field.name + "Operator"] = field.operator ?? "eq";
                }

                value[field.name] = field.value;
            }
        }

        this.$emit("change", value);
    }

    public onNewFilterSelected() {
        if (this.newFilter) {
            if (this.newFilter.type === "number") {
                this.viewModel.push({
                    ...this.newFilter,
                    operator: "eq",
                    value: 0,
                });
            } else {
                this.viewModel.push({
                    ...this.newFilter,
                    value: null,
                });
            }

            this.newFilter = null;
        }
    }

    public onRemoveFilter(value: string) {
        this.viewModel = this.viewModel.filter(v => v.name !== value);
    }

    private setViewModel(): IFilterField[] {
        if (Object.keys(this.modelValue).length === 0) {
            return [];
        }

        const vm = [];
        for (const key of Object.keys(this.modelValue)) {
            const filter = this.filters.find(f => f.name === key);

            if (this.modelValue[key] !== null &&
                this.modelValue !== undefined &&
                filter
            ) {
                if (filter.type === "number") {
                    vm.push({
                        ...filter,
                        operator: this.modelValue[key + "Operator"],
                        value: this.modelValue[key],
                    });
                } else {
                    vm.push({
                        ...filter,
                        value: this.modelValue[key],
                    });
                }
            }
        }

        return vm;
    }
}
