import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { ISubject } from "@/models";

class BrandCompanyService extends ElintApiService {
    public async getAllBrandCompanies(): Promise<ISubject[]> {
        try {
            const response = await fetch(encodeURI(`/api/subjects/type/1`), {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const brandcompanies = await response.json();
                return brandcompanies;
            }
            else {
                NotificationService.error("Error getting brand companies !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting brand companies !");
            return [];
        }
    }
}

const brandCompanyService = new BrandCompanyService();

export {BrandCompanyService, brandCompanyService};
