import Vue from "vue";
import { Component } from "vue-property-decorator";
import {
    UserSightingDashboard,
    SightingDashboard,
    BrandCompanyRequestDashboard,
    MarketCompanyRequestDashboard,
} from "@/views";
import { Role } from "@/models";

@Component({
    name: "home",
})
export default class Home extends Vue {

    public get homeComponent() {
        switch (this.$store.state.app.userRole) {
            case Role.BrandCompanyContact:
                return BrandCompanyRequestDashboard;
            case Role.MarketCompanyContact:
                return MarketCompanyRequestDashboard;
            case Role.TraceTeam:
            case Role.BSTeam:
            case Role.Administrator:
                return SightingDashboard;
            default:
                return UserSightingDashboard;
        }
    }
}
