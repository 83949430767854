import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IUserSightingSource } from "@/models";

class UserSightingSourceService extends ElintApiService {
    public async getUserSightingSources(): Promise<IUserSightingSource[]> {
        try {
            const response = await fetch("/api/usersightingsources", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const userSightingSources = await response.json();
                return userSightingSources;
            }
            else {
                NotificationService.error("Error getting user sighting sources !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting user sighting sources !");
            return [];
        }
    }
}

const userSightingSourceService = new UserSightingSourceService();

export { UserSightingSourceService, userSightingSourceService };
