import Vue from "vue";
import { Component } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import { Loader, UserSightingBottle } from "@/components";
import VueEasyLightbox from "vue-easy-lightbox";
import * as icons from "@/components/shared/icons";
import {
    IUserSighting,
    RecordStatus,
    IUserSightingBottle,
    IUserSightingOutlet,
    IReporterRequest,
    IMarket,
    ICurrency,
} from "@/models";
import { GoogleMapsService, locationService } from "@/services";
import { userSightingFormValidations } from "./UserSightingFormValidations";

@Component({
    name: "user-sighting-form",
    components: {
        "datepicker": Datepicker,
        Multiselect,
        Loader,
        VueEasyLightbox,
        UserSightingBottle,
        "save-icon": icons.SaveIcon,
        "edit-icon": icons.EditIcon,
        "add-icon": icons.AddIcon,
    },
    validations: userSightingFormValidations,
})
export default class UserSightingForm extends Vue {

    public userSightingOutlet: IUserSightingOutlet;
    public userSightingBottles: IUserSightingBottle[];

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public dragging: boolean;
    public loading: boolean;
    public googleMapsService: GoogleMapsService;
    public visible: boolean;
    public index: number;
    public isDisabledFields: boolean;

    public map: google.maps.Map;
    public markers: google.maps.Marker[];
    public userMarkers: google.maps.Marker[];
    public userMarker: google.maps.Marker;
    public outlets: google.maps.places.PlaceResult[];
    public outletsLoading: boolean;
    public outlet: google.maps.places.PlaceResult;
    public outletQuery: string;
    public searchOutletsTimeout: any;

    public searchCurrenciesTimeout: any;
    public filteredCurrencies: ICurrency[];

    public searchMarketTimeout: any;
    public filteredMarkets: IMarket[];

    public notes: string;
    public currencyId: number;

    public currency: ICurrency;
    public market: IMarket;

    private selectedMarkerOnMap: google.maps.Marker = null;

    public constructor() {
        super();

        this.userSightingOutlet = {
            dateSighted: new Date(),
            outletType: null,
            marketId: null,
            outletName: null,
            province: null,
            street: null,
            streetNumber: null,
            townCity: null,
            zipCode: null,
            countryState: null,
            latitude: null,
            longitude: null,
            bookmarked: false,
        };

        this.userSightingBottles = [];

        this.loadPromises = [];
        this.isLoaded = false;
        this.dragging = false;
        this.loading = false;
        this.googleMapsService = null;
        this.map = null;
        this.markers = [];
        this.userMarkers = [];
        this.userMarker = null;
        this.outlets = [];
        this.outletsLoading = false;
        this.outlet = null;
        this.outletQuery = null;
        this.searchOutletsTimeout = null;
        this.visible = false;
        this.index = 0;
        this.isDisabledFields = false;

        this.searchCurrenciesTimeout = null;
        this.filteredCurrencies = [];
        this.currency = null;

        this.searchMarketTimeout = null;
        this.filteredMarkets = [];
        this.market = null;

        this.notes = "";
        this.currencyId = 0;

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }
        if (!this.$store.state.app.googleMapsConfig) {
            this.loadPromises.push(this.$store.dispatch("app/loadGoogleMapsConfig"));
        }
        if (!this.$store.state.sighting.userSightings) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchUserSightings"));
        }
        else {
            this.$store.dispatch("sighting/fetchUserSightings");
        }
        if (!this.$store.state.sighting.brands) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrands"));
        }
        if (!this.$store.state.sighting.markets) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchMarkets"));
        }

        if (this.$store.state.sighting.userBookmarkedOutlets?.length < 1) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchUserBookmarkedOutlets"));
        }

        this.filteredCurrencies = this.currencies;
        this.filteredMarkets = this.markets;
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.isLoaded = true;

        if (this.userSighting) {
            if (this.userSighting.recordStatus === RecordStatus.Submitted) {
                this.$router.push("/unauthorized");
            } else {

                this.userSightingOutlet = {
                    ...this.userSighting,
                };

                this.userSightingBottles.push({
                    alcoholVolume: this.userSighting.alcoholVolume,
                    backLabelPartNumber: this.userSighting.backLabelPartNumber,
                    brandId: this.userSighting.brand?.brandId,
                    brand: this.userSighting.brand,
                    caseCode: this.userSighting.caseCode,
                    decoded: this.userSighting.decoded,
                    estimatedQuantity: this.userSighting.estimatedQuantity,
                    importerSubjectId: this.userSighting.importerSubjectId,
                    lotDate: this.userSighting.lotDate,
                    lotNumber: this.userSighting.lotNumber,
                    lotTime: this.userSighting.lotTime,
                    palletCodeSscc: this.userSighting.palletCodeSscc,
                    photos: this.userSighting.photos,
                    brandQualityId: this.userSighting.quality?.brandQualityId,
                    quality: this.userSighting.quality,
                    quantitySighted: this.userSighting.quantitySighted,
                    rspPrice: this.userSighting.rspPrice,
                    size: this.userSighting.size,
                    expanded: false,
                });
                this.notes = this.userSighting.notes;
                this.currencyId = this.userSighting.currencyId;
                this.currency = this.currencies.filter(c => c.currencyId === this.currencyId)[0];
                this.market = this.markets.filter(m => m.marketId === this.userSightingOutlet.marketId)[0];

                if (this.userSighting.recordStatus === 3) {
                    this.isDisabledFields = true;
                }
            }
        }
        else if (this.userSightingId) {
            this.$router.push("/not-found");
        }
        else {
            this.userSightingOutlet.marketId = this.$store.state.app?.userProfile.marketId;
            this.currencyId = this.$store.state.app?.userProfile.currencyId;
            this.currency = this.currencies.filter(c => c.currencyId === this.currencyId)[0];
            this.market = this.markets.filter(m => m.marketId === this.userSightingOutlet.marketId)[0];
            this.userSightingBottles.push(this.initBottle());
        }

        this.googleMapsService = new GoogleMapsService(this.$store.state.app.googleMapsConfig.apiKey);
        this.map = await this.googleMapsService.buildMap("map");

        if (this.userSightingOutlet?.latitude && this.userSightingOutlet.longitude) {
            const myLatLng = { lat: this.userSightingOutlet.latitude, lng: this.userSightingOutlet.longitude };

            this.map.setOptions({
                draggable: this.userSighting?.recordStatus !== 3,
                center: myLatLng,
            });

            this.placeMarker(myLatLng);
            this.userMarker.setTitle(this.userSightingOutlet.outletName);
            this.userMarker.setZIndex(999);
        }

        if (this.userSighting?.recordStatus !== 3) {
            this.map.addListener("dragend", () => {
                if (!this.outlet) {
                    this.searchOutlets(this.outletQuery);
                }
            });

            // Add user marker
            this.map.addListener("rightclick", (event) => {
                this.placeMarker(event.latLng);
                this.onRemoveOutlet();
                this.googleMapsService.centerMap(this.map, this.userMarker.getPosition().lat(), this.userMarker.getPosition().lng());
                this.removeSelectedOutlet();
                this.userSightingOutlet.latitude = this.userMarker.getPosition().lat();
                this.userSightingOutlet.longitude = this.userMarker.getPosition().lng();
                this.userSightingOutlet.bookmarked = true;
             });

            // Add bookmarked outlets from user
            if (this.bookmarkedOutlets) {
                this.bookmarkedOutlets.forEach(place => {
                    const marker = new google.maps.Marker({
                        map: this.map,
                        title: place.outletName,
                        icon:  {
                                url: `${window.location.origin}/dist/user.png`,
                                size: new google.maps.Size(71, 71),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(17, 34),
                                scaledSize: new google.maps.Size(35, 35),
                        },
                        position: { lat: place.latitude, lng: place.longitude },
                    });
                    marker.addListener("click", () => {
                        this.onRemoveOutlet();
                        this.googleMapsService.deleteMarkers(this.markers);
                        this.googleMapsService.centerMap(this.map, marker.getPosition().lat(), marker.getPosition().lng());

                        this.removeSelectedOutlet();

                        this.selectedMarkerOnMap = new google.maps.Marker({
                            map: this.map,
                            icon: this.selectedIcon(),
                            title: marker.getTitle(),
                            position: marker.getPosition(),
                        });

                        this.userSightingOutlet.latitude = marker.getPosition().lat();
                        this.userSightingOutlet.longitude = marker.getPosition().lng();
                        this.userSightingOutlet.outletName = place.outletName;
                        this.userSightingOutlet.streetNumber = place.streetNumber;
                        this.userSightingOutlet.street = place.street;
                        this.userSightingOutlet.zipCode = place.zipCode;
                        this.userSightingOutlet.townCity = place.townCity;
                        this.userSightingOutlet.province = place.province;
                        this.userSightingOutlet.countryState = place.countryState;
                        this.userSightingOutlet.bookmarked = place.bookmarked;
                     });
                    this.userMarkers.push(marker);
                });
            }

            if (this.placeId) {
                this.setOutlet(this.placeId);
            }
            else {
                this.searchOutlets("");
            }
        }
    }

    public get userSightingId(): number {
        return this.$route.params.userSightingId ? parseInt(this.$route.params.userSightingId, 10) : null;
    }

    public get placeId(): string {
        return this.$route.query.placeId as string;
    }

    public get userSighting(): IUserSighting {
        if (this.userSightingId) {
            return this.$store.getters["sighting/getUserSighting"](this.userSightingId);
        }
        return null;
    }

    public get markets(): IMarket[] {
        return this.$store.state.sighting.markets ?? [];
    }

    public get bookmarkedOutlets(): IUserSightingOutlet[] {
        return this.$store.state.sighting.userBookmarkedOutlets ?? [];

    }

    public get reporterRequest(): IReporterRequest {
        if (this.userSighting) {
            return this.userSighting.reporterRequest;
        }
    }

    public get currencies(): ICurrency[] {
        return this.$store.state.sighting.currencies ?? [];
    }

    // Outlets
    public removeSelectedOutlet(): void {
        if (!!this.selectedMarkerOnMap) {
            this.googleMapsService.deleteMarkers([this.selectedMarkerOnMap]);
        }
    }

    public selectedIcon(): any {
        const icon = {
            url: `${window.location.origin}/dist/selected.png`,
            origin: new google.maps.Point(-1, -1),
            scaledSize: new google.maps.Size(43, 43),
        };
        return icon;
    }

    public onSearchCurrencyChanged(query) {
        if (this.searchCurrenciesTimeout) {
            clearTimeout(this.searchCurrenciesTimeout);
        }
        this.searchCurrenciesTimeout = setTimeout(async () => {
            await this.searchCurrencies(query);
        }, 400);
    }

    public searchCurrencies(query: string) {
        this.filteredCurrencies = this.currencies.filter(c => c.name.toLowerCase().includes(query.toLowerCase()));
    }

    public onSelectCurrency(currency: ICurrency) {
        this.currency = currency;
        this.currencyId = currency.currencyId;
        if (this.userSighting) {
            this.userSighting.currencyId = currency.currencyId;
        }
    }

    public onSearchMarketChanged(query) {
        if (this.searchMarketTimeout) {
            clearTimeout(this.searchMarketTimeout);
        }
        this.searchMarketTimeout = setTimeout(async () => {
            await this.searchMarket(query);
        }, 400);
    }

    public searchMarket(query: string) {
        this.filteredMarkets = this.markets.filter(m => m.name.toLowerCase().includes(query.toLowerCase()));
    }

    public onSelectMarket(market: IMarket) {
        this.market = market;
        this.userSightingOutlet.marketId = market.marketId;
        if (this.userSighting) {
            this.userSighting.marketId = market.marketId;
        }
    }

    public onRemoveMarket() {
        this.market = null;
        this.userSightingOutlet.marketId = 0;
        this.userSighting.marketId = 0;
    }

    public onRemoveCurrency() {
        this.currency = null;
        this.currencyId = 0;
        if (this.userSighting) {
            this.userSighting.currencyId = 0;
        }
    }

    public onSearchOutletChanged(query) {
        if (this.searchOutletsTimeout) {
            clearTimeout(this.searchOutletsTimeout);
        }
        this.searchOutletsTimeout = setTimeout(async () => {
            await this.searchOutlets(query);
        }, 400);
    }

    public async searchOutlets(query: string): Promise<void> {
        if (!this.outlet) {
            this.outletQuery = query;
            this.outletsLoading = true;
            const position = this.map.getCenter();
            this.outlets = await locationService.searchLocations(query, position.lat(), position.lng());
            this.outletsLoading = false;
            this.googleMapsService.deleteMarkers(this.markers);
            this.markers = this.googleMapsService.createMarkers(this.map, this.outlets, (outlet) => {
                this.setOutlet(outlet.place_id);
            });
        }
    }

    public async onSelectOutlet(outlet: google.maps.places.PlaceResult): Promise<void> {
        await this.setOutlet(outlet.place_id);
    }

    public async setOutlet(placeId: string): Promise<void> {
        this.outlet = await this.googleMapsService.getPlaceDetails(placeId, this.map);

        this.removeSelectedOutlet();

        this.selectedMarkerOnMap = new google.maps.Marker({
            map: this.map,
            icon: this.selectedIcon(),
            title: this.outlet.name,
            position: this.outlet.geometry.location,
        });

        this.selectedMarkerOnMap.addListener("click", () => {
            this.removeSelectedOutlet();
            this.onRemoveOutlet();
        });

        this.googleMapsService.centerMap(this.map, this.outlet.geometry.location.lat(), this.outlet.geometry.location.lng());
        this.userSightingOutlet.outletName = this.outlet.name;
        this.userSightingOutlet.streetNumber = this.outlet.address_components.find(ac => ac.types.some(t => t === "street_number"))?.long_name;
        this.userSightingOutlet.street = this.outlet.address_components.find(ac => ac.types.some(t => t === "route"))?.long_name;
        this.userSightingOutlet.zipCode = this.outlet.address_components.find(ac => ac.types.some(t => t === "postal_code"))?.long_name;
        this.userSightingOutlet.townCity = this.outlet.address_components.find(ac => ac.types.some(t => t === "locality"))?.long_name ||
        this.outlet.address_components.find(ac => ac.types.some(t => t === "postal_town"))?.long_name;
        this.userSightingOutlet.province = this.outlet.address_components.find(ac => ac.types.some(t => t === "administrative_area_level_1"))?.long_name;
        this.userSightingOutlet.countryState = this.outlet.address_components.find(ac => ac.types.some(t => t === "country"))?.long_name;
        this.userSightingOutlet.latitude = this.outlet.geometry.location.lat();
        this.userSightingOutlet.longitude = this.outlet.geometry.location.lng();
        this.userSightingOutlet.bookmarked = false;
    }

    public onRemoveOutlet(): void {
        this.outlet = null;

        if (!!this.selectedMarkerOnMap) {
            this.googleMapsService.deleteMarkers([this.selectedMarkerOnMap]);
        }

        this.userSightingOutlet.outletName = null;
        this.userSightingOutlet.streetNumber = null;
        this.userSightingOutlet.street = null;
        this.userSightingOutlet.zipCode = null;
        this.userSightingOutlet.townCity = null;
        this.userSightingOutlet.province = null;
        this.userSightingOutlet.countryState = null;
        this.userSightingOutlet.latitude = null;
        this.userSightingOutlet.longitude = null;
        this.userSightingOutlet.bookmarked = false;
        this.searchOutlets("");
    }

    public removeOutlet(): void {
        this.removeSelectedOutlet();
        this.onRemoveOutlet();
    }

    public placeMarker(location: any) {
        if (this.userMarker == null) {
            this.userMarker = new google.maps.Marker({
                position: location,
                map: this.map,
            });
        }
        else {
            this.userMarker.setPosition(location);
        }
    }

    public removeUserOutlet() {
        this.userMarker.setMap(null);
        this.userMarker = null;
        this.userSightingOutlet.latitude = null;
        this.userSightingOutlet.longitude = null;
        this.userSightingOutlet.bookmarked = false;
    }

    public deleteUserSighting(): void {
        this.$store.dispatch("sighting/deleteUserSighting", this.userSightingId);
        this.$router.push("/");
    }

    public openModal(modalName: string): void {
        this.$modal.show(modalName);
    }

    public closeModal(modalName: string): void {
        this.$modal.hide(modalName);
    }

    public addBottle(): void {
        this.shrinkBottlePanels();
        this.userSightingBottles.push(this.initBottle());
    }

    public get disabledOutletFields(): boolean {
        return this.outlet !== null;
    }

    public deleteBottle(index: number): void {
        this.userSightingBottles.splice(index, 1);
    }

    public initBottle(): IUserSightingBottle {
        return {
            alcoholVolume: null,
            backLabelPartNumber: "",
            brandId: null,
            brand: null,
            caseCode: "",
            decoded: false,
            estimatedQuantity: null,
            importerSubjectId: null,
            lotDate: "",
            lotNumber: "",
            lotTime: "",
            palletCodeSscc: "",
            photos: [],
            brandQualityId: null,
            quality: null,
            quantitySighted: null,
            rspPrice: null,
            size: null,
            expanded: true,
        };
    }

    public addUserSighting(status: RecordStatus): void {
        if (status === RecordStatus.Submitted) {
            this.$v.$touch();
            if (this.$v.$error) {
                window.scrollTo(0, 0);
                return;
            }
        }

        this.userSightingBottles.forEach(bottle => {
            this.$store.dispatch("sighting/addUserSighting", {
                recordStatus: status,
                dateSighted: this.userSightingOutlet.dateSighted,
                outletType: this.userSightingOutlet.outletType,
                marketId: this.userSightingOutlet.marketId,
                outletName: this.userSightingOutlet.outletName,
                streetNumber: this.userSightingOutlet.streetNumber,
                street: this.userSightingOutlet.street,
                zipCode: this.userSightingOutlet.zipCode,
                townCity: this.userSightingOutlet.townCity,
                province: this.userSightingOutlet.province,
                countryState: this.userSightingOutlet.countryState,
                latitude: this.userSightingOutlet.latitude,
                longitude: this.userSightingOutlet.longitude,
                bookmarked: this.userSightingOutlet.bookmarked,
                notes: this.notes,
                photos: bottle.photos,
                brandId: bottle.brandId,
                brandQualityId: bottle.brandQualityId,
                size: bottle.size,
                alcoholVolume: bottle.alcoholVolume,
                importerSubjectId: bottle.importerSubjectId,
                lotNumber: bottle.lotNumber,
                decoded: bottle.decoded,
                lotDate: bottle.lotDate,
                lotTime: bottle.lotTime,
                backLabelPartNumber: bottle.backLabelPartNumber,
                caseCode: bottle.caseCode,
                quantitySighted: bottle.quantitySighted,
                estimatedQuantity: bottle.estimatedQuantity,
                rspPrice: bottle.rspPrice,
                palletCodeSscc: bottle.palletCodeSscc,
                currencyId: this.currencyId,
            });
        });

        this.$router.push("/");
    }

    public updateUserSighting(status: RecordStatus): void {
        if (status === RecordStatus.Submitted) {
            this.$v.$touch();
            if (this.$v.$error) {
                window.scrollTo(0, 0);
                return;
            }
        }
        this.$store.dispatch("sighting/updateUserSighting", {
            userSightingId: this.userSightingId,
            recordStatus: status,
            photos: this.userSightingBottles[0].photos,
            dateSighted: this.userSightingOutlet.dateSighted,
            outletType: this.userSightingOutlet.outletType,
            marketId: this.userSightingOutlet.marketId,
            outletName: this.userSightingOutlet.outletName,
            streetNumber: this.userSightingOutlet.streetNumber,
            street: this.userSightingOutlet.street,
            zipCode: this.userSightingOutlet.zipCode,
            townCity: this.userSightingOutlet.townCity,
            province: this.userSightingOutlet.province,
            countryState: this.userSightingOutlet.countryState,
            latitude: this.userSightingOutlet.latitude,
            longitude: this.userSightingOutlet.longitude,
            bookmarked: this.userSightingOutlet.bookmarked,

            brandId: this.userSightingBottles[0].brandId,
            brandQualityId: this.userSightingBottles[0].brandQualityId,
            size: this.userSightingBottles[0].size,
            alcoholVolume: this.userSightingBottles[0].alcoholVolume,
            importerSubjectId: this.userSightingBottles[0].importerSubjectId,
            lotNumber: this.userSightingBottles[0].lotNumber,
            decoded: this.userSightingBottles[0].decoded,
            lotDate: this.userSightingBottles[0].lotDate,
            lotTime: this.userSightingBottles[0].lotTime,
            backLabelPartNumber: this.userSightingBottles[0].backLabelPartNumber,
            caseCode: this.userSightingBottles[0].caseCode,
            quantitySighted: this.userSightingBottles[0].quantitySighted,
            estimatedQuantity: this.userSightingBottles[0].estimatedQuantity,
            rspPrice: this.userSightingBottles[0].rspPrice,
            palletCodeSscc: this.userSightingBottles[0].palletCodeSscc,
            notes: this.notes,
            currencyId: this.currencyId,
        });
        this.$router.push("/");
    }

    public incomplete(): void {
        this.$store.dispatch("sighting/incompleteUserSighting", this.userSightingId);
        this.$router.push("/");
    }

    public toggleBottleExpands(bottle: IUserSightingBottle): void {
        if (bottle && bottle.expanded) {
            bottle.expanded = false;
        }
        else {
            this.shrinkBottlePanels();
            bottle.expanded = true;
        }
    }

    public shrinkBottlePanels() {
        for (const b of this.userSightingBottles) {
            b.expanded = false;
        }
    }
}
