import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
    name: "bottles",
})
export default class Bottles extends Vue {

    constructor() {
        super();

    }
}
