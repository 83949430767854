import {
    minValue,
    required,
} from "vuelidate/lib/validators";
import { RuleDecl } from "vue/types/options";

export const subjectsValidations: RuleDecl = {
    subject: {
        subjectTypeId: {
            required,
            minValue: minValue(1),
        },
        subjectName: {
            required,
        },
    },
};
