import {
    decimal,
    required,
    requiredIf,
    minValue,
    helpers,
} from "vuelidate/lib/validators";
import { RuleDecl } from "vue/types/options";

const timeValidationRule = helpers.regex("time", /^([0-1][0-9]|[2][0-3]):[0-5][0-9]$/);
const dateValidationRule = helpers.regex("date", /^[0-9]{4}\/([0][0-9]|[1][0-2])\/([0-2][0-9]|[3][0-1])$/);

// custom validator to validate lotNumber field with the brand regex for the lotNumber
const lotNumberValidation = (value, vm) => {
    const regexStr = vm.brand?.regexLotNumber;
    if (regexStr && !vm.decoded) {
        const regex = new RegExp(regexStr);
        return regex.test(value);
    }
    return true;
};

export const userSightingFormValidations: RuleDecl = {
    userSightingOutlet: {
        dateSighted: {
            required,
        },
        outletType: {
            required,
        },
        outletName: {
            required,
        },
        marketId: {
            required,
        },
    },
    userSightingBottles: {
        $each: {
            brandId: {
                required,
            },
            brandQualityId: {
                required,
            },
            size: {
                required,
                decimal,
            },
            alcoholVolume: {
                required,
                decimal,
            },
            lotNumber: {
                requiredIf: requiredIf((vm: any) => {
                    return !vm.decoded;
                }),
                lotNumberValidation,
            },
            decoded: {},
            lotDate: {
                requiredIf: requiredIf((vm: any) => {
                    return !vm.decoded && vm.brand?.dateRequired || false;
                }),
                dateValidationRule,
            },
            lotTime: {
                requiredIf: requiredIf((vm: any) => {
                    return !vm.decoded && vm.brand?.timeRequired || false;
                }),
                timeValidationRule,
            },
            quantitySighted: {
                required,
                minValue : minValue(1),
            },
        },
    },
};
