import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { ICoding } from "@/models";

class CodingService extends ElintApiService {
    public async getAllCodings(): Promise<ICoding[]> {
        try {
            const response = await fetch("/api/codings", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const codings = await response.json();
                return codings;
            }
            else {
                NotificationService.error("Error getting codings !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting codings !");
            return null;
        }
    }
}

const codingService = new CodingService();

export {CodingService, codingService};
