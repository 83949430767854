import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { AzureBlobService } from "@/services";
import { Loader } from "@/components";
import { IMarket, IUserSighting, RecordStatus } from "@/models";
import * as icons from "@/components/shared/icons";
import Paginate from "vuejs-paginate";

@Component({
    name: "user-sighting-dashboard",
    components: {
        Loader,
        "add-icon": icons.AddIcon,
        Paginate,
    },
})
export default class UserSightingDashboard extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public selectedStatus: RecordStatus;
    public azureBlobService: AzureBlobService;
    public key: string;
    public page: number;
    private pageSize = 20;

    public constructor() {
        super();
        this.loadPromises = [];
        this.isLoaded = false;
        this.selectedStatus = null;
        this.azureBlobService = null;
        this.key = null;
        this.page = 1;

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }
        if (!this.$store.state.sighting.userSightings) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchUserSightings"));
        }
        else {
            this.$store.dispatch("sighting/fetchUserSightings");
        }
    }

    public async created(): Promise<void> {
        this.selectedStatus = this.statusFromQuery;
        await Promise.all(this.loadPromises);
        this.isLoaded = true;
        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName, this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
    }

    public get userSightings(): IUserSighting[] {
        return this.$store.getters["sighting/searchUserSightingByStatus"](this.selectedStatus, this.key) ?? [];
    }

    public get allUserSightings(): IUserSighting[] {
        return this.$store.state.sighting.userSightings;
    }

    public get paginatedUserSightings(): IUserSighting[] {
        return this.userSightings.slice((this.page - 1) * this.pageSize, (this.page) * this.pageSize);
    }

    public get totalPages(): number {
        return Math.ceil(this.userSightings?.length / this.pageSize);
    }

    public get statusFromQuery(): RecordStatus {
        if (this.$route.query.status) {
            return parseInt(this.$route.query.status as string, 10);
        }
        return null;
    }

    public get draftCount(): number {
        return this.$store.getters["sighting/getUserSightingsByStatus"](RecordStatus.Draft)?.length ?? 0;
    }

    public get submitedCount(): number {
        return this.$store.getters["sighting/getUserSightingsByStatus"](RecordStatus.Submitted)?.length ?? 0;
    }

    public get requestCount(): number {
        return this.$store.getters["sighting/getUserSightingsByStatus"](RecordStatus.Request)?.length ?? 0;
    }

    public getMarketName(marketId: number): IMarket {
        return this.$store.getters["sighting/getMarket"](marketId)?.name;
    }

    public getRecordStatuslabel(userSighting: IUserSighting): string {
        return RecordStatus[userSighting.recordStatus] ?? "";
    }

    public getPhotosWithFullUrl(photo: string): string {
        if (this.azureBlobService) {
            return this.azureBlobService.getDownloadUrl(photo);
        }
    }

    public canEditUserSighting(userSighting: IUserSighting): boolean {
        return userSighting.recordStatus === RecordStatus.Draft || userSighting.recordStatus === RecordStatus.Request;
    }

    public navigateToPage(page: number): void {
        this.page = page;
    }

    public setStatus(statusId: number): void {
        if (this.selectedStatus !== statusId) {
            this.selectedStatus = statusId;
            this.page = 1;
        }
    }

    @Watch("statusFromQuery")
    public onStatusFromQueryChanged(): void {
        this.selectedStatus = this.statusFromQuery;
    }
}
