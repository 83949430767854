import * as Msal from "msal";
import { cookieService } from "./CookieService";

class AuthService {

    private msalInstance: Msal.UserAgentApplication;
    private request: Msal.AuthenticationParameters;

    public init(msalConfig: Msal.Configuration): void {
        this.request = {
            scopes: [
                msalConfig.auth.clientId,
            ],
        };
        this.msalInstance = new Msal.UserAgentApplication(msalConfig);
        this.msalInstance.handleRedirectCallback((error, response) => {
            if (!error) {
                this.msalInstance.acquireTokenSilent(this.request);
            }
        });
    }

    public login(): void {
        if (this.msalInstance) {
            this.msalInstance.loginRedirect(this.request);
        }
    }

    public logout(): void {
        if (this.msalInstance) {
            this.msalInstance.logout();
        }
    }

    public getAccount(): Msal.Account {
        if (this.msalInstance) {
            return this.msalInstance.getAccount();
        }
        return null;
    }

    public async getAccessToken(): Promise<string> {
        try {
            const response = await this.msalInstance.acquireTokenSilent(this.request);
            if (response?.accessToken !== null) {
                return response.accessToken;
            }
            else {
                cookieService.setCookie("exp.source", window.location.pathname, 300);
                this.msalInstance.acquireTokenRedirect(this.request);
            }
        }
        catch (error) {
            cookieService.setCookie("exp.source", window.location.pathname, 300);
            this.msalInstance.acquireTokenRedirect(this.request);
        }
    }
}

const authService = new AuthService();

export { AuthService, authService };
