import { AnalysisStatus } from "@/models";

/**
 * Display name of the status instead of number
 * @param value
 */
export const analysisStatusFilter = (value: AnalysisStatus) => {
    switch (value) {
        case AnalysisStatus.ReporterRequest:
            return "Reporter Request";
        case AnalysisStatus.BrandCoRequest:
            return "BrandCo Request";
        case AnalysisStatus.MarketCoRequest:
            return "MarketCo Request";
        default:
            return AnalysisStatus[value];
    }
};
