import { GetterTree } from "vuex";
import { ISightingState } from "./state";
import {
    RecordStatus,
    IUserSighting,
    IBrand,
    IBrandQuality,
    ISighting,
    AnalysisStatus,
    IProductionSite,
    ICoding,
    ReporterRequestStatus,
    IReporterRequest,
    ISubject,
    ICurrency,
    IMarket,
    IMarketCompanyCode,
    IBrandCompanyRequest,
    BrandCompanyRequestStatus,
    IMarketCompanyRequest,
    MarketCompanyRequestStatus,
    ISubjectType,
    SubjectType,
    IUserSightingSource,
} from "@/models";

const getters: GetterTree<ISightingState, any> = {
    // User sighting
    getUserSightingsByStatus: (state) => (status: RecordStatus): IUserSighting[] => {
        if (!status) {
            return state.userSightings;
        }
        else {
            return state.userSightings?.filter(userSighting => userSighting.recordStatus === status);
        }
    },

    searchUserSightingByStatus: (state, g) => (status: RecordStatus, key: string): IUserSighting[] => {
        return g.getUserSightingsByStatus(status).filter((userSighting: IUserSighting) => !key ||
            userSighting.quality?.name.toLowerCase().includes(key.toLowerCase()) ||
            userSighting.lotNumber?.toLowerCase().includes(key.toLowerCase()) ||
            userSighting.outletName?.toLowerCase().includes(key.toLowerCase()) ||
            (g.getMarket(userSighting.marketId)?.name.toLowerCase().includes(key.toLowerCase()) ?? false));
    },

    getUserSighting: (state) => (userSightingId: number): IUserSighting => {
        return state.userSightings?.find(userSighting => userSighting.userSightingId === userSightingId);
    },

    getUserSightingSource: (state) => (userSightingSourceId: number): IUserSightingSource => {
        return state.userSightingSources.find(s => s.userSightingSourceId === userSightingSourceId);
    },

    // Sighting
    getSightingQueues: (state) => (): ISighting[] => {
        return state.sightings.filter(sighting => sighting.isInQueue);
    },

    getSightingQueuesByStatus: (state, g) => (status: AnalysisStatus): ISighting[] => {
        if (!status) {
            return g.getSightingQueues();
        }
        else {
            return g.getSightingQueues()?.filter(sighting => sighting.analysisStatus === status);
        }
    },

    searchSightingQueuesByStatus: (state, g) => (status: AnalysisStatus, key: string): ISighting[] => {
        return g.getSightingQueuesByStatus(status)?.filter((sighting: ISighting) => !key ||
            sighting.userSighting.userSightingId.toString().includes(key) ||
            sighting.market.name.toLowerCase().includes(key.toLowerCase()) ||
            sighting.userSighting.user.name.toLowerCase().includes(key.toLowerCase()) ||
            sighting.quality.name.toLowerCase().includes(key.toLowerCase()) ||
            sighting.size.toString().includes(key) ||
            sighting.lotNumber.toLowerCase().includes(key.toLowerCase()));
    },

    getSighting: (state) => (sightingId: number): ISighting => {
        return state.sightings?.find(sighting => sighting.sightingId === sightingId);
    },

    getEditingSighting: (state) => (): ISighting => {
        return state.editingSighting;
    },

    getSightingByStatus: (state, g) => (status: AnalysisStatus): ISighting[] => {
        if (!status) {
            return state.sightings;
        }
        else {
            return state.sightings?.filter(sighting => sighting.analysisStatus === status);
        }
    },

    getSightingsTotal: (state, g) => (): number => {
        return state.totalSightings;
    },

    // Reporter request
    getReporterRequestsByStatus: (state) => (status: ReporterRequestStatus): IReporterRequest[] => {
        if (!status) {
            return state.reporterRequests;
        }
        else {
            return state.reporterRequests?.filter(reporterRequest => reporterRequest.status === status);
        }
    },

    getReporterRequestsBySightingId: (state) => (sightingId: number): IReporterRequest => {
        return state.reporterRequests.find(reporterRequest => reporterRequest.sightingId === sightingId);
    },

    searchReporterRequests: (state, g) => (key: string): IReporterRequest[] => {
        return state.reporterRequests?.filter((reporterRequest: IReporterRequest) => !key ||
            reporterRequest.userSighting.user.name.toLowerCase().includes(key.toLowerCase()) ||
            g.getMarket(reporterRequest.userSighting.marketId).name.toLowerCase().includes(key.toLowerCase()));
    },

    // Brand company request
    getBrandCompanyRequest: (state) => (brandCompanyRequestId: number): IBrandCompanyRequest => {
        return state.brandCompanyRequests.find(brandCompanyRequest => brandCompanyRequest.brandCompanyRequestId === brandCompanyRequestId);
    },

    getBrandCompanyRequestsByStatus: (state) => (status: BrandCompanyRequestStatus): IBrandCompanyRequest[] => {
        if (!status) {
            return state.brandCompanyRequests;
        }
        else {
            return state.brandCompanyRequests?.filter(brandCompanyRequest => brandCompanyRequest.status === status);
        }
    },

    searchBrandCompanyRequests: (state, g) => (key: string): IBrandCompanyRequest[] => {
        return state.brandCompanyRequests?.filter((brandCompanyRequest: IBrandCompanyRequest) => !key ||
                g.getBrandCompany(brandCompanyRequest.brandCompanyId).name.toLowerCase().includes(key.toLowerCase()) ||
                g.getMarketByMarketCompanyId(brandCompanyRequest.affectedMarketCompanyId).name.toLowerCase().includes(key.toLowerCase()) ||
                g.getBrandQuality(brandCompanyRequest.brandId, brandCompanyRequest.brandQualityId).name.toLowerCase().includes(key.toLowerCase()));
    },

    // Market company request
    getMarketCompanyRequest: (state) => (marketCompanyRequestId: number): IMarketCompanyRequest => {
        return state.marketCompanyRequests.find(marketCompanyRequest => marketCompanyRequest.marketCompanyRequestId === marketCompanyRequestId);
    },

    getMarketCompanyRequestsByStatus: (state) => (status: MarketCompanyRequestStatus): IMarketCompanyRequest[] => {
        if (!status) {
            return state.marketCompanyRequests;
        }
        else {
            return state.marketCompanyRequests?.filter(marketCompanyRequest => marketCompanyRequest.status === status);
        }
    },

    searchMarketCompanyRequests: (state, g) => (key: string): IMarketCompanyRequest[] => {
        return state.marketCompanyRequests?.filter((marketCompanyRequest: IMarketCompanyRequest) => !key ||
                g.getBrandCompany(marketCompanyRequest.brandCompanyId).name.toLowerCase().includes(key.toLowerCase()) ||
                g.getMarketByMarketCompanyId(marketCompanyRequest.affectedMarketCompanyId).name.toLowerCase().includes(key.toLowerCase()) ||
                marketCompanyRequest.qualityName.toLowerCase().includes(key.toLowerCase()));
    },

    // [BRAND]
    getBrands: (state) => (): IBrand[] => {
        return state.brands;
    },

    getBrand: (state) => (brandId: number): IBrand => {
        return state.brands?.find(brand => brand.brandId === brandId);
    },

    getBrandQuality: (state) => (brandId: number, brandQualityId: number): IBrandQuality => {
        return state.brands?.find(brand => brand.brandId === brandId)?.qualities?.find(brandQuality =>
            brandQuality.brandQualityId === brandQualityId);
    },

    getBrandsForIds: (state) => (brandIds: number[]): IBrand[] => {
        return state.brands.filter(brand => brandIds.includes(brand.brandId));
    },

    getBrandCompany: (state) => (brandCompanyId: number): ISubject => {
        return state.brandCompanies?.find(brandCompany => brandCompany.subjectId === brandCompanyId);
    },

    // [COMPANIES]
    getCompany: (state) => (subjectId: number): ISubject => {
        return state.companies?.find(s => s.subjectId === subjectId);
    },

    // [CUSTOMERS]
    getCustomer: (state) => (subjectId: number): ISubject => {
        return state.customers?.find(s => s.subjectId === subjectId);
    },

    // [CURRENCIES]
    getCurrency: (state) => (currencyId: number): ICurrency => {
        return state.currencies?.find(currency => currency.currencyId === currencyId);
    },

    // [MARKETS]
    getMarkets: (state) => (): IMarket[] => {
        return state.markets;
    },

    getMarket: (state) => (marketId: number): IMarket => {
        return state.markets?.find(market => market.marketId === marketId);
    },

    getMarketByMarketCompanyId: (state) => (marketCompanyId: number): IMarket => {
        return state.markets?.find(market => market.marketCompanyId === marketCompanyId);
    },

    getMarketCompany: (state) => (marketCompanyId: number): ISubject => {
        return state.subjects?.find(s => s.subjectId === marketCompanyId && s.subjectTypeId === SubjectType.MarketCompany);
    },

    getMarketCompanyCode: (state) => (marketCompanyCodeId: number): IMarketCompanyCode => {
        return state.marketCompanyCodes?.find(marketCompanyCode => marketCompanyCode.marketCompanyCodeId === marketCompanyCodeId);
    },

    // [PRODUCTIONSITES]
    getProductionSites: (state) => (): IProductionSite[] => {
        return state.productionSites;
    },

    // [CODINGS]
    getCodings: (state) => (): ICoding[] => {
        return state.codings;
    },

    getCoding: (state) => (codingId: number): ICoding => {
        return state.codings.find(coding => coding.codingId === codingId);
    },

    // [SUBJECT TYPES]
    getSubjectType: (state) => (subjectTypeId: number): ISubjectType => {
        return state.subjectTypes?.find(subjectType => subjectType.subjectTypeId === subjectTypeId);
    },

    // [SUBJECTS]
    getSubjectsByType: (state) => (subjectTypeId: number): ISubjectType[] => {
        return state.subjects?.filter(s => s.subjectTypeId === subjectTypeId) ?? [];
    },
};

export { getters };
