import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { ISubject, ISearchSubject } from "@/models";

class SubjectService extends ElintApiService {
    public async addSubject(data): Promise<number> {
        let subjectId: Promise<number> = null;

        try {
            const response = await fetch("/api/subjects/add", {
                method: "POST",
                headers: await this.getHeaders(),
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Subject added !");
                subjectId = response.text().then(t => parseInt(t, 10));
            }
            else {
                NotificationService.error("Error adding subject !");
            }
        }
        catch (error) {
            NotificationService.error("Exception adding subject !");
        }

        return subjectId;
    }

    public async searchSubjects(query: string): Promise<ISearchSubject[]> {
        if (query == null || query === "") {
            return [];
        }

        try {
            const response = await fetch(encodeURI(`/api/subjects/SearchSubjectsByName/${query}`), {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const subjectTypes = await response.json();
                return subjectTypes;
            }
            else {
                NotificationService.error("Error getting search subjects !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting search subjects !");
            return [];
        }
    }

    public async getSubject(subjectId: number): Promise<ISubject> {
        if (!subjectId) {
            return null;
        }

        try {
            const response = await fetch(encodeURI(`/api/subjects/${subjectId}`), {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const subject = await response.json();
                return subject;
            }
            else {
                NotificationService.error("Error getting subject !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting subject !");
            return null;
        }
    }

    public async getAllSubjects(): Promise<ISubject[]> {
        try {
            const response = await fetch(`/api/subjects`, {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const subjectTypes = await response.json();
                return subjectTypes;
            }
            else {
                NotificationService.error("Error getting subjects !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting subjects !");
            return [];
        }
    }

    public async getSubjectsByType(subjectTypeId: number): Promise<ISubject[]> {
        if (!subjectTypeId) {
            return null;
        }

        try {
            const response = await fetch(encodeURI(`/api/subjects/type/${subjectTypeId}`), {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const subjectTypes = await response.json();
                return subjectTypes;
            }
            else {
                NotificationService.error("Error getting subjects !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting subjects !");
            return [];
        }
    }

    public async getSubjectJournalKeywords(): Promise<string[]> {
        try {
            const response = await fetch(encodeURI(`/api/subjects/journal/keywords`), {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                return await response.json();
            }
            else {
                NotificationService.error("Error getting journal keywords !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting journal keywords !");
            return [];
        }
    }

    public async updateSubject(data): Promise<void> {
        try {
            const response = await fetch("/api/subjects/" + data.subjectId + "/update", {
                method: "POST",
                headers: await this.getHeaders(),
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Subject saved !");
            }
            else {
                NotificationService.error("Error saving subject !");
            }
        }
        catch (error) {
            NotificationService.error("Exception saving subject !");
        }
    }

    public async deleteSubject(data): Promise<boolean> {
        try {
            const response = await fetch("/api/subjects/" + data, {
                method: "DELETE",
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                NotificationService.success("Subject deleted !");
                return true;
            }
            else {
                NotificationService.error("Error deleting subject !");
                return false;
            }
        }
        catch {
            NotificationService.error("Exception deleting subjects !");
            return false;
        }
    }

    public async getSubjectByReferenceNumber(brandCompanyId: number, referenceNumber: string): Promise<ISubject> {
        try {
            const response = await fetch(`/api/subjects/referencenumber/${brandCompanyId}/${referenceNumber}`, {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                if (response.headers.get("Content-Length") === "0") {
                    return null;
                }
                const subject = await response.json();
                return subject;
            }
            else {
                NotificationService.error("Error getting subject !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting subject !");
            return null;
        }
    }
}

const subjectService = new SubjectService();

export { SubjectService, subjectService };
