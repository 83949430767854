import Vue from "vue";
import Vuex from "vuex";
import { app } from "./app";
import { sighting } from "./sighting";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        app,
        sighting,
    },
});

export { store };
