import {
    required,
} from "vuelidate/lib/validators";
import { RuleDecl } from "vue/types/options";

export const usersValidations: RuleDecl = {
    termsAndConditionsAccepted: {
        required,
        checked: value => value === true,
    },
};
