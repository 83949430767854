import { required } from "vuelidate/lib/validators";
import { RuleDecl } from "vue/types/options";
import Brands from "./Brands";

export const brandValidations: RuleDecl = {
    brandName: {
        required,
    },
    brandCompanyId: {
        required,
    },
    selectedBrand: {
        name: {
            required,
        },
    },
    selectedBrandQuality: {
        name: {
            required,
        },
    },
};
