import {
    IUserSighting,
    ISighting,
    IBrand,
    IProductionSite,
    ICoding,
    IReporterRequest,
    ICurrency,
    IMarket,
    IMarketCompanyCode,
    IBrandCompanyRequest,
    IMarketCompanyRequest,
    ISubjectType,
    ISubject,
    IUserSightingOutlet,
    IUserSightingSource,
    ISightingPreviousValues,
 } from "@/models";

interface ISightingState {
    userSightings: IUserSighting[];
    userBookmarkedOutlets: IUserSightingOutlet[];

    sightings: ISighting[];
    totalSightings: number;
    editingSighting: ISighting;
    editingSightingPreviousValues: ISightingPreviousValues;

    reporterRequests: IReporterRequest[];
    brandCompanyRequests: IBrandCompanyRequest[];
    marketCompanyRequests: IMarketCompanyRequest[];
    userSightingSources: IUserSightingSource[];
    currencies: ICurrency[];

    brands: IBrand[];
    brandCompanies: ISubject[];
    companies: ISubject[];
    customers: ISubject[];
    markets: IMarket[];
    marketCompanies: ISubject[];
    marketCompanyCodes: IMarketCompanyCode[];
    productionSites: IProductionSite[];
    codings: ICoding[];

    subjectTypes: ISubjectType[];
    subject: ISubject;
    subjects: ISubject[];
    subjectJournalKeywords: string[];
}

const initialState: ISightingState = {
    userSightings: null,
    userBookmarkedOutlets: [],

    sightings: null,
    totalSightings: 0,
    editingSighting: null,
    editingSightingPreviousValues: null,

    reporterRequests: null,
    brandCompanyRequests: null,
    marketCompanyRequests: null,
    userSightingSources: [],

    brands: null,
    brandCompanies: null,
    companies: [],
    customers: [],
    currencies: null,
    markets: null,
    marketCompanies: null,
    marketCompanyCodes: null,
    productionSites: null,
    codings: null,

    subjectTypes: null,

    subject: null,
    subjects: null,

    subjectJournalKeywords: [],
};

export { ISightingState, initialState };
