import { NavigationGuard } from "vue-router";
import { store } from "@/store";

export const profileGuard: NavigationGuard = async (to, from, next) => {
    if (!store.state.app.loaded) {
        await store.dispatch("app/load");
    }
    if (store.getters["app/isAuthenticated"] && to.path !== "/my-profile" &&
        (!store.state.app.userProfile.marketId ||
            !store.state.app.userProfile.currencyId ||
            !store.state.app.userProfile.termsAndConditionsAccepted)) {
            next("/my-profile");
    }
    else {
        next();
    }
};
