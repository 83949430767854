import { MutationTree } from "vuex";
import {
    SET_USER_SIGHTINGS,
    SET_USER_BOOKMARKED_OUTLETS,

    SET_SIGHTINGS,
    SET_SIGHTINGS_TOTAL,
    SET_EDITING_SIGHTING,
    SET_EDITING_SIGHTING_PREVIOUS_VALUES,

    SET_REPORTER_REQUESTS,
    SET_MARKET_COMPANY_REQUESTS,

    SET_USER_SIGHTING_SOURCES,

    SET_BRANDS,
    SET_BRAND_QUALITIES,
    SET_COMPANIES,
    SET_CUSTOMERS,

    SET_PRODUCTIONSITES,
    SET_CODINGS,
    SET_BRAND_COMPANIES,
    SET_CURRENCIES,
    SET_MARKETS,
    SET_MARKET_COMPANIES,
    SET_MARKET_COMPANY_CODES,
    SET_BRAND_COMPANY_REQUESTS,

    SET_SUBJECT_TYPES,
    SET_SUBJECT,
    SET_SUBJECTS,
    SET_SUBJECT_JOURNAL_KEYWORDS,
    REMOVE_DELETED_SUBJECT,
} from "./mutations-types";
import { ISightingState } from "./state";
import {
    IUserSighting,
    IBrand,
    ISighting,
    IProductionSite,
    ICoding,
    IReporterRequest,
    ICurrency,
    IMarket,
    IMarketCompanyCode,
    IBrandCompanyRequest,
    IMarketCompanyRequest,
    ISubjectType,
    ISubject,
    IUserSightingOutlet,
    IUserSightingSource,
} from "@/models";

export const mutations: MutationTree<ISightingState> = {

    // [SIGHTING]
    [SET_USER_SIGHTINGS](state, payload: IUserSighting[]): void {
        state.userSightings = payload;
    },

    [SET_USER_BOOKMARKED_OUTLETS](state, payload: IUserSightingOutlet[]): void {
        state.userBookmarkedOutlets = payload;
    },

    [SET_SIGHTINGS](state, payload: ISighting[]): void {
        state.sightings = payload;
    },

    [SET_SIGHTINGS_TOTAL](state, payload: number): void {
        state.totalSightings = payload;
    },

    [SET_EDITING_SIGHTING](state, payload: ISighting): void {
        state.editingSighting = payload;
    },

    [SET_EDITING_SIGHTING_PREVIOUS_VALUES](state, payload: any): void {
        state.editingSightingPreviousValues = payload;
    },

    [SET_REPORTER_REQUESTS](state, payload: IReporterRequest[]): void {
        state.reporterRequests = payload;
    },

    [SET_BRAND_COMPANY_REQUESTS](state, payload: IBrandCompanyRequest[]): void {
        state.brandCompanyRequests = payload;
    },

    [SET_MARKET_COMPANY_REQUESTS](state, payload: IMarketCompanyRequest[]): void {
        state.marketCompanyRequests = payload;
    },

    [SET_USER_SIGHTING_SOURCES](state, payload: IUserSightingSource[]): void {
        state.userSightingSources = payload;
    },

    // [BRAND]
    [SET_BRANDS](state, payload: IBrand[]): void {
        state.brands = payload;
    },

    [SET_BRAND_QUALITIES](state, payload): void {
        if (state.brands.length !== 0) {
            state.brands.find(brand => brand.brandId === payload.brandId).qualities = payload.brandQualities;
        }
    },

    [SET_BRAND_COMPANIES](state, payload: ISubject[]): void {
        state.brandCompanies = payload;
    },

    // Customers
    [SET_COMPANIES](state, payload: ISubject[]): void {
        state.companies = payload;
    },

    // Customers
    [SET_CUSTOMERS](state, payload: ISubject[]): void {
        state.customers = payload;
    },

    // [CURRENCIES]
    [SET_CURRENCIES](state, payload: ICurrency[]): void {
        state.currencies = payload;
    },

    // [MARKETS]
    [SET_MARKETS](state, payload: IMarket[]): void {
        state.markets = payload;
    },

    [SET_MARKET_COMPANIES](state, payload: ISubject[]): void {
        state.marketCompanies = payload;
    },

    [SET_MARKET_COMPANY_CODES](state, payload: IMarketCompanyCode[]): void {
        state.marketCompanyCodes = payload;
    },

    // [PRODUCTIONSITES]
    [SET_PRODUCTIONSITES](state, payload: IProductionSite[]): void {
        state.productionSites = payload;
    },

    // [CODINGS]
    [SET_CODINGS](state, payload: ICoding[]): void {
        state.codings = payload;
    },

    // [SUBJECTS]
    [SET_SUBJECT_TYPES](state, payload: ISubjectType[]): void {
        state.subjectTypes = payload;
    },

    [SET_SUBJECT](state, payload: ISubject): void {
        state.subject = payload;
    },

    [SET_SUBJECTS](state, payload: ISubject[]): void {
        if (payload?.length > 0) {
            const subjectIds: number[] = payload.map(p => p.subjectId);
            const subjects: ISubject[] = state.subjects?.filter(s => !subjectIds.some(i => i === s.subjectId)) ?? [];
            state.subjects = subjects.concat(payload);
        }

        state.subjects = payload;
    },

    [SET_SUBJECT_JOURNAL_KEYWORDS](state, payload: string[]): void {
        state.subjectJournalKeywords = payload ?? [];
    },

    [REMOVE_DELETED_SUBJECT](state, payload: number): void {
        state.subjects = state.subjects?.filter(s => s.subjectId !== payload) ?? [];
    },
};
