import Vue from "vue";
import { Component } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import { Loader } from "@/components";
import * as icons from "@/components/shared/icons";
import VueEasyLightbox from "vue-easy-lightbox";
import {
    IMarketCompanyRequest,
    IBrand,
    ISighting,
    IMarketCompanyRequestFile,
} from "@/models";
import { AzureBlobService } from "@/services";

@Component({
    name: "market-company-request-form",
    components: {
        "datepicker": Datepicker,
        Loader,
        VueEasyLightbox,
        "add-icon": icons.AddIcon,
        "upload-icon": icons.UploadIcon,
    },
})
export default class MarketCompanyRequestForm extends Vue {

    public uploadedFile: File;
    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public ordersInfo: any[];
    public customersInfo: any[];
    public loading: boolean;
    public downloadLink: string;
    public dragging: boolean;
    public azureBlobService: AzureBlobService;
    public files: IMarketCompanyRequestFile[];

    constructor() {
        super();

        this.loadPromises = [];
        this.files = [];
        this.isLoaded = false;
        this.ordersInfo = [];
        this.customersInfo = [];
        this.loading = false;
        this.uploadedFile = null;
        this.downloadLink = null;
        this.dragging = false;
        this.azureBlobService = null;

        if (!this.$store.state.sighting.marketCompanyRequests) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchMarketCompanyRequests"));
        }
        else {
            this.$store.dispatch("sighting/fetchMarketCompanyRequests");
        }

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }

        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName, this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
    }

    public onDragEnter(event: DragEvent): void {
        this.dragging = true;
    }

    public onDragLeave(event: DragEvent): void {
        this.dragging = false;
    }

    public async onDropFiles(event: DragEvent): Promise<void> {
        this.dragging = false;
        this.loading = true;

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
            await this.uploadFile(event.dataTransfer.files[i]);
        }

        this.loading = false;
    }

    public async uploadFile(file: File): Promise<void> {
        const metadata = {
            name: file.name,
        };
        const link = await this.azureBlobService.uploadFile(file, "marketCompanyRequest", metadata);

        this.files.push({ name: file.name, link });
    }

    public deleteFile() {
        this.files = [];
        this.uploadedFile = null;
    }

    public get brandQuality(): IBrand {
        return this.$store.getters["sighting/getBrandQuality"](this.marketCompanyRequest?.brandId, this.marketCompanyRequest?.brandQualityId);
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        if (this.marketCompanyRequest) {
            this.ordersInfo = JSON.parse(this.marketCompanyRequest.ordersInfo) || [];
            this.customersInfo = JSON.parse(this.marketCompanyRequest.customersInfo) || [];
            this.files = JSON.parse(this.marketCompanyRequest.filesInfo) || [];
        }
        this.isLoaded = true;
    }

    public get marketCompanyRequestId(): number {
        return this.$route.params.marketCompanyRequestId ? parseInt(this.$route.params.marketCompanyRequestId, 10) : null;
    }

    public get marketCompanyRequest(): IMarketCompanyRequest {
        if (this.marketCompanyRequestId) {
            return this.$store.getters["sighting/getMarketCompanyRequest"](this.marketCompanyRequestId);
        }
        return null;
    }

    public answerMarketCompanyRequest(isShipmentInfoAvailable: boolean): void {
        this.$store.dispatch("sighting/answerMarketCompanyRequests", {
            marketCompanyRequestId: this.marketCompanyRequestId,
            isShipmentInfoAvailable,
            customersInfo: JSON.stringify(this.customersInfo),
            filesInfo: JSON.stringify(this.files),
        });
        this.$router.push("/");
    }
}
