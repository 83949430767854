import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IBrandCompanyRequest } from "@/models";

class BrandCompanyRequestService extends ElintApiService {

    public async getAllBrandCompanyRequests(): Promise<IBrandCompanyRequest[]> {
        try {
            const response = await fetch("/api/brandcompanyrequests", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const brandCompanyRequests = await response.json();
                return brandCompanyRequests;
            }
            else {
                NotificationService.error("Error getting brand company requests !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting brand company requests !");
        }
    }

    public async getActiveBrandCompanyRequests(): Promise<IBrandCompanyRequest[]> {
        try {
            const response = await fetch("/api/brandcompanyrequests/active", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const brandCompanyRequests = await response.json();
                return brandCompanyRequests;
            }
            else {
                NotificationService.error("Error getting brand company requests !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting brand company requests !");
        }
    }

    public async addBrandCompanyRequest(data): Promise<void> {
        try {
            const response = await fetch("/api/brandCompanyRequests", {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Brand Company request added !");
            }
            else {
                NotificationService.error("Error adding brand company request !");
            }
        }
        catch (error) {
            NotificationService.error("Exception adding brand company request !");
        }
    }

    public async sendBrandCompanyRequests(brandCompanyRequestIds: number[]) {
        try {
            const response = await fetch("/api/brandCompanyRequests/send", {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(brandCompanyRequestIds),
            });
            if (response.ok) {
                NotificationService.success("Brand company requests sent !");
            }
            else {
                NotificationService.error("Error sending brand company requests !");
            }
        }
        catch (error) {
            NotificationService.error("Exception sending brand company requests !");
        }
    }

    public async answerBrandCompanyRequests(data: any) {
        try {
            const response = await fetch(`/api/brandCompanyRequests/${data.brandCompanyRequestId}/answer`, {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Brand company request answered !");
            }
            else {
                NotificationService.error("Error answering brand company request !");
            }
        }
        catch (error) {
            NotificationService.error("Exception answering brand company request !");
        }
    }
}

const brandCompanyRequestService = new BrandCompanyRequestService();

export {BrandCompanyRequestService, brandCompanyRequestService};
