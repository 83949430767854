import { NavigationGuard } from "vue-router";
import { store } from "@/store";

export const roleGuard: NavigationGuard = async (to, from, next) => {
    const skipGuard = to.matched.some(route => route.meta.skipGuard);

    if (!skipGuard) {
        if (!store.state.app.loaded) {
            await store.dispatch("app/load");
        }
        const roleLevelRequirement = to.matched.find(route => route.meta.roleLevelRequirement)?.meta.roleLevelRequirement;

        if (roleLevelRequirement && store.state.app.userRole < roleLevelRequirement) {
            next("/");
        }
        else {
            next();
        }
    }
    else {
        next();
    }
};
