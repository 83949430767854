import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IMarketCompanyRequest, IBrand, IBrandCompany } from "@/models";
import { Loader } from "@/components";

@Component({
    name: "market-company-request-dashboard",
    components: {
        Loader,
    },
})
export default class MarketCompanyRequestDashboard extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public key: string;

    public constructor() {
        super();
        this.loadPromises = [];
        this.isLoaded = false;
        this.key = null;

        if (!this.$store.state.sighting.marketCompanyRequests) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchMarketCompanyRequests"));
        }
        else {
            this.$store.dispatch("sighting/fetchBrandCompanyRequests");
        }
        if (!this.$store.state.sighting.brandCompanies) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrandCompanies"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.isLoaded = true;
    }

    public get marketCompanyRequests(): IMarketCompanyRequest[] {
        return this.$store.getters["sighting/searchMarketCompanyRequests"](this.key) ?? [];
    }

    public getBrand(brandId: number): IBrand {
        return this.$store.getters["sighting/getBrand"](brandId);
    }

    public getBrandOwner(brand: IBrand): IBrandCompany {
        return this.$store.getters["sighting/getBrandCompany"](brand.brandCompanyId);
    }

    public getBrandQuality(brandId: number, brandQualityId: number): IBrand {
        return this.$store.getters["sighting/getBrandQuality"](brandId, brandQualityId);
    }

    public getBrandCompany(brandCompanyId: number): IBrandCompany {
        return this.$store.getters["sighting/getBrandCompany"](brandCompanyId);
    }
}
