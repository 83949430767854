// [SIGHTING]
const SET_USER_SIGHTINGS = "SET_USER_SIGHTINGS";
const SET_USER_BOOKMARKED_OUTLETS = "SET_USER_BOOKMARKED_OUTLETS";

const SET_SIGHTINGS = "SET_SIGHTINGS";
const SET_SIGHTINGS_TOTAL = "SET_SIGHTINGS_TOTAL";
const SET_EDITING_SIGHTING = "SET_EDITING_SIGHTING";
const SET_EDITING_SIGHTING_PREVIOUS_VALUES = "SET_EDITING_SIGHTING_PREVIOUS_VALUES";

const SET_REPORTER_REQUESTS = "SET_REPORTER_REQUESTS";
const SET_BRAND_COMPANY_REQUESTS = "SET_BRAND_COMPANY_REQUESTS";
const SET_MARKET_COMPANY_REQUESTS = "SET_MARKET_COMPANY_REQUESTS";

const SET_USER_SIGHTING_SOURCES = "SET_USER_SIGHTING_SOURCES";

// [BRANDS]
const SET_BRANDS = "SET_BRANDS";
const SET_BRAND_QUALITIES = "SET_BRAND_QUALITIES";
const SET_BRAND_COMPANIES = "SET_BRAND_COMPANIES";

// [SET_COMPANIES].
const SET_COMPANIES = "SET_COMPANIES";

// Customers
const SET_CUSTOMERS = "SET_CUSTOMERS";

// [CURRENCIES]
const SET_CURRENCIES = "SET_CURRENCIES";

// [MARKETS]
const SET_MARKETS = "SET_MARKETS";
const SET_MARKET_COMPANIES = "SET_MARKET_COMPANIES";
const SET_MARKET_COMPANY_CODES = "SET_MARKET_COMPANY_CODES";

// [PRODUCTIONSITES]
const SET_PRODUCTIONSITES = "ADD_PRODUCTIONSITES";

// [CODINGS]
const SET_CODINGS = "SET_CODINGS";

// [SUBJECT]
const SET_SUBJECT_TYPES = "SET_SUBJECT_TYPES";
const SET_SUBJECT = "SET_SUBJECT";
const SET_SUBJECTS = "SET_SUBJECTS";
const SET_SUBJECT_JOURNAL_KEYWORDS = "SET_SUBJECT_JOURNAL_KEYWORDS";
const REMOVE_DELETED_SUBJECT = "REMOVE_DELETED_SUBJECT";

export {
    SET_USER_SIGHTINGS,
    SET_USER_BOOKMARKED_OUTLETS,

    SET_SIGHTINGS,
    SET_SIGHTINGS_TOTAL,
    SET_EDITING_SIGHTING,
    SET_EDITING_SIGHTING_PREVIOUS_VALUES,

    SET_REPORTER_REQUESTS,
    SET_BRAND_COMPANY_REQUESTS,
    SET_MARKET_COMPANY_REQUESTS,

    SET_USER_SIGHTING_SOURCES,

    SET_BRANDS,
    SET_BRAND_QUALITIES,
    SET_COMPANIES,
    SET_CUSTOMERS,
    SET_BRAND_COMPANIES,
    SET_CURRENCIES,
    SET_MARKETS,
    SET_MARKET_COMPANIES,
    SET_MARKET_COMPANY_CODES,
    SET_PRODUCTIONSITES,
    SET_CODINGS,

    SET_SUBJECT_TYPES,
    SET_SUBJECT,
    SET_SUBJECTS,
    SET_SUBJECT_JOURNAL_KEYWORDS,
    REMOVE_DELETED_SUBJECT,
};
