import Vue from "vue";
import { Component } from "vue-property-decorator";
import { SidebarMenu } from "vue-sidebar-menu";
import { IUser, Role } from "@/models";
import * as icons from "@/components/shared/icons";
@Component({
    name: "app",
    components: {
        SidebarMenu,
        "logout-icon": icons.LogoutIcon,
    },
})
export default class App extends Vue {
    public loadPromises: Array<Promise<any>>;

    constructor() {
        super();

        this.loadPromises = [];
    }

    public get isAuthenticated(): boolean {
        return this.$store.getters["app/isAuthenticated"];
    }

    public get isLoaded(): boolean {
        return this.$store.state.app.loaded;
    }

    public get userAccount(): IUser {
        return this.$store.state.app.userAccount;
    }

    public get menuItems(): any[] {
        return [{
            header: true,
            hiddenOnCollapse: true,
        }].concat(this.$store.getters["app/menuItems"]);
    }

    public logout(): void {
        this.$store.dispatch("app/logout");
    }

    public get isUser(): boolean {
        return this.$store.state.app.userRole === Role.User;
    }

    public get isAdmin(): boolean {
        return this.$store.state.app.userRole === Role.Administrator;
    }

    public get isAtLeastTraceTeam(): boolean {
        return this.$store.state.app.userRole >= Role.TraceTeam;
    }

    public get isAtLeastBSTeam(): boolean {
        return this.$store.state.app.userRole >= Role.BSTeam;
    }
}
