export enum AnalysisStatus {
    Research = 1,
    ReporterRequest = 2,
    BrandCoRequest = 3,
    Review = 4,
    MarketCoRequest = 5,
    Decoded = 6,
    Incomplete = 7,
    Complete = 8,
}
