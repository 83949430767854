import Vue from "vue";
import { Component } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import { subjectService, NotificationService, AzureBlobService } from "@/services";
import { Loader } from "@/components";
import {
    ISearchSubject,
    ISubject,
    ISubjectPersonnel,
    ISubjectJournal,
    IProductionSite,
    ISubjectRelation,
    SubjectType,
    IMarket,
    ISubjectJournalFile,
    SubjectRelationType,
} from "@/models";
import { subjectFormValidations } from "./SubjectFormValidations";
import * as icons from "@/components/shared/icons";
import PeoplePicker from "@/components/shared/peoplePicker/PeoplePicker.vue";

@Component({
    name: "subject-form",
    components: {
        Loader,
        Multiselect,
        "edit-icon": icons.EditIcon,
        "email-icon": icons.EmailIcon,
        "delete-icon": icons.GarbageIcon,
        "people-picker": PeoplePicker,
        "upload-icon": icons.UploadIcon,
    },
    validations: subjectFormValidations,
})
export default class SubjectForm extends Vue {
    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;

    public readonly actionAdd: string = "Add";
    public readonly actionEdit: string = "Edit";
    public readonly actionDelete: string = "Delete";

    public searchSubjectsLoading: boolean;
    public searchSubjects: ISearchSubject[];
    public searchSubjectsTimeout: any;
    public searchSubject: ISearchSubject;

    public selectedTab: number;

    public subject: ISubject;
    public relation: ISubjectRelation;

    public editIndex: number;

    public selectedMarkets: IMarket[];
    public markets: IMarket[];

    public selectedSubjectXRefs: ISubject[];
    public selectedXRefRelations: ISubjectRelation[];
    public selectedAffiliates: ISubject[];
    public selectedAffiliateRelations: ISubjectRelation[];
    public subjects: ISubject[];

    public editSubjectName: boolean;

    // Personnels
    public readonly personnelModal: string = "personnelModal";
    public readonly personnelDeleteModal: string = "personnelDeleteModal";
    public selectedPersonnel: ISelectedPersonnel;

    // Production site
    public readonly productionSiteModal: string = "productionSiteModal";
    public readonly productionSiteDeleteModal: string = "productionSiteDeleteModal";
    public readonly marketOfOperationsModal: string = "marketOfOperationsModal";
    public readonly deleteMarketOfOperationsModal: string = "deleteMarketOfOperationsModal";
    public selectedSubjects: ISubject[];

    public readonly subjectXRefsModal: string = "subjectXRefsModal";
    public readonly deleteSubjectXRefsModal: string = "deleteSubjectXRefsModal";

    public readonly affiliatesModal: string = "affiliatesModal";
    public readonly deleteAffiliatesModal: string = "deleteAffiliatesModal";

    // Journals
    public searchJournalKeywords: string[];
    public readonly journalModal: string = "journalModal";
    public readonly journalDeleteModal: string = "journalDeleteModal";
    public selectedJournal: ISelectedJournal;

    public azureBlobService: AzureBlobService;

    private extensionRegexp = new RegExp("(?:\.([^.]+))?$");

    constructor() {
        super();

        this.selectedMarkets = null;
        this.selectedSubjectXRefs = [];
        this.selectedXRefRelations = [];
        this.selectedAffiliates = [];
        this.selectedAffiliateRelations = [];

        this.loadPromises = [];
        this.isLoaded = false;

        this.searchSubjectsLoading = false;
        this.searchSubjects = [];
        this.searchSubjectsTimeout = null;
        this.searchSubject = null;

        this.selectedTab = 1;
        this.editIndex = null;

        this.subjects = [];

        this.editSubjectName = false;

        // Production site

        // Personnel
        this.selectedPersonnel = null;
        this.selectedSubjects = null;
        this.subject = null;
        this.relation = null;

        // Journal
        this.searchJournalKeywords = [];
        this.selectedJournal = null;
    }

    public async created(): Promise<void> {
        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName,
            this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);

        if (!this.$store.state.sighting.subject || this.$store.state.sighting.subject.subjectId) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchSubject", this.$route.params.subjectId));
        }

        if (!this.$store.state.subjectJournalKeywords) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchSubjectJournalKeywords"));
        }

        this.loadPromises.push(this.$store.dispatch("sighting/fetchSubjects"));

        await Promise.all(this.loadPromises);

        this.subject = this.$store.state.sighting.subject || null;

        if (!this.subject) {
            this.$router.push("/subjects");
        }

        this.markets = this.$store.state.sighting.markets;
        this.subjects = this.$store.state.sighting.subjects;
        this.initDetailsIfNeeded();

        this.isLoaded = true;
    }

    public onMarketSelected(market: IMarket): void {
        this.selectedMarkets.push(market);
    }

    public addMarketOfOperations(): void {
        for (const selectedMarket of this.selectedMarkets) {
            for (const details of this.subject.details.marketsOfOperation) {
                if (details.name === selectedMarket.name) {
                    return;
                }
            }
        }

        for (const selected of this.selectedMarkets) {
            this.subject.details.marketsOfOperation.push(selected);
        }
        this.selectedMarkets = null;
        this.$modal.hide(this.marketOfOperationsModal);
    }

    public deleteMarketOfOperations(): void {
        for (const element of this.selectedMarkets) {
            this.subject.details.marketsOfOperation = this.subject.details.marketsOfOperation.filter(obj => obj !== element);
        }
        this.selectedMarkets = null;
        this.$modal.hide(this.deleteMarketOfOperationsModal);
    }

    public get getMarketsOfOperation(): string {
        let markets: string = "";
        let count: number = 1;

        this.subject.details.marketsOfOperation.forEach((market) => {
            let separator = ",";
            if (count === this.subject.details.marketsOfOperation.length) {
                separator = "";
            }

            count++;
            markets += `${market.name}${separator} `;
        });

        return markets;
    }

    public addSubjectXRef(): void {
        for (const xref of this.selectedSubjectXRefs) {
            if (this.subject.relations.findIndex(s => s.subjectRelationTypeId === SubjectRelationType.XRef && (s.subjectFromId === xref.subjectId || s.subjectToId === xref.subjectId)) < 0) {
                this.subject.relations.push(this.subjectToRelation(xref, SubjectRelationType.XRef));
            }
        }

        this.selectedSubjectXRefs = [];
        this.$modal.hide(this.subjectXRefsModal);
    }

    public deleteSubjectXRef(): void {
        for (const xref of this.selectedXRefRelations) {
            this.subject.relations = this.subject.relations.filter(s =>
                s.subjectRelationTypeId !== SubjectRelationType.XRef ||
                s.subjectToId !== xref.subjectToId);
        }

        this.selectedXRefRelations = [];
        this.$modal.hide(this.deleteSubjectXRefsModal);
    }

    public addAffiliate(): void {
        for (const aff of this.selectedAffiliates) {
            if (this.subject.relations.findIndex(s => s.subjectRelationTypeId === SubjectRelationType.Affiliate && s.subjectToId === aff.subjectId) < 0) {
                this.subject.relations.push(this.subjectToRelation(aff, SubjectRelationType.Affiliate));
            }
        }

        this.selectedAffiliates = null;
        this.$modal.hide(this.affiliatesModal);
    }

    public deleteAffiliate(): void {
        for (const aff of this.selectedAffiliateRelations) {
            this.subject.relations = this.subject.relations.filter(s =>
                s.subjectRelationTypeId !== SubjectRelationType.Affiliate ||
                s.subjectToId !== aff.subjectToId);
        }

        this.selectedAffiliateRelations = [];
        this.$modal.hide(this.deleteAffiliatesModal);
    }

    public get productionSites(): IProductionSite[] {
        const ids: number[] = this.subject?.relations?.filter(r =>
            r.subjectToTypeId === SubjectType.ProductionSite &&
            r.subjectRelationTypeId === SubjectRelationType.ProductionSite).map(r => r.subjectToId) ?? [];

        return this.$store.getters["sighting/getSubjectsByType"](SubjectType.ProductionSite).filter(s => !ids.includes(s.subjectId));
    }

    public get availableXRefs(): ISubject[] {
        const ids: number[] = this.subject?.relations?.filter(r => r.subjectRelationTypeId === SubjectRelationType.XRef).map(r => r.subjectToId) ?? [];
        return this.subjects?.filter(s => s.subjectId !== this.subject.subjectId && !ids.includes(s.subjectId)) ?? [];
    }

    public get availableAffiliates(): ISubject[] {
        const ids: number[] = this.subject?.relations?.filter(r => r.subjectRelationTypeId === SubjectRelationType.Affiliate).map(r => r.subjectToId) ?? [];
        return this.subjects?.filter(s => s.subjectId !== this.subject.subjectId && !ids.includes(s.subjectId)) ?? [];
    }

    public get keywords(): string[] {
        let kw: string[] = this.subject?.journals?.map(j => j.keywords).reduce((x, y) => x.concat(y), []) ?? [];
        kw = kw.concat(this.$store.state.sighting.subjectJournalKeywords);
        return this.cleanArray(kw);
    }

    public onSearchSubjectChanged(query) {
        if (this.searchSubjectsTimeout) {
            clearTimeout(this.searchSubjectsTimeout);
        }
        this.searchSubjectsTimeout = setTimeout(async () => {
            await this.searchSubjectsQuery(query);
        }, 400);
    }

    public async searchSubjectsQuery(query: string): Promise<void> {
        this.searchSubjectsLoading = true;

        if (!this.searchSubject) {
            this.searchSubjects = await subjectService.searchSubjects(query);
            this.searchSubjectsLoading = false;
        }

        this.searchSubjectsLoading = false;
    }

    public async onSelectSubject(searchSubject: ISearchSubject): Promise<void> {
        this.$router.push("/subjects/" + searchSubject.subjectId);
    }

    public onSelectUser(event) {
        this.$emit("input", event);
    }

    public async save(): Promise<void> {
        this.editSubjectName = false;
        this.$store.dispatch("sighting/updateSubject", this.subject);
    }

    public closeModal(modalName: string): void {
        this.$modal.hide(modalName);
    }

    public showModal(modalName: string): void {
        this.$modal.show(modalName);
    }

    // Personnel
    public getRequests(personnel: ISubjectPersonnel): string {
        let requests = "";

        if (personnel.isBcRequest) {
            requests += "BC Request";
        }

        if (personnel.isMcRequest) {
            if (requests !== "") {
                requests += ", ";
            }

            requests += "MC Request";
        }

        return requests;
    }

    public getReports(personnel: ISubjectPersonnel): string {
        let reports = "";

        if (personnel.isMarketTracking) {
            reports += "MC Report";
        }

        if (personnel.isRegionalReport) {
            if (reports !== "") {
                reports += ", ";
            }

            reports += "Regional Report";
        }

        return reports;
    }

    public openMarketOfOperationsModal(): void {
        this.showModal(this.marketOfOperationsModal);
    }

    public openDeleteMarketOfOperationsModal(): void {
        this.showModal(this.deleteMarketOfOperationsModal);
    }

    public openSubjectXRefsModal(): void {
        this.showModal(this.subjectXRefsModal);
    }

    public openDeleteSubjectXRefsModal(): void {
        this.showModal(this.deleteSubjectXRefsModal);
    }

    public openAffiliatesModal(): void {
        this.showModal(this.affiliatesModal);
    }

    public openDeleteAffiliatesModal(): void {
        this.showModal(this.deleteAffiliatesModal);
    }

    public openAddPersonnelModal(): void {
        this.selectedPersonnel = {
            action: this.actionAdd,
            personnel: {
                user: [{
                    uniqueId: "",
                    email: "",
                    displayName: "",
                    jobTitle: "",
                    department: "",
                    location: "",
                    mobilePhone: "",
                    companyName: "",
                    manager: "",
                    prettyName: "",
                }],
                position: "",
                isBcRequest: false,
                isMcRequest: false,
                isMarketTracking: false,
                isRegionalReport: false,
            },
        };
        this.showModal(this.personnelModal);
    }

    public openEditPersonnelModal(index: number): void {
        this.selectedPersonnel = {
            action: this.actionEdit,
            personnel: this.subject.details.personnels[index],
        };
        this.editIndex = index;
        this.showModal(this.personnelModal);
    }

    public openDeletePersonnelModal(index: number): void {
        this.selectedPersonnel = {
            action: this.actionDelete,
            personnel: this.subject.details.personnels[index],
        };
        this.editIndex = index;
        this.showModal(this.personnelDeleteModal);
    }

    public openDeleteJournalModal(key: number): void {
        this.selectedJournal = {
            action: this.actionDelete,
            key,
            journal: this.subject.journals[key],
        };

        this.showModal(this.journalDeleteModal);
    }

    public closePersonnalModal(): void {
        this.editIndex = null;
        this.closeModal(this.personnelModal);

        this.selectedPersonnel = {
            action: this.actionAdd,
            personnel: {
                user: [{
                    uniqueId: "",
                    email: "",
                    displayName: "",
                    jobTitle: "",
                    department: "",
                    location: "",
                    mobilePhone: "",
                    companyName: "",
                    manager: "",
                    prettyName: "",
                }],
                position: "",
                isBcRequest: false,
                isMcRequest: false,
                isMarketTracking: false,
                isRegionalReport: false,
            },
        };
    }

    public async addPersonnel(): Promise<void> {
        this.$v.selectedPersonnel.$touch();
        if (this.$v.selectedPersonnel.$error) {
            return;
        }

        this.closeModal(this.personnelModal);

        if (this.selectedPersonnel.action === this.actionAdd) {
            this.subject.details.personnels.push({
                ...this.selectedPersonnel.personnel,
            });
        }
        else {
            this.subject.details.personnels[this.editIndex] = this.selectedPersonnel.personnel;
            this.editIndex = null;
        }

        this.selectedPersonnel = null;
    }

    public async deletePersonnel(index: number): Promise<void> {
        this.subject.details.personnels.splice(index, 1);
        this.closeModal(this.personnelDeleteModal);
        this.selectedPersonnel = null;
        this.editIndex = null;
    }

    // Production Site
    public openAddProductionSiteModal(): void {
        this.showModal(this.productionSiteModal);
    }

    public async addProductionSite(): Promise<void> {

        this.closeModal(this.productionSiteModal);

        const relations: ISubjectRelation[] = this.selectedSubjects.map<ISubjectRelation>(ps => ({
            subjectFromId: this.subject.subjectId,
            subjectToId: ps.subjectId,
            endDate: null,
            startDate: new Date(),
            subjectToName: ps.name,
            subjectToReference: ps.reference,
            subjectRelationTypeId: SubjectRelationType.ProductionSite,
            subjectToTypeId: ps.subjectTypeId,
        }));

        this.subject.relations = this.subject.relations.concat(relations);
        this.selectedSubjects = null;
    }

    public openDeleteProductionSiteModal(relation: ISubjectRelation): void {
        this.relation = { ...relation };
        this.showModal(this.productionSiteDeleteModal);
    }

    public async deleteProductionSite(relation: ISubjectRelation): Promise<void> {
        this.subject.relations = this.subject.relations.filter(r => r.subjectToId !== relation.subjectToId && r.subjectRelationTypeId === SubjectRelationType.ProductionSite);
        this.closeModal(this.productionSiteDeleteModal);
        this.relation = null;
    }

    // Journal
    public get filteredJournals(): ISubjectJournal[] {
        let subjects: ISubjectJournal[] = this.subject.journals ?? [];
        if (this.searchJournalKeywords?.length > 0) {
            subjects = subjects.filter(journal => journal.keywords?.some(k => this.searchJournalKeywords.indexOf(k) >= 0));
        }

        subjects.sort((x, y) => new Date(x.date).getTime() - new Date(y.date).getTime());
        return subjects;
    }

    public filterJournal(journal: ISubjectJournal, query: string): boolean {
        if (this.joinKeywords(journal.keywords).includes(query)) {
            return true;
        }
    }

    public joinKeywords(keywords: string[]): string {
        return keywords.join(", ");
    }

    public openAddOrEditJournalModal(value: ISubjectJournal, key: number): void {
        this.selectedJournal = {
            action: value ? this.actionEdit : this.actionAdd,
            key,
            journal: value ?? {
                date: "",
                title: "",
                keywords: [],
                description: "",
                files: [],
            },
        };

        this.showModal(this.journalModal);
    }

    public async addOrEditJournal(): Promise<void> {
        this.$v.selectedJournal.$touch();
        if (this.$v.selectedJournal.$error) {
            return;
        }

        this.closeModal(this.journalModal);

        if (this.selectedJournal?.key >= 0) {
            this.subject.journals[this.selectedJournal?.key] = this.selectedJournal.journal;
        } else {
            this.subject.journals.push({...this.selectedJournal.journal});
        }

        this.selectedJournal = null;
        this.$v.selectedJournal.$reset();
    }

    public async deleteJournal(key: number): Promise<void> {
        this.subject.journals.splice(key, 1);
        this.closeModal(this.journalDeleteModal);
        this.selectedJournal = null;
    }

    // Fix when the list is filtered
    public changeDescription(journal: ISubjectJournal): void {
        this.subject.journals
            .filter(jnl => jnl.title === journal.title)[0].description = journal.description;
    }

    public addTag(newTag) {
        this.selectedJournal.journal.keywords.push(newTag);
    }

    public addKeywordTag(newTag) {
        this.searchJournalKeywords.push(newTag);
    }

    public async handleFiles(event): Promise<void> {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.target.files.length; i++) {
            await this.uploadFile(event.target.files[i]);
        }
    }

    public async uploadFile(file: File): Promise<void> {
        if (this.escapeExtention(this.extensionRegexp.exec(file?.name)[1]) !== "") {
            const metadata = {
                name: file.name,
            };

            this.selectedJournal.journal.files.push({
                name: file.name,
                uri: await this.azureBlobService.uploadFile(file, "journals", metadata),
            });
        } else {
            NotificationService.error("Invalid file !");
        }
    }

    public async deleteJournalFile(key: number): Promise<void> {
        this.selectedJournal.journal.files.splice(key, 1);
    }

    public getFileWithFullUrl(uri: string): string {
        if (this.azureBlobService) {
            return this.azureBlobService.getDownloadUrl(uri);
        } else {
            return uri;
        }
    }

    public downloadFile(file: ISubjectJournalFile): void {
        if (this.azureBlobService && file) {
            const link = document.createElement("a");
            link.href = this.azureBlobService.getDownloadUrl(file.uri);
            link.download = file.name;
            link.click();
        }
    }

    public getFileClass(fileName: string): string {
        const extension: string = this.extensionRegexp.exec(fileName)[1];
        return this.escapeExtention(extension) + " file-type";
    }

    public onDateChanged(event: InputEvent): void {
        if (this.selectedJournal?.journal?.date) {
            const re = /(\d{4})\/?(\d{2})\/?(\d{2})/;
            this.selectedJournal.journal.date = this.selectedJournal.journal.date.replace(re, "$1/$2/$3");
        }
    }

    public async onDeleteSubject() {
        const result = await this.$store.dispatch("sighting/deleteSubject", this.subject.subjectId);
        if (result === true) {
            this.$router.push({ path: "/subjects"});
        }
    }

    public openModal(modalName: string): void {
        this.$modal.show(modalName);
    }

    private subjectToRelation(subject: ISubject, relationType: SubjectRelationType): ISubjectRelation {
        if (!subject) {
            return null;
        }

        return {
            subjectFromId: this.subject.subjectId,
            subjectToId: subject.subjectId,
            endDate: null,
            startDate: new Date(),
            subjectToName: subject.name,
            subjectToReference: subject.reference,
            subjectRelationTypeId: relationType,
            subjectToTypeId: subject.subjectTypeId,
        };
    }

    private escapeExtention(extension: string): string {
        switch (extension?.toLowerCase()) {
            case "pdf": {
                return "pdf";
            }
            case "doc":
            case "docx": {
                return "doc";
            }
            case "xls":
            case "xlsx": {
                return "xls";
            }
            case "ppt":
            case "pptx": {
                return "ppt";
            }
            case "jpg":
            case "jpeg":
            case "gif":
            case "bmp":
            case "png": {
                return "png";
            }
            default:
                return "";
        }
    }

    private initDetailsIfNeeded(): void {
        if (this.subject.details === null) {
            this.subject.details = {
                location: null,
                country: null,
                latitude: null,
                longitude: null,
                website: null,
                managementEntity: null,
                region: null,
                connectCode: null,
                personnels: [],
                marketsOfOperation: [],
            };
        }

        if (this.subject.details.personnels === null) {
            this.subject.details.personnels = [];
        }

        if (!this.subject.details.marketsOfOperation) {
            this.subject.details.marketsOfOperation = [];
        }

        if (!this.subject.relations) {
            this.subject.relations = [];
        }
    }

    private cleanArray(values: string[]): string[] {
        return values?.filter((v, i, s) => s.indexOf(v) === i).sort((x, y) => x.localeCompare(y)) ?? [];
    }
}

export interface ISelectedPersonnel {
    action: string;
    personnel: ISubjectPersonnel;
}

export interface ISelectedJournal {
    action: string;
    key: number;
    journal: ISubjectJournal;
}
