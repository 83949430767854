import Vue from "vue";
import { Component } from "vue-property-decorator";
import {
    IReporterRequest,
    ReporterRequestStatus,
    IBrandCompanyRequest,
    IMarketCompany,
    ISubject, BrandCompanyRequestStatus,
    IMarketCompanyRequest,
    MarketCompanyRequestStatus,
    SubjectType,
    Role,
} from "@/models";
import { Loader } from "@/components";
import { AzureBlobService, SightingService } from "@/services";
import * as icons from "@/components/shared/icons";
@Component({
    name: "sighting-request-dashboard",
    components: {
        Loader,
        "more-icon": icons.MoreIcon,
    },
})
export default class SightingRequestDashboard extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public azureBlobService: AzureBlobService;
    public sightingService: SightingService;
    public key: string;

    public selectedRequestType: number;
    public selectedReporterRequests: IReporterRequest[];
    public selectedBrandCompanyRequests: IBrandCompanyRequest[];
    public selectedMarketCompanyRequests: IMarketCompanyRequest[];

    public constructor() {
        super();
        this.loadPromises = [];
        this.isLoaded = false;
        this.azureBlobService = null;
        this.sightingService = null;
        this.key = null;

        this.selectedRequestType = 1;
        this.selectedReporterRequests = [];
        this.selectedBrandCompanyRequests = [];
        this.selectedMarketCompanyRequests = [];

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }
        if (!this.$store.state.sighting.reporterRequests) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchReporterRequests"));
        }
        else {
            this.$store.dispatch("sighting/fetchReporterRequests");
        }
        if (!this.$store.state.sighting.brandCompanyRequests) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrandCompanyRequests"));
        }
        else {
            this.$store.dispatch("sighting/fetchBrandCompanyRequests");
        }
        if (this.UserIsBSTeam) {
            if (!this.$store.state.sighting.marketCompanyRequests) {
                this.loadPromises.push(this.$store.dispatch("sighting/fetchMarketCompanyRequests"));
            }
            else {
                this.$store.dispatch("sighting/fetchMarketCompanyRequests");
            }
        }
        if (this.$store.getters["sighting/getSubjectsByType"](SubjectType.MarketCompany).length < 1) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchSubjectsByType", SubjectType.MarketCompany));
        }

        if (!this.$store.state.sighting.markets) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchMarkets"));
        }
        if (!this.$store.state.sighting.brandCompanies) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchBrandCompanies"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.isLoaded = true;
        this.azureBlobService = new AzureBlobService(
            this.$store.state.app.blobSettings.containerName,
            this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);
        this.sightingService = new SightingService();
    }

    public get UserIsBSTeam(): boolean {
        return this.$store.state.app.userRole >= Role.BSTeam;
    }

    public get reporterRequests(): IReporterRequest[] {
        return this.$store.getters["sighting/searchReporterRequests"](this.key) ?? [];
    }

    public get brandCompanyRequests(): IBrandCompanyRequest[] {
        return this.$store.getters["sighting/searchBrandCompanyRequests"](this.key) ?? [];
    }

    public get marketCompanyRequests(): IMarketCompanyRequest[] {
        return this.$store.getters["sighting/searchMarketCompanyRequests"](this.key) ?? [];
    }

    public get toSendCount(): number {
        switch (this.selectedRequestType) {
            case 1:
                return this.$store.getters["sighting/getReporterRequestsByStatus"](ReporterRequestStatus.ToSend).length;
            case 2:
                return this.$store.getters["sighting/getBrandCompanyRequestsByStatus"](BrandCompanyRequestStatus.ToSend).length;
            case 3:
                return this.$store.getters["sighting/getMarketCompanyRequestsByStatus"](MarketCompanyRequestStatus.ToSend).length;
            default:
                return 0;
        }
    }

    public get awaitingResponseCount(): number {
        switch (this.selectedRequestType) {
            case 1:
                return this.$store.getters["sighting/getReporterRequestsByStatus"](ReporterRequestStatus.AwaitingResponse).length;
            case 2:
                return this.$store.getters["sighting/getBrandCompanyRequestsByStatus"](BrandCompanyRequestStatus.AwaitingResponse).length;
            case 3:
                return this.$store.getters["sighting/getMarketCompanyRequestsByStatus"](MarketCompanyRequestStatus.AwaitingResponse).length;
            default:
                return 0;
        }
    }

    public get canSendRequests(): boolean {
        switch (this.selectedRequestType) {
            case 1:
                return this.selectedReporterRequests.length > 0;
            case 2:
                return this.selectedBrandCompanyRequests.length > 0;
            case 3:
                return this.selectedMarketCompanyRequests.length > 0;
            default:
                return false;
        }
    }

    public getMarket(marketId: number): IMarketCompany {
        return this.$store.getters["sighting/getMarket"](marketId);
    }

    public getMarketNameByMarketCompanyId(marketCompanyId: number): string {
        return this.$store.getters["sighting/getMarketByMarketCompanyId"](marketCompanyId)?.name ?? "";
    }

    public getBrandCompany(brandCompanyId: number): ISubject {
        return this.$store.getters["sighting/getBrandCompany"](brandCompanyId);
    }

    public getMarketCompany(marketCompanyId: number): IMarketCompany {
        return this.$store.getters["sighting/getMarketCompany"](marketCompanyId);
    }

    public addOrRemoveReporterRequest(reporterRequest: IReporterRequest): void {
        if (this.selectedReporterRequests.find(r => r.reporterRequestId === reporterRequest.reporterRequestId)) {
            this.selectedReporterRequests = this.selectedReporterRequests.filter(r => r.reporterRequestId !== reporterRequest.reporterRequestId);
        } else {
            this.selectedReporterRequests.push(reporterRequest);
        }
    }
    public addOrRemoveBrandCompanyRequest(brandCompanyRequest: IBrandCompanyRequest): void {
        if (this.selectedBrandCompanyRequests.find(r => r.brandCompanyRequestId === brandCompanyRequest.brandCompanyRequestId)) {
            this.selectedBrandCompanyRequests = this.selectedBrandCompanyRequests.filter(r => r.brandCompanyRequestId !== brandCompanyRequest.brandCompanyRequestId);
        } else {
            this.selectedBrandCompanyRequests.push(brandCompanyRequest);
        }
    }

    public addOrRemoveMarketCompanyRequest(marketCompanyRequest: IMarketCompanyRequest): void {
        if (this.selectedMarketCompanyRequests.find(r => r.marketCompanyRequestId === marketCompanyRequest.marketCompanyRequestId)) {
            this.selectedMarketCompanyRequests = this.selectedMarketCompanyRequests.filter(r => r.marketCompanyRequestId !== marketCompanyRequest.marketCompanyRequestId);
        } else {
            this.selectedMarketCompanyRequests.push(marketCompanyRequest);
        }
    }

    public sendRequests(): void {
        switch (this.selectedRequestType) {
            case 1:
                this.sendReporterRequests();
                break;
            case 2:
                this.sendBrandCompanyRequests();
                break;
            case 3:
                this.sendMarketCompanyRequests();
                break;
        }
    }

    public sendReporterRequests(): void {
        this.$store.dispatch("sighting/sendReporterRequests", this.selectedReporterRequests.map(reporterRequest => {
            return reporterRequest.reporterRequestId;
        }));
    }

    public sendBrandCompanyRequests(): void {
        this.$store.dispatch("sighting/sendBrandCompanyRequests", this.selectedBrandCompanyRequests.map(brandCompanyRequest => {
            return brandCompanyRequest.brandCompanyRequestId;
        }));
    }

    public sendMarketCompanyRequests(): void {
        this.$store.dispatch("sighting/sendMarketCompanyRequests", this.selectedMarketCompanyRequests.map(marketCompanyRequest => {
            return marketCompanyRequest.marketCompanyRequestId;
        }));
    }
}
