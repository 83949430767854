import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { IProductionSite, ISubject } from "@/models";

class ProductionSiteService extends ElintApiService {
    public async getAllProductionSites(): Promise<IProductionSite[]> {
        try {
            const response = await fetch("/api/productionsites", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const productionSites = await response.json();
                return productionSites;
            }
            else {
                NotificationService.error("Error getting production sites !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting production sites !");
            return null;
        }
    }

    public async getProductionSitesByBrand(brandCompanyId: number): Promise<ISubject[]> {
        try {
            const response = await fetch(`/api/productionsites/bybrand/${brandCompanyId}`, {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const productionSites = await response.json();
                return productionSites;
            }
            else {
                NotificationService.error("Error getting production sites !");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting production sites !");
            return null;
        }
    }

    public async addProductionSite(name: string): Promise<void> {
        try {
            const body = {
                name,
            };
            const response = await fetch("/api/productionsites", {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(body),
            });
            if (response.ok) {
                NotificationService.success("Production site added !");
            }
            else {
                NotificationService.error("Error adding production site !");
            }
        }
        catch (error) {
            NotificationService.error("Exception adding production site !");
        }
    }

    public async getBrandsForProductionSite(id: number): Promise<number[]> {
        try {
            const response = await fetch(`/api/productionsites/${id}/brands`, {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const brandIds = await response.json();
                return brandIds;
            }
            else {
                NotificationService.error("Error getting production sites !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting production sites !");
            return [];
        }
    }

    public async addBrandsToProductionSite(data): Promise<void> {
        try {
            const body = {
                brandIds: data.brandIds,
            };

            const response = await fetch(`/api/productionsites/${data.productionSiteId}/brands`, {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(body),
            });
            if (response.ok) {
                NotificationService.success("Production site edited !");
            }
            else {
                NotificationService.error("Error editing production site !");
            }
        }
        catch (error) {
            NotificationService.error("Exception editing production site !");
        }
    }

    public async upsertUsersToProductionSite(data): Promise<void> {
        try {
            const body = {
                userIds: data.userIds,
            };
            const response = await fetch(`/api/productionsites/${data.productionSiteId}/users`, {
                headers: await this.getHeaders(),
                method: "POST",
                body: JSON.stringify(body),
            });
            if (response.ok) {
                NotificationService.success("Production site edited !");
            }
            else {
                NotificationService.error("Error editing production site !");
            }

        }
        catch (error) {
            NotificationService.error("Exception editing production site !");
        }
    }
}

const productionSiteService = new ProductionSiteService();

export {ProductionSiteService, productionSiteService};
