import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Loader } from "@/components";
import { IUserSighting, IMarket, ISubject } from "@/models";
import { AzureBlobService } from "@/services";
import VueEasyLightbox from "vue-easy-lightbox";
import { GoogleMapsService, locationService } from "@/services";
@Component({
    name: "user-sighting-view",
    components: {
        Loader,
        VueEasyLightbox,
    },
})
export default class UserSightingView extends Vue {

    public loadPromises: Array<Promise<any>>;
    public isLoaded: boolean;
    public azureBlobService: AzureBlobService;
    public visible: boolean;
    public index: number;
    public googleMapsService: GoogleMapsService;

    public map: google.maps.Map;
    public marker: google.maps.Marker;

    public constructor() {
        super();
        this.loadPromises = [];
        this.isLoaded = false;
        this.visible = false;
        this.index = 0;
        this.googleMapsService = null;
        this.map = null;
        this.marker = null;

        if (!this.$store.state.app.blobSettings) {
            this.loadPromises.push(this.$store.dispatch("app/loadStorageConfig"));
        }
        if (!this.$store.state.app.googleMapsConfig) {
            this.loadPromises.push(this.$store.dispatch("app/loadGoogleMapsConfig"));
        }
        if (!this.$store.state.sighting.userSightings) {
            this.loadPromises.push(this.$store.dispatch("sighting/fetchUserSightings"));
        }
    }

    public async created(): Promise<void> {
        await Promise.all(this.loadPromises);
        this.isLoaded = true;
        this.azureBlobService = new AzureBlobService(this.$store.state.app.blobSettings.containerName, this.$store.state.app.blobSettings.blobUri,
            this.$store.state.app.blobSettings.sasToken);

        if (!this.userSighting && this.userSightingId) {
            this.$router.push("/not-found");
        }

        this.googleMapsService = new GoogleMapsService(this.$store.state.app.googleMapsConfig.apiKey);
        this.map = await this.googleMapsService.buildMap("map");
        this.marker = new google.maps.Marker({
            map: this.map,
            position: {lat: this.userSighting.latitude, lng: this.userSighting.longitude},
        });
        this.map.setCenter(this.marker.getPosition());
    }

    public get userSightingId(): number {
        return this.$route.params.userSightingId ? parseInt(this.$route.params.userSightingId, 10) : null;
    }

    public get userSighting(): IUserSighting {
        if (this.userSightingId) {
            return this.$store.getters["sighting/getUserSighting"](this.userSightingId);
        }
        return null;
    }

    public get market(): IMarket {
        return this.$store.getters["sighting/getMarket"](this.userSighting.marketId);
    }

    public get importerName(): string {
        const customer: ISubject = this.$store.getters["sighting/getCustomer"](this.userSighting.importerSubjectId);
        const company: ISubject = this.$store.getters["sighting/getCompany"](this.userSighting.importerSubjectId);
        return (customer ?? company)?.name;
    }

    public get photosWithFullUrl(): string[] {
        if (this.azureBlobService) {
            return this.userSighting.photos.map(photo => this.azureBlobService.getDownloadUrl(photo));
        }
    }

    // Lightbox sur les images
    public showImg(index: number) {
        this.index = index;
        this.visible = true;
      }

    public handleHide() {
        this.visible = false;
    }
}
