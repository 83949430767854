import {
    required,
    minValue,
    email,
    helpers,
} from "vuelidate/lib/validators";
import { RuleDecl } from "vue/types/options";

const dateValidationRule = helpers.regex("date", /^[0-9]{4}\/([0][0-9]|[1][0-2])\/([0-2][0-9]|[3][0-1])$/);

export const subjectFormValidations: RuleDecl = {
    subject: {
        subjectTypeId: {
            required,
            minValue: minValue(1),
        },
    },
    selectedPersonnel: {
        personnel: {
            user: {
                email: {
                    required,
                    email,
                },
            },
        },
    },
    selectedJournal: {
        journal: {
            date: {
                required,
                dateValidationRule,
            },
            title: {
                required,
            },
            keywords: {
                required,
            },
            description: {
                required,
            },
        },
    },
};
