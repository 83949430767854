import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    name: "login",
})
export default class Login extends Vue {

    public login(): void {
        this.$store.dispatch("app/login");
    }

}
