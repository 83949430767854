import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { ISubjectType } from "@/models";

class SubjectTypeService extends ElintApiService {
    public async getSubjectTypes(): Promise<ISubjectType[]> {
        try {
            const response = await fetch("/api/subjecttypes", {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const subjectTypes = await response.json();
                return subjectTypes;
            }
            else {
                NotificationService.error("Error getting subject types !");
                return [];
            }
        }
        catch (error) {
            NotificationService.error("Exception getting subject types !");
            return [];
        }
    }
}

const subjectTypeService = new SubjectTypeService();

export { SubjectTypeService, subjectTypeService };
