import ElintApiService from "./ElintApiService";
import { NotificationService } from "./NotificationService";
import { ISighting, ISightingSubList, ISightingPreviousValues } from "@/models";

class SightingService extends ElintApiService {

    public async getPagedSightingsByStatusAndKey(data): Promise<ISightingSubList> {
        try {
            const response = await fetch(`/api/sightings?${data.status ? `status=${data.status}` : ""}&pageSize=${data.pageSize}&pageNumber=${data.pageNumber}&key=${data.keySearch}&includeCompleted=${data.includeCompleted ?? true}&sortDirection=${data.sortDirection ?? ""}&sortBy=${data.sortBy ?? ""}`, {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                const sightings = await response.json();
                return sightings;
            }
            else {
                NotificationService.error("Error getting sightings !");
                return { items: [], total: 0 };
            }
        }
        catch (error) {
            NotificationService.error("Exception getting sightings !");
            return { items: [], total: 0 };
        }
    }

    public async GetAdvancedSightings(data): Promise<ISightingSubList> {
        try {
            const response = await fetch(`/api/sightings`, {
                method: "POST",
                headers: await this.getHeaders(),
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const sightings = await response.json();
                return sightings;
            }
            else {
                NotificationService.error("Error getting sightings !");
                return { items: [], total: 0 };
            }
        }
        catch {
            NotificationService.error("Exception getting sightings !");
            return { items: [], total: 0 };
        }
    }

    public async getSightingById(id): Promise<ISighting> {
        try {
            const response = await fetch(`/api/sightings/${id}`, {
                headers: await this.getHeaders(),
            });

            if (response.ok) {
                const sightings = await response.json();
                return sightings;
            }
            else {
                NotificationService.error("Error getting sighting by ID.");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting sighting by ID!");
            return null;
        }
    }

    public async getSightingPreviousValuesById(id): Promise<ISightingPreviousValues> {
        try {
            const response = await fetch(`/api/sightings/${id}/previous-values`, {
                headers: await this.getHeaders(),
            });
            if (response.ok) {
                return await response.json();
            }
            else {
                NotificationService.error("Error getting sighting previous values by ID.");
                return null;
            }
        }
        catch (error) {
            NotificationService.error("Exception getting sighting previous values  by ID!");
            return null;
        }
    }

    public async updateSighting(data): Promise<void> {
        try {
            const response = await fetch(`/api/sightings/${data.sightingId}/update`, {
                method: "POST",
                headers: await this.getHeaders(),
                body: JSON.stringify(data),
            });
            if (response.ok) {
                NotificationService.success("Sighting updated !");
            }
            else {
                NotificationService.error("Error updating sighting.");
            }
        }
        catch (error) {
            NotificationService.error("Exception updating sighting !");
        }
    }

    public async deleteSighting(sightingId: number): Promise<void> {
        try {
            const response = await fetch(`/api/sightings/${sightingId}/delete`, {
                method: "DELETE",
                headers: await this.getHeaders(),
            });

            if (response.ok) {
                NotificationService.success("Sighting deleted!");
            } else {
                NotificationService.error("Error deleting sighting.");
            }
        } catch (error) {
            NotificationService.error("Exception deleting sighting!");
        }
    }
}

const sightingService = new SightingService();

export {SightingService, sightingService};
