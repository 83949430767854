import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { AzureBlobService } from "@/services";

@Component({
    name: "edit-bottles",
})
export default class EditBottles extends Vue {

    public azureBlobService: AzureBlobService;
    public productName: string;
    public photos: string[];
    public files: string[];
    public dragging: boolean;
    public loading: boolean;

    public constructor() {
        super();

        this.dragging = false;
        this.loading = false;
        this.productName = "";
        this.photos = [];
        this.files = [];
        this.azureBlobService = null;

    }

    public onDragEnter(event: DragEvent): void {
        this.dragging = true;
    }

    public onDragLeave(event: DragEvent): void {
        this.dragging = false;
    }

    public async onDropFiles(event: DragEvent): Promise<void> {
        this.dragging = false;
        this.loading = true;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
            await this.uploadFile(event.dataTransfer.files[i]);
        }
        this.loading = false;
    }

    public async uploadFile(file: File): Promise<void> {
        const metadata = {
            name: file.name,
        };
        this.photos.push(await this.azureBlobService.uploadFile(file, "usersighting", metadata));
    }
}
