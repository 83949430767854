import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IModelState } from "@/models";

@Component({
    name: "validation-summary",
})
export default class ValidationSummary extends Vue {
    @Prop()
    public modelState: IModelState;

    /// Computed
    public get modelErrors() {
        const values = this.modelState ? this.modelState.values : {};

        if (Object.keys(values).length === 0) {
            return {};
        }

        let errors: any[] = [];
        for (const key in values) {
            if (!values.hasOwnProperty(key)) {
                continue;
            }

            const value = values[key];
            const errs = value.errors.map(error => {
                return {
                    key: value.key,
                    message: error.errorMessage,
                };
            });

            errors = [].concat(errors, errs);
        }

        return errors;
    }

    public get rootClass() {
        if (this.modelState && !this.modelState.isValid) {
            return "validation-summary-errors";
        }
        else {
            return "validation-summary-valid";
        }

    }
}
